import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";

const Navbar = () => {
  const navigate = useNavigate();
  const currentBot = useSelector((state) => state.bot.bot);
  return (
    <div className="navbar">
      <div className="flex justify-between items-center py-[13px]">
        <div className="flex" onClick={() => navigate("/webbot")}>
          <img src="/logo-black.svg" />
        </div>
        <div
          className="my-2 mx-3"
          style={{
            height: "16px",
            width: "2px",
            backgroundColor: "#E4E5EA",
          }}
        ></div>
        <div className="web-chat-default-text">{currentBot.name}</div>
        <div className="flex-1"></div>
        <div className="hidden sm:block">
          <Link
            className="web-chat-default-text mr-[20px]"
            to={
              process.env.REACT_APP_BASE_URL+"/login?utm_source=chat-app-top&utm_medium=chat-bot&utm_campaign=chat-bot"
            }
            target="_blank"
          >
            Create your own app
          </Link>
          <Link
            className="mr-[20px] clone-bot-btn"
            to={
              "https://www.getbind.co/?utm_source=chat-app-top&utm_medium=chat-bot&utm_campaign=chat-bot"
            }
            target="_blank"
          >
            {" "}
            Clone this bot
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
