import React from "react";

import LoginForm from "./LoginForm";

const LogIn = () => {
  return (
    <main className="relative flex flex-col h-screen bg-gray-100 md:flex-row text-left">
      <div className="flex w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <LoginForm />
        <div className="w-1/2 hidden md:block">
          <div className="w-7/12">
            <img
              src="/logo-black.svg"
              alt="logo"
              className="z-20 inline-block h-7"
            />
            <div className="mt-20">
              <p className="text-[28px] font-semibold">
                Log in to access Bind AI{" "}
              </p>
              <p className="text-lg">Unlock access to Bind AI Copilot and Bind AI Studio.</p>
              <ol className="mt-8 text-lg">
                <li className="pb-3 flex items-start">
                  {" "}
                  <img
                    src="/assets/images/tick.svg"
                    alt="Tick"
                    className="pr-5 pt-1"
                  />
                  <span>Create AI Chatbots and AI Agents with Bind Studio</span>
                </li>
                <li className="pb-3 flex items-start">
                  <img
                    src="/assets/images/tick.svg"
                    alt="Tick"
                    className="pr-5 pt-1"
                  />
                  Use Bind AI copilot for chat, generating images and more
                </li>
                <li className="flex items-start">
                  <img
                    src="/assets/images/tick.svg"
                    alt="Tick"
                    className="pr-5 pt-1"
                  />
                  Store your chat history and connect your data
                </li>
              </ol>
              <img
                src="/assets/images/arrow.svg"
                alt="arrow"
                className="mt-8"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default LogIn;
