import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { getBots } from "../reducers/botReducer";
import classNames from "classnames";
import userEvent from "@testing-library/user-event";
import { Link } from "react-router-dom";

const MyBot = () => {
  const navigate = useNavigate();
  const bots = useSelector((state) => state.bot.bots);
  const user = useSelector((state) => state.auth.currentUser);
  const createNewBot = () => {
    navigate(`/createBot/select`);
  };
  const id=localStorage.getItem("userId");

  const dispatch = useDispatch();

  const getBotsT = async () => {
    await dispatch(getBots({ id: id}));
  };

  useEffect(() => {
    if(id){
    getBotsT();
    }
    // console.log(user);
  }, [id]);
  return (
    <div className="my-[20px] pr-[20px] ml-[20px]">
      <div className="flex mb-[14px]">
        <h1
          className="text-left mb-[10px]"
          style={{
            fontFamily: "Inter",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            color: "var(--main-1-c-274-c, #1C274C)",
          }}
        >
          My Bots
        </h1>
        <div className="flex-1"></div>
        <button className="next-button" onClick={() => createNewBot()}>
          Create bot
        </button>
      </div>
      <div>
        <div className="bot-list-header flex text-left">
          {/* <input type="checkbox" /> */}
          <p className="flex-1">Bot Name</p>
          <p className="flex-1">Environment</p>
          <p className="flex-1">Widget or API</p>
          <p className="flex-1">Status</p>
          <p className="flex-1">Last Update</p>
          <p className="flex-1">Actions</p>
        </div>
        <div className="bot-list-content">
          {bots == undefined
            ? null
            : bots.map((bot, index) => (
                <div
                  className={classNames("flex text-left items-center", {
                    "bot-list-item-odd": index % 2 == 0,
                    "bot-list-item-even": index % 2 == 1,
                  })}
                >
                  {/* <input type="checkbox" /> */}
                  <p className="flex-1">{bot.bname}</p>
                  <p className="flex-1">Dev</p>
                  <Link className="flex-1" to={`/createBot/publish/${bot.id}`}>
                    {bot.type == 0 ? "JS code / Direct link" : "Custom API"}
                  </Link>
                  <p
                    className={classNames("flex-1", {
                      "bot-draft": bot.status == 0,
                      "bot-active": bot.status == 1,
                    })}
                  >
                    {bot.status == 0 ? "Draft" : "Active"}
                  </p>
                  <p className="flex-1">{bot.date}</p>
                  <Link
                    to={`/createBot/customize/${bot.id}`}
                    className="flex-1 flex"
                  >
                    View / Edit
                    <div className="flex-1"></div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10.0002 16.6667C9.54183 16.6667 9.14961 16.5036 8.8235 16.1775C8.49683 15.8508 8.3335 15.4583 8.3335 15C8.3335 14.5417 8.49683 14.1492 8.8235 13.8225C9.14961 13.4964 9.54183 13.3333 10.0002 13.3333C10.4585 13.3333 10.851 13.4964 11.1777 13.8225C11.5038 14.1492 11.6668 14.5417 11.6668 15C11.6668 15.4583 11.5038 15.8508 11.1777 16.1775C10.851 16.5036 10.4585 16.6667 10.0002 16.6667ZM10.0002 11.6667C9.54183 11.6667 9.14961 11.5033 8.8235 11.1767C8.49683 10.8506 8.3335 10.4583 8.3335 10C8.3335 9.54167 8.49683 9.14917 8.8235 8.8225C9.14961 8.49639 9.54183 8.33333 10.0002 8.33333C10.4585 8.33333 10.851 8.49639 11.1777 8.8225C11.5038 9.14917 11.6668 9.54167 11.6668 10C11.6668 10.4583 11.5038 10.8506 11.1777 11.1767C10.851 11.5033 10.4585 11.6667 10.0002 11.6667ZM10.0002 6.66667C9.54183 6.66667 9.14961 6.50333 8.8235 6.17667C8.49683 5.85056 8.3335 5.45833 8.3335 5C8.3335 4.54167 8.49683 4.14944 8.8235 3.82333C9.14961 3.49667 9.54183 3.33333 10.0002 3.33333C10.4585 3.33333 10.851 3.49667 11.1777 3.82333C11.5038 4.14944 11.6668 4.54167 11.6668 5C11.6668 5.45833 11.5038 5.85056 11.1777 6.17667C10.851 6.50333 10.4585 6.66667 10.0002 6.66667Z"
                        fill="#1D2433"
                        fill-opacity="0.8"
                      />
                    </svg>
                  </Link>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default MyBot;
