import { useState } from "react";
import { addEmail } from "../../../reducers/botReducer";
import { useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";

const PremiumDialog = ({ setOpenPremiumDlg }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const startTrial = () => {
    navigate("/login");
  };

  return (
    <div className="email-dialog">
      <div className="email-dialog-form p-[20px] sm:p-[0px] text-left ">
        <h2 className="mx-[30px] sm:mx-[60px] mb-[12px]">
          Available only in Premium
        </h2>
        <h3 className="mx-[30px] sm:mx-[60px] mb-[50px]">
          Connect your own data, and build customized sales, data and other
          applications
        </h3>
        <div className="flex flex-col">
          <div className="flex mb-[12px]">
            <img src="/assets/images/check-img.svg" className="mr-[10px]" />
            Integrate with your Intercom, Zendesk, Google Drive and more
          </div>
          <div className="flex mb-[12px]">
            <img src="/assets/images/check-img.svg" className="mr-[10px]" />
            Store 100s of documents in Vector search index
          </div>
          <div className="flex mb-[12px]">
            <img src="/assets/images/check-img.svg" className="mr-[10px]" />
            Create multiple custom bots
          </div>
          <div className="flex mb-[12px]">
            <img src="/assets/images/check-img.svg" className="mr-[10px]" />
            Deploy Custom LLM REST API
          </div>
          <div className="flex mb-[12px]">
            <img src="/assets/images/check-img.svg" className="mr-[10px]" />
            Access to Bind Playground for testing your LLM setup
          </div>
          <div className="flex mb-[12px]">
            <img src="/assets/images/check-img.svg" className="mr-[10px]" />
            Beta access to Plugins.
          </div>
        </div>
        <button
          className="rounded-[10px] bg-[#4529FA] text-white w-[80%] mt-[70px]"
          onClick={() => startTrial()}
        >
          Start a trial
        </button>
        <button
          style={{
            color: "var(--main-873-a-4363, #3A4363)",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          }}
          onClick={() => setOpenPremiumDlg(0)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default PremiumDialog;
