import ChatBot from "../bots/chatbot";
import { useState, useEffect } from "react";
import { selectPrompt, publishBot, getBot } from "../../reducers/botReducer";
import { useSelector, useDispatch } from "react-redux";
import ChatBotDev from "../bots/chatbotdev";
import DeployEnvironment from "./DeployEnvironment";
import DeploymentHistory from "./DeploymentHistory";
import { useNavigate, useParams } from "react-router";
import DeployBot from "./DeployBot";
import DeployApi from "./DeployApi";

const BotPublish = (props) => {
  const [option, setOption] = useState(0);
  const { id } = useParams();
  const currentBotDetail = useSelector((state) => state.bot.currentBotDetail);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const botPublish = async () => {
    const payload = {
      botId: id,
      name: currentBotDetail.name,
      desc: currentBotDetail.desc,
      knowledge_base_type: currentBotDetail.knowledge_base_type,
      knowledge_base_name: currentBotDetail.knowledge_base_name,
      knowledge_base_desc: currentBotDetail.knowledge_base_desc,
    };
    await dispatch(publishBot(payload));
  };

  const getCurrentBot = async () => {
    dispatch(getBot({ botId: id }));
  };

  useEffect(() => {
    getCurrentBot();
  }, []);

  const prevStep = async () => {
    navigate(`/createBot/plugin/${id}`);
  };

  return (
    <>
      {currentBotDetail != null &&
        (currentBotDetail.type == 0 ? <DeployBot /> : <DeployApi />)}
    </>
  );
};

export default BotPublish;
