import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { history } from "../history";

const initialState = {
  training: false,
  currentBot: "",
  errors: "",
  bots: [],
  bot: {},
  trainData: [],
  apikey: "",
  output: [],

  apiData: {
    errors: "",
    answer: "",
  },

  currentBotDetail: {
    type: 0,
    bot_type: 0,
    name: "",
    desc: "",
    knowledge_base_type: 0,
    prompt: "",
    knowledge_base_name: "",
    knowledge_base_desc: "",
  },
};

export const getBot = createAsyncThunk("bot/get", async (payload) => {
  let formData = new FormData();
  formData.append("botId", payload.botId);

  const response = await axios.post(
    process.env.REACT_APP_NEW_BACKEND_URL + "/bot/get",
    formData
  );
  return response.data;
});

export const getBots = createAsyncThunk("bot/get/all", async (payload) => {
  let formData = new FormData();
  formData.append("userId", payload.id);
  const response = await axios.post(
    process.env.REACT_APP_NEW_BACKEND_URL+"/bot",
    formData
  );
  return response.data;
});

export const createBot = createAsyncThunk("bot/createone", async (payload) => {
  let formData = new FormData();
  // formData.append("email", payload.email);
  formData.append("userId", payload.userId);
  formData.append("type", payload.type);
  // formData.append("btype", payload.btype);
  const response = await axios.post(
    process.env.REACT_APP_NEW_BACKEND_URL+"/bot/create",
    formData
  );
  return response.data;
});

export const setBase = createAsyncThunk("bot/setbase", async (payload) => {
  let formData = new FormData();
  formData.append("base", payload.base);
  formData.append("botId", payload.botId);
  const response = await axios.post(
    process.env.REACT_APP_NEW_BACKEND_URL+"/bot/base",
    formData
  );
  return response.data;
});

export const publishBot = createAsyncThunk("bot/publish", async (payload) => {
  let formData = new FormData();
  formData.append("botId", payload.botId);
  formData.append("name", payload.name);
  formData.append("desc", payload.desc);
  formData.append("knowledge_base_type", payload.knowledge_base_type);
  formData.append("knowledge_base_name", payload.knowledge_base_name);
  formData.append("knowledge_base_desc", payload.knowledge_base_desc);
  const response = await axios.post(
    process.env.REACT_APP_NEW_BACKEND_URL+"/bot/publish",
    formData
  );
  return response.data;
});

export const customizeBot = createAsyncThunk(
  "bot/customize",
  async (payload) => {
    let formData = new FormData();
    formData.append("botId", payload.botId);
    formData.append("btype", payload.btype);
    formData.append("name", payload.name);
    formData.append("desc", payload.desc);
    formData.append("prompt", payload.prompt);
    const response = await axios.post(
      process.env.REACT_APP_NEW_BACKEND_URL+"/bot/customize",
      formData
    );
    return response.data;
  }
);

export const selectPrompt = createAsyncThunk(
  "bot/selectprompt",
  async (payload) => {
    let formData = new FormData();
    formData.append("prompt", payload.prompt);
    formData.append("botId", payload.botId);
    const response = await axios.post(
      process.env.REACT_APP_NEW_BACKEND_URL+"/train/prompt",
      formData
    );
    return response.data;
  }
);

export const trainText = createAsyncThunk("bot/traintext", async (payload) => {
  let formData = new FormData();
  formData.append("botId", payload.botId);
  formData.append("text", payload.text);
  formData.append("userId", payload.userId);
  const response = await axios.post(
    process.env.REACT_APP_NEW_BACKEND_URL+"/train/text",
    formData
  );
  return response.data;
});

export const trainPdf = createAsyncThunk("bot/trainpdf", async (payload) => {
  console.log(payload);
  let formData = new FormData();
  formData.append("file", payload.selectedFiles[0]);
  formData.append("botId", payload.botId);
  formData.append("userId", payload.userId);

  const response = await axios.post(
    process.env.REACT_APP_NEW_BACKEND_URL+"/train/pdf",
    formData
  );
  console.log(response);
  return response.data;
});

export const getBaseData = createAsyncThunk(
  "bot/getBaseData",
  async (payload) => {
    console.log(payload);
    let formData = new FormData();
    formData.append("userId", payload.userId);

    const response = await axios.post(
      process.env.REACT_APP_NEW_BACKEND_URL+"/bot/getTrainData",
      formData
    );
    console.log(response);
    return response.data;
  }
);

export const createApiKey = createAsyncThunk(
  "bot/create/apikey",
  async (payload) => {
    let formData = new FormData();
    formData.append("userId", payload.userId);
    formData.append("botId", payload.botId);

    const response = await axios.post(
      process.env.REACT_APP_NEW_BACKEND_URL+"/bot/apikey/create",
      formData
    );
    return response.data;
  }
);

export const recreateApiKey = createAsyncThunk(
  "bot/recreate/apikey",
  async (payload) => {
    let formData = new FormData();
    formData.append("userId", payload.userId);
    formData.append("botId", payload.botId);

    const response = await axios.post(
      process.env.REACT_APP_NEW_BACKEND_URL+"/bot/apikey/recreate",
      formData
    );
    return response.data;
  }
);

export const apiReqeust = createAsyncThunk(
  "bot/api/request",
  async (payload) => {
    let formData = new FormData();
    // formData.append("userId", payload.userId);
    // formData.append("botId", payload.botId);
    formData.append("apikey", payload.apikey);
    formData.append("question", payload.question);

    const response = await axios.post(
      process.env.REACT_APP_NEW_BACKEND_URL+`/api/${payload.botId}`,
      formData
    );
    return response.data;
  }
);

export const addEmail = createAsyncThunk("bot/add/email", async (payload) => {
  let formData = new FormData();
  // formData.append("userId", payload.userId);
  formData.append("email", payload.email);

  const response = await axios.post(
    process.env.REACT_APP_NEW_BACKEND_URL+"/addEmail",
    formData
  );
  return response.data;
});

export const botReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateCurrentBotType: (state, payload) => {
      state.currentBotDetail.type = payload.payload.type;
      // state.currentBotDetail.bot_type = payload.payload.bot_type;
    },
    updateCurrentBotName: (state, payload) => {
      state.currentBotDetail.name = payload.payload.name;
      state.currentBotDetail.desc = payload.payload.desc;
      state.currentBotDetail.prompt = payload.payload.prompt;
      state.currentBotDetail.knowledge_base_type = payload.payload.btype;
    },
    updateCurrentBotPrompt: (state, payload) => {
      state.currentBotDetail.prompt = payload.payload.prompt;
    },
    updateCurrentBotKnowledge: (state, payload) => {
      state.currentBotDetail.knowledge_base_name = payload.payload.name;
      state.currentBotDetail.knowledge_base_desc = payload.payload.desc;
    },
    removeCurrentBot: (state, payload) => {
      state.currentBot = "";
    },
    setConsoleOutput: (state, payload) => {
      if (payload.payload.type === "add") {
        state.output.push(payload.payload.content);
      } else if (payload.payload.type === "clear") {
        state.output = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBot.fulfilled, (state, { payload }) => {
      if (payload.status === "error") {
        state.errors = payload.msg;
        NotificationManager.error("", state.errors, 2000);
      } else {
        state.bot = payload.bot;
        state.currentBotDetail = {
          type: payload.bot.type,
          bot_type: payload.bot.bot_type,
          name: payload.bot.name,
          desc: payload.bot.desc,
          knowledge_base_type: 0,
          prompt: payload.bot.prompt,
          knowledge_base_name: "",
          knowledge_base_desc: "",
        };
        console.log(state.bot);
        // NotificationManager.success("Created New Bot Successfully!");
      }
    });
    builder.addCase(getBots.fulfilled, (state, { payload }) => {
      if (payload.status === "error") {
        state.errors = payload.msg;
        NotificationManager.error("", state.errors, 2000);
      } else {
        state.bots = [...payload.bots];
        console.log(state.bots);
        // NotificationManager.success("Created New Bot Successfully!");
      }
    });
    builder.addCase(createBot.fulfilled, (state, { payload }) => {
      state.training = false;
      if (payload.status === "error") {
        state.errors = payload.msg;
        NotificationManager.error("", state.errors, 2000);
      } else {
        state.currentBot = payload.id;
        NotificationManager.success("Created New Bot Successfully!");

        state.currentBotDetail = {
          type: 0,
          bot_type: 0,
          name: "",
          desc: "",
          knowledge_base_type: 0,
          prompt: "",
          knowledge_base_name: "",
          knowledge_base_desc: "",
        };
      }
    });
    builder.addCase(customizeBot.fulfilled, (state, { payload }) => {
      state.training = false;
      if (payload.status === "error") {
        state.errors = payload.msg;
        NotificationManager.error("", state.errors, 2000);
      } else {
        NotificationManager.success("Succesfully Customized!");
      }
    });
    builder.addCase(selectPrompt.fulfilled, (state, { payload }) => {
      state.training = false;
      if (payload.status === "error") {
        state.errors = payload.msg;
        NotificationManager.error("", state.errors, 2000);
      } else {
        NotificationManager.success("Prompt Succesfully Selected!");
      }
    });
    builder.addCase(setBase.fulfilled, (state, { payload }) => {
      state.training = false;
      if (payload.status === "error") {
        state.errors = payload.msg;
        NotificationManager.error("", state.errors, 2000);
      } else {
        NotificationManager.success("Succesfully Selected!");
      }
    });
    builder.addCase(publishBot.fulfilled, (state, { payload }) => {
      state.training = false;
      if (payload.status === "error") {
        state.errors = payload.msg;
        NotificationManager.error("", state.errors, 2000);
      } else {
        NotificationManager.success("Succesfully Published!");
      }
    });
    builder.addCase(trainText.pending, (state, action) => {
      state.training = true;
    });
    builder.addCase(trainText.fulfilled, (state, { payload }) => {
      state.training = false;
      if (payload.status === "error") {
        state.errors = payload.msg;
        NotificationManager.error("", state.errors, 2000);
      } else {
        NotificationManager.success("Successfully Trained!");
      }
    });
    builder.addCase(trainPdf.pending, (state, action) => {
      state.training = true;
      console.log(state.training);
    });
    builder.addCase(trainPdf.fulfilled, (state, { payload }) => {
      state.training = false;
      if (payload.status === "error") {
        state.errors = payload.msg;
        NotificationManager.error("", state.errors, 2000);
      } else {
        NotificationManager.success("Successfully Trained!");
      }
      console.log(state.training);
    });
    builder.addCase(getBaseData.fulfilled, (state, { payload }) => {
      state.training = false;
      if (payload.status === "error") {
        state.errors = payload.msg;
        NotificationManager.error("", state.errors, 2000);
      } else {
        state.trainData = payload.bots;
      }
      console.log(state.trainData);
    });
    builder.addCase(createApiKey.fulfilled, (state, { payload }) => {
      if (payload.status === "error") {
        state.errors = payload.msg;
        NotificationManager.error("", state.errors, 2000);
      } else {
        state.apikey = payload.apikey;
      }
      console.log(state.trainData);
    });
    builder.addCase(addEmail.fulfilled, (state, { payload }) => {
      if (payload.status === "error") {
        state.errors = payload.msg;
        NotificationManager.error("", state.errors, 2000);
      } else {
        NotificationManager.success("Email Successfully Saved!");
      }
      console.log(state.trainData);
    });
    builder.addCase(recreateApiKey.fulfilled, (state, { payload }) => {
      if (payload.status === "error") {
        state.errors = payload.msg;
        NotificationManager.error("", state.errors, 2000);
      } else {
        state.apikey = payload.apikey;
      }
      console.log(state.trainData);
    });
    builder.addCase(apiReqeust.fulfilled, (state, { payload }) => {
      if (payload.status === "error") {
        state.apiData.answer = "";
        state.apiData.errors = payload.msg;
      } else {
        state.apiData.answer = payload.answer;
        state.apiData.errors = "";
      }
    });
  },
});

export const {
  updateCurrentBotType,
  updateCurrentBotName,
  updateCurrentBotPrompt,
  updateCurrentBotKnowledge,
  removeCurrentBot,
  setConsoleOutput,
} = botReducer.actions;
export default botReducer.reducer;
