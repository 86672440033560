import ChatBot from "./chatbot";
import ChatBotProd from "./chatbotProd";
import { useParams } from "react-router";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBot } from "../../reducers/botReducer";
import Navbar from "../Navbar";
import HomeSideBar from "../HomeSideBar";

const BotPage = () => {
  let { id } = useParams();

  return (
    <div className="flex">
      <HomeSideBar step={1} />
      <div className="w-full">
        <Navbar />
        <div className="m-4 sm:m-[5px]">
          <ChatBotProd botId={id} />
        </div>
      </div>
    </div>
  );
};

export default BotPage;
