import CodeEditor from "@uiw/react-textarea-code-editor";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

const ResponseApi = () => {
  const [code, setCode] = useState(
    `{
    "answer": "Mount Everest is 8,848.86 meters (29,031.7 feet) tall. It is the highest mountain in the world, located on the border between Nepal and China."
}`
  );

  const errors = useSelector((state) => state.bot.apiData.errors);
  const answer = useSelector((state) => state.bot.apiData.answer);

  return (
    <div className="flex-1 mr-[12px]">
      <h1 className="deploy-env-title mb-[10px]">API Response</h1>
      <div className="test-api-widget">
        <div
          className="flex justify-content items-center px-[20px] py-[12px]"
          style={{ borderBottom: "1px solid #F1F2F4" }}
        >
          <span className="deploy-text-default">
            Here’s what the API responds
          </span>
          <div className="flex-1"></div>
        </div>
        <div className="p-[16px] api-deploy-print">
          <CodeEditor
            value={
              errors == "" && answer == ""
                ? `{
    status : success,
    answer : The answer will be here.
}`
                : errors == ""
                ? `{
    status : success,
    answer : ${answer}
}`
                : `{
    status : error,
    error : ${errors}
}`
            }
            language="python"
            placeholder="Please enter Python code."
            // onChange={(evn) => setCode(evn.target.value)}
            padding={15}
            style={{
              fontSize: 14,
              backgroundColor: "#ffffff",
              fontFamily:
                "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ResponseApi;
