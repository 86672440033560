import classNames from "classnames";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";

const DeployEnvironment = ({ status, id }) => {
  const directLinkClicked = () => {};

  const copyJsCode = (value) => {
    navigator.clipboard.writeText(value);
    NotificationManager.success("Successfully Copied!");
  };
  return (
    <div
      className={classNames("deploy-env", {
        "deploy-pro-env": status == "prod",
        "deploy-dev-env": status == "dev",
      })}
    >
      <div className="flex justify-center items-center">
        <h1 className="deploy-env-title mr-2">
          {status == "prod" ? "Production" : "Dev"} environment
        </h1>
        <span className="deploy-badge">Deployed</span>
        <div className="flex-1"></div>
        {status == "prod" && (
          <Link
            className="flex justify-center items-center"
            to={
              "https://www.getbind.co/contact?utm_source=in-app-deploy&utm_medium=in-app-deploy&utm_campaign=in-app-deploy"
            }
            target="_blank"
          >
            <span className="deploy-text-default">Contact Us</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              className="ml-2"
            >
              <g clip-path="url(#clip0_275_1702)">
                <path
                  d="M12.75 13.4583H4.25C3.86042 13.4583 3.54167 13.1396 3.54167 12.75V4.25C3.54167 3.86042 3.86042 3.54167 4.25 3.54167H7.79167C8.18125 3.54167 8.5 3.22292 8.5 2.83333C8.5 2.44375 8.18125 2.125 7.79167 2.125H3.54167C2.75542 2.125 2.125 2.7625 2.125 3.54167V13.4583C2.125 14.2375 2.7625 14.875 3.54167 14.875H13.4583C14.2375 14.875 14.875 14.2375 14.875 13.4583V9.20833C14.875 8.81875 14.5562 8.5 14.1667 8.5C13.7771 8.5 13.4583 8.81875 13.4583 9.20833V12.75C13.4583 13.1396 13.1396 13.4583 12.75 13.4583ZM9.91667 2.83333C9.91667 3.22292 10.2354 3.54167 10.625 3.54167H12.4596L5.9925 10.0087C5.71625 10.285 5.71625 10.7312 5.9925 11.0075C6.26875 11.2837 6.715 11.2837 6.99125 11.0075L13.4583 4.54042V6.375C13.4583 6.76458 13.7771 7.08333 14.1667 7.08333C14.5562 7.08333 14.875 6.76458 14.875 6.375V2.83333C14.875 2.44375 14.5562 2.125 14.1667 2.125H10.625C10.2354 2.125 9.91667 2.44375 9.91667 2.83333Z"
                  fill="#1C274C"
                />
              </g>
              <defs>
                <clipPath id="clip0_275_1702">
                  <rect width="17" height="17" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        )}
      </div>
      <hr className="my-5" />
      <div>
        <span className="deploy-text-default flex items-center mb-3">
          Direct link:
          <a
            className="deploy-text-default ml-2"
            style={{ textDecorationLine: "underline" }}
            onClick={() => directLinkClicked()}
            // href={`https://app.getbind.co/bot/${id}`}
            href={`${process.env.REACT_APP_BASE_URL}/webbot/${id}`}
            target="_blank"
          >
            {/*Z https://app.getbind.co/bot/{id} */}
            {process.env.REACT_APP_BASE_URL}/webbot/{id}
          </a>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            className="ml-2"
          >
            <g clip-path="url(#clip0_275_1702)">
              <path
                d="M12.75 13.4583H4.25C3.86042 13.4583 3.54167 13.1396 3.54167 12.75V4.25C3.54167 3.86042 3.86042 3.54167 4.25 3.54167H7.79167C8.18125 3.54167 8.5 3.22292 8.5 2.83333C8.5 2.44375 8.18125 2.125 7.79167 2.125H3.54167C2.75542 2.125 2.125 2.7625 2.125 3.54167V13.4583C2.125 14.2375 2.7625 14.875 3.54167 14.875H13.4583C14.2375 14.875 14.875 14.2375 14.875 13.4583V9.20833C14.875 8.81875 14.5562 8.5 14.1667 8.5C13.7771 8.5 13.4583 8.81875 13.4583 9.20833V12.75C13.4583 13.1396 13.1396 13.4583 12.75 13.4583ZM9.91667 2.83333C9.91667 3.22292 10.2354 3.54167 10.625 3.54167H12.4596L5.9925 10.0087C5.71625 10.285 5.71625 10.7312 5.9925 11.0075C6.26875 11.2837 6.715 11.2837 6.99125 11.0075L13.4583 4.54042V6.375C13.4583 6.76458 13.7771 7.08333 14.1667 7.08333C14.5562 7.08333 14.875 6.76458 14.875 6.375V2.83333C14.875 2.44375 14.5562 2.125 14.1667 2.125H10.625C10.2354 2.125 9.91667 2.44375 9.91667 2.83333Z"
                fill="#1C274C"
              />
            </g>
            <defs>
              <clipPath id="clip0_275_1702">
                <rect width="17" height="17" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
        <span className="deploy-text-default flex items-center mb-3">
          Widget JS code:
          <div className="deploy-url flex-1 ml-3">
          {`<script async src=${process.env.REACT_APP_NEW_BACKEND_URL+`/bot/script/${id}`}></script>`}
          </div>
        </span>
        <div className="flex mt-3">
          <div className="flex-1"></div>
          <span className="flex items-center copy-code">
            <span
              className="deploy-text-default"
              style={{ textDecorationLine: "underline" }}
              onClick={() =>
                copyJsCode(
                  `<script async src=${process.env.REACT_APP_NEW_BACKEND_URL+`/bot/script/${id}`}></script>`
                )
              }
            >
              Copy JS code
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              className="ml-2"
            >
              <g clip-path="url(#clip0_275_1677)">
                <path
                  d="M10.625 14.1667H3.54167V4.95835C3.54167 4.56877 3.22292 4.25002 2.83333 4.25002C2.44375 4.25002 2.125 4.56877 2.125 4.95835V14.1667C2.125 14.9459 2.7625 15.5834 3.54167 15.5834H10.625C11.0146 15.5834 11.3333 15.2646 11.3333 14.875C11.3333 14.4854 11.0146 14.1667 10.625 14.1667ZM14.1667 11.3334V2.83335C14.1667 2.05419 13.5292 1.41669 12.75 1.41669H6.375C5.59583 1.41669 4.95833 2.05419 4.95833 2.83335V11.3334C4.95833 12.1125 5.59583 12.75 6.375 12.75H12.75C13.5292 12.75 14.1667 12.1125 14.1667 11.3334ZM12.75 11.3334H6.375V2.83335H12.75V11.3334Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_275_1677">
                  <rect width="17" height="17" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
        </div>
      </div>
    </div>
  );
};

export default DeployEnvironment;
