import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";

const Sidebar = ({ step, setStep }) => {
  const navigate = useNavigate();
  return (
    <div className="sidebar w-[350px]">
      <div className="flex px-[16px] py-[20px]">
        <img src="/logo-black.svg" onClick={() => navigate("/webbot")} />
        <div className="flex-1"></div>
        {/* <button
          style={{
            borderRadius: "4px",
            border: "1px solid var(--main-12-e-4-e-5-ea, #E4E5EA)",
            background: "#FFF",
            boxShadow: "0px 2px 4px 0px rgba(28, 39, 76, 0.07)",
            width: "28px",
            height: "28px",
          }}
          className="flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clip-path="url(#clip0_342_235)">
              <path
                d="M12.2583 5.59167C12.5833 5.91667 12.5833 6.44167 12.2583 6.76667L9.02501 10L12.2583 13.2333C12.5833 13.5583 12.5833 14.0833 12.2583 14.4083C11.9333 14.7333 11.4083 14.7333 11.0833 14.4083L7.25834 10.5833C6.93334 10.2583 6.93334 9.73334 7.25834 9.40833L11.0833 5.58333C11.4 5.26667 11.9333 5.26667 12.2583 5.59167Z"
                fill="#1C274C"
              />
            </g>
            <defs>
              <clipPath id="clip0_342_235">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="matrix(-1 0 0 1 20 0)"
                />
              </clipPath>
            </defs>
          </svg>
        </button> */}
      </div>
      <hr className="mx-[16px]"></hr>
      <Link className="flex m-[20px] p-[10px] previous-menu" to={"/"}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          className="mr-[10px]"
        >
          <path
            d="M17.5 9.62492H5.97629L8.49629 7.10492C8.83754 6.76367 8.83754 6.21242 8.49629 5.87117C8.15504 5.52992 7.60379 5.52992 7.26254 5.87117L3.24629 9.88742C2.90504 10.2287 2.90504 10.7799 3.24629 11.1212L7.26254 15.1374C7.60379 15.4787 8.15504 15.4787 8.49629 15.1374C8.83754 14.7962 8.83754 14.2449 8.49629 13.9037L5.97629 11.3749H17.5C17.9813 11.3749 18.375 10.9812 18.375 10.4999C18.375 10.0187 17.9813 9.62492 17.5 9.62492Z"
            fill="#3A4363"
          />
        </svg>
        Show previous menu
      </Link>
      <div className="my-[15px] mx-[24px] flex flex-col text-left">
        <p
          style={{
            color: "var(--main-54848-a-9-e, #848A9E)",
            fontFamily: "Inter",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            textTransform: "uppercase",
          }}
          className="mb-4"
        >
          Create bot
        </p>
        <Link
          className={classNames("mb-[25px] flex items-center", {
            "sidebar-item-current": step == 0,
            "sidebar-item-finish": step > 0,
            "sidebar-item-default": step < 0,
          })}
          // onClick={() => setStep(0)}
        >
          {step > 0 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="7"
              viewBox="0 0 8 7"
              fill="none"
              className="mr-2"
            >
              <path
                d="M1 3.69754L2.75 5.7207C3.07212 6.0931 3.59455 6.0931 3.91667 5.7207L7 1"
                stroke="#553CFA"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
          Select Bot type
        </Link>
        <Link
          className={classNames("mb-[25px] flex items-center", {
            "sidebar-item-current": step == 1,
            "sidebar-item-finish": step > 1,
            "sidebar-item-default": step < 1,
          })}
          // onClick={() => setStep(1)}
        >
          {step > 1 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="7"
              viewBox="0 0 8 7"
              fill="none"
              className="mr-2"
            >
              <path
                d="M1 3.69754L2.75 5.7207C3.07212 6.0931 3.59455 6.0931 3.91667 5.7207L7 1"
                stroke="#553CFA"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
          Customize prompt template
        </Link>
        <Link
          className={classNames("mb-[25px] flex items-center", {
            "sidebar-item-current": step == 2,
            "sidebar-item-finish": step > 2,
            "sidebar-item-default": step < 2,
          })}
          // onClick={() => setStep(2)}
        >
          {step > 2 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="7"
              viewBox="0 0 8 7"
              fill="none"
              className="mr-2"
            >
              <path
                d="M1 3.69754L2.75 5.7207C3.07212 6.0931 3.59455 6.0931 3.91667 5.7207L7 1"
                stroke="#553CFA"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
          Add knowledge base
        </Link>
        <Link
          className={classNames("mb-[25px] flex items-center", {
            "sidebar-item-current": step == 3,
            "sidebar-item-finish": step > 3,
            "sidebar-item-default": step < 3,
          })}
          // onClick={() => setStep(3)}
        >
          {step > 3 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="7"
              viewBox="0 0 8 7"
              fill="none"
              className="mr-2"
            >
              <path
                d="M1 3.69754L2.75 5.7207C3.07212 6.0931 3.59455 6.0931 3.91667 5.7207L7 1"
                stroke="#553CFA"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
          Plugins
        </Link>
        <Link
          className={classNames("mb-[25px] flex items-center", {
            "sidebar-item-current": step == 4,
            "sidebar-item-finish": step > 4,
            "sidebar-item-default": step < 4,
          })}
          // onClick={() => setStep(4)}
        >
          {step > 4 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="7"
              viewBox="0 0 8 7"
              fill="none"
              className="mr-2"
            >
              <path
                d="M1 3.69754L2.75 5.7207C3.07212 6.0931 3.59455 6.0931 3.91667 5.7207L7 1"
                stroke="#553CFA"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
          Deploy
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
/*

border-radius: 4px;
border: 1px solid var(--main-12-e-4-e-5-ea, #E4E5EA);
background: #FFF;
box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
*/
