import React, { useState } from "react";
import { auth } from "../../firebaseConfig";
import { NotificationManager } from "react-notifications";
import { sendPasswordResetEmail } from "firebase/auth";
import { history } from "../../history";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    setIsLoading(true);

    if (!validateEmail(email)) {
      NotificationManager.error(
        "Please enter a valid email address.",
        "",
        2000
      );
      setIsLoading(false);
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      NotificationManager.success(
        "Password reset link has been sent to your email address.",
        "",
        2000
      );
      setEmail("");
    } catch (err) {
      console.log("Error sending password reset email:", err);
      NotificationManager.error(
        "An error occurred. Please try again later",
        "",
        2000
      );
    } finally {
      setIsLoading(false);
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <main className="relative flex flex-col h-screen bg-gray-100 md:flex-row text-left">
      <div className="flex w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="md:w-1/2 text-left">
          <div className="md:w-3/4 mx-auto z-10 flex flex-col justify-center h-screen md:h-fit px-4  bg-white md:pt-12 rounded-lg">
            <div className="flex flex-col justify-center flex-1 pb-12">
              <div className="px-8">
                <div className="space-y-1 lg:pb-6">
                  <h2 className="text-[36px] font-semibold  text-gray-800">
                    Forgot Password
                  </h2>
                </div>

                <div className="block space-y-4">
                  <div className="space-y-2">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="w-full border-gray-300 rounded-md text-sm"
                      placeholder="Please Enter Your Email"
                      value={email}
                      onChange={handleInputChange}
                    />
                  </div>

                  <button
                    type="submit"
                    className="flex items-center justify-center w-full px-4 py-4 space-x-1 text-sm font-semibold leading-4 text-center transition-colors duration-300 bg-[#4529FA] rounded-md hover:bg-[#4529FA] text-blue-50"
                    disabled={isLoading}
                    onClick={handleSubmit}
                  >
                    {isLoading ? (
                      <span className="mr-2">Sending...</span>
                    ) : (
                      "Send Reset Link"
                    )}
                  </button>

                  <div className="text-xs text-gray-700 w-2/3 mx-auto text-center pt-3">
                    You acknowledge that you read, and agree to our{" "}
                    <a href="" className="font-semibold underline">
                      Terms of Service{" "}
                    </a>{" "}
                    and our{" "}
                    <a href="" className="font-semibold underline">
                      Privacy Policy.{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 hidden md:block">
          <div className="w-7/12">
            <img
              src="/logo-black.svg"
              alt="logo"
              className="z-20 inline-block h-7"
            />
            <div className="mt-20">
              <p className="text-[28px] font-semibold">
                Log in to access Bind AI{" "}
              </p>
              <p className="text-lg">Unlock access to Bind AI Copilot and Bind AI Studio.</p>
              <ol className="mt-8 text-lg">
                <li className="pb-3 flex items-start">
                  {" "}
                  <img
                    src="/assets/images/tick.svg"
                    alt="Tick"
                    className="pr-5 pt-1"
                  />
                  <span>Create AI Chatbots and AI Agents with Bind Studio</span>
                </li>
                <li className="pb-3 flex items-start">
                  <img
                    src="/assets/images/tick.svg"
                    alt="Tick"
                    className="pr-5 pt-1"
                  />
                  Use Bind AI copilot for chat, generating images and more
                </li>
                <li className="flex items-start">
                  <img
                    src="/assets/images/tick.svg"
                    alt="Tick"
                    className="pr-5 pt-1"
                  />
                  Store your chat history and connect your data
                </li>
              </ol>
              <img
                src="/assets/images/arrow.svg"
                alt="arrow"
                className="mt-8"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ForgotPassword;
