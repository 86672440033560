import React, { useState, useEffect } from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import prettier from "prettier/standalone";
import parserBabel from "prettier/parser-babel";

const TypingText = ({ text, isTyping  }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDisplayedText((prevText) => {
        return prevText + text.charAt(currentIndex);
      });
      if (currentIndex >= text.length) clearInterval(intervalId);
      else setCurrentIndex(currentIndex + 1);
    }, 10);
    return () => clearInterval(intervalId);
  }, [currentIndex]);

  const countSubstrings = (str, searchValue) => {
    let count = 0,
      i = 0;
    while (true) {
      const r = str.indexOf(searchValue, i);
      if (r !== -1) [count, i] = [count + 1, r + 1];
      else return count;
    }
  };

  // const formattedCode = prettier.format(displayedText.split("```")[1], {
  //   parser: "babel",
  //   plugins: [parserBabel],
  // });

  const displayText = () => {
    if (!isTyping) {
      return <p>{text}</p>; 
    }
    if (countSubstrings(displayedText, "```") > 1)
      return (
        <>
          {displayedText.split("```")[0]}
          <Editor
            value={displayedText.split("```")[1]}
            // onValueChange={(code) => setCode(code)}
            highlight={(code) => highlight(code, languages.js)}
            padding={10}
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              backgroundColor: "#0F152B",
              marginTop: "40px",
              marginBottom: "10px",
              padding: "18px",
              borderRadius: "4px",
              color: "white",
            }}
          />
          {displayedText.split("```")[2]}
        </>
      );
    else if (countSubstrings(displayedText, "|\n|") > 1)
      return (
        <>
          {displayedText.split("|")[0]}
          <table className="response-table">
            {displayedText.split("|\n|").map((text, index) => {
              if (
                index == 0 ||
                index == displayedText.split("|\n|").length - 1
              ) {
                if (index == 0) {
                  return (
                    <tr className="table-header">
                      {text.split("|").map((item, i) => {
                        if (i > 0)
                          return <td className="table-item">{item}</td>;
                      })}
                    </tr>
                  );
                } else {
                  return (
                    <tr className="table-text">
                      {text.split("|").map((item, i) => {
                        if (i < text.split("|").length - 1)
                          return <td className="table-item">{item}</td>;
                      })}
                    </tr>
                  );
                }
              } else if (index > 1)
                return (
                  <tr className="table-text">
                    {text.split("|").map((item) => (
                      <td className="table-item">{item}</td>
                    ))}
                  </tr>
                );
            })}
          </table>
          {
            displayedText
              .split("|\n|")
              [displayedText.split("|\n|").length - 1].split("|")[2]
          }
        </>
      );
    return <p>{displayedText}</p>;
  };

  return <>{displayText()}</>;
};

export default TypingText;
