import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { history } from "../../history";

const HandleAuthLink = () => {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const mode = params.get("mode");
    const oobCode = params.get("oobCode");

    if (mode === "resetPassword") {
      history.navigate(`/resetPassword?oobCode=${oobCode}`);
    } else if (mode === "verifyEmail") {
      history.navigate(`/verifyEmail?oobCode=${oobCode}`);
    } else {
      history.navigate("/login");
    }
  }, []);

  return <div>Please wait...</div>;
};

export default HandleAuthLink;
