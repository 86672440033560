import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { signOut } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { history } from "../history";

const HomeSideBar = ({ step, setStep }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    signOut(auth)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
    localStorage.clear();
    history.navigate("/login");
  };
  return (
    <div className="sidebar w-[350px] hidden sm:block">
      <div className="flex px-[16px] py-[20px]">
        <img src="/logo-black.svg" onClick={() => navigate("/webbot")} />
        <div className="flex-1"></div>
        {/* <button
          style={{
            borderRadius: "4px",
            border: "1px solid var(--main-12-e-4-e-5-ea, #E4E5EA)",
            background: "#FFF",
            boxShadow: "0px 2px 4px 0px rgba(28, 39, 76, 0.07)",
            width: "28px",
            height: "28px",
          }}
          className="flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clip-path="url(#clip0_342_235)">
              <path
                d="M12.2583 5.59167C12.5833 5.91667 12.5833 6.44167 12.2583 6.76667L9.02501 10L12.2583 13.2333C12.5833 13.5583 12.5833 14.0833 12.2583 14.4083C11.9333 14.7333 11.4083 14.7333 11.0833 14.4083L7.25834 10.5833C6.93334 10.2583 6.93334 9.73334 7.25834 9.40833L11.0833 5.58333C11.4 5.26667 11.9333 5.26667 12.2583 5.59167Z"
                fill="#1C274C"
              />
            </g>
            <defs>
              <clipPath id="clip0_342_235">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="matrix(-1 0 0 1 20 0)"
                />
              </clipPath>
            </defs>
          </svg>
        </button> */}
      </div>
      <hr className="mx-[16px]"></hr>
      <div className="my-[15px] mx-[24px] flex flex-col text-left">
        {/* <Link
          className={classNames("mb-[25px] flex items-center", {
            "sidebar-item-current": step == 0,
            "sidebar-item-default": step != 0,
          })}
          // onClick={() => setStep(0)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            className="mr-2"
          >
            <g clip-path="url(#clip0_1659_13267)">
              <path
                d="M11.024 15.8042C10.709 16.0492 10.2628 16.0492 9.94779 15.8042L4.56654 11.6217C4.25154 11.3767 3.81404 11.3767 3.49904 11.6217C3.05279 11.9717 3.05279 12.6454 3.49904 12.9954L9.41404 17.5979C10.044 18.0879 10.9278 18.0879 11.5665 17.5979L17.4815 12.9954C17.9278 12.6454 17.9278 11.9717 17.4815 11.6217L17.4728 11.6129C17.1578 11.3679 16.7203 11.3679 16.4053 11.6129L11.024 15.8042ZM11.5753 13.1617L17.4903 8.55917C17.9365 8.20917 17.9365 7.52667 17.4903 7.17667L11.5753 2.57417C10.9453 2.08417 10.0615 2.08417 9.42279 2.57417L3.50779 7.18542C3.06154 7.53542 3.06154 8.21792 3.50779 8.56792L9.42279 13.1704C10.0528 13.6604 10.9453 13.6604 11.5753 13.1617Z"
                fill="#1C274C"
              />
            </g>
            <defs>
              <clipPath id="clip0_1659_13267">
                <rect width="21" height="21" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Dashboard
        </Link> */}
        <Link
          className={classNames("mb-[25px] flex items-center", {
            "sidebar-item-current": step == 1,
            "sidebar-item-finish": step > 1,
            "sidebar-item-default": step < 1,
          })}
          to={"/"}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            className="mr-2"
          >
            <path
              d="M18.875 6.5H16.0902M18.875 10H16.25M18.875 13.5H16.0902M5.90975 6.5H3.125M7.5 4.90975V2.125M7.5 17.875V15.0902M11 4.75V2.125M11 17.875V15.25M14.5 4.90975V2.125M14.5 17.875V15.0902M5.75 10H3.125M5.90975 13.5H3.125M9.95 15.25H12.05C13.5202 15.25 14.2552 15.25 14.8167 14.9639C15.3107 14.7122 15.7122 14.3107 15.9639 13.8167C16.25 13.2552 16.25 12.5202 16.25 11.05V8.95C16.25 7.47986 16.25 6.74479 15.9639 6.18328C15.7122 5.68935 15.3107 5.28778 14.8167 5.03611C14.2552 4.75 13.5202 4.75 12.05 4.75H9.95C8.47986 4.75 7.74479 4.75 7.18328 5.03611C6.68935 5.28778 6.28778 5.68935 6.03611 6.18328C5.75 6.74479 5.75 7.47986 5.75 8.95V11.05C5.75 12.5202 5.75 13.2552 6.03611 13.8167C6.28778 14.3107 6.68935 14.7122 7.18328 14.9639C7.74479 15.25 8.47986 15.25 9.95 15.25ZM9.25 8.25H12.75V11.75H9.25V8.25Z"
              stroke="#3A4363"
              stroke-width="1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          My bots
        </Link>
        <Link
          className={classNames("mb-[25px] flex items-center", {
            "sidebar-item-current": step == 2,
            "sidebar-item-finish": step > 2,
            "sidebar-item-default": step < 2,
          })}
          to={"/createBot/select"}
          // onClick={() => setStep(2)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            className="mr-2"
          >
            <path
              d="M8.75027 8.75024V6.12524C8.75027 4.6755 7.57502 3.50024 6.12527 3.50024C4.67553 3.50024 3.50027 4.6755 3.50027 6.12524C3.50027 7.57499 4.67553 8.75024 6.12527 8.75024H8.75027ZM8.75027 8.75024V12.2502M8.75027 8.75024H12.2503M8.75027 12.2502V14.8752C8.75027 16.325 7.57502 17.5002 6.12527 17.5002C4.67553 17.5002 3.50027 16.325 3.50027 14.8752C3.50027 13.4255 4.67553 12.2502 6.12527 12.2502H8.75027ZM8.75027 12.2502H12.2503M12.2503 8.75024H14.8753C16.3251 8.75024 17.5003 7.57499 17.5003 6.12524C17.5003 4.6755 16.3251 3.50024 14.8753 3.50024C13.4255 3.50024 12.2503 4.6755 12.2503 6.12524V8.75024ZM12.2503 8.75024V12.2502M12.2503 12.2502H14.8753C16.3251 12.2502 17.5003 13.4255 17.5003 14.8752C17.5003 16.325 16.3251 17.5002 14.8753 17.5002C13.4255 17.5002 12.2503 16.325 12.2503 14.8752V12.2502Z"
              stroke="#3A4363"
              stroke-width="1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Integrations
        </Link>
        <Link
          className={classNames("mb-[25px] flex items-center", {
            "sidebar-item-current": step == 3,
            "sidebar-item-finish": step > 3,
            "sidebar-item-default": step < 3,
          })}
          to={"/createBot/select"}
          // onClick={() => setStep(3)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            className="mr-2"
          >
            <path
              d="M7.875 12.2502H13.125M4.025 8.75024H16.975C17.4651 8.75024 17.7101 8.75024 17.8973 8.65488C18.0619 8.57098 18.1957 8.43713 18.2796 8.27249C18.375 8.08531 18.375 7.84029 18.375 7.35024V4.90024C18.375 4.4102 18.375 4.16517 18.2796 3.978C18.1957 3.81336 18.0619 3.67951 17.8973 3.59561C17.7101 3.50024 17.4651 3.50024 16.975 3.50024H4.025C3.53496 3.50024 3.28993 3.50024 3.10276 3.59561C2.93812 3.67951 2.80426 3.81336 2.72037 3.978C2.625 4.16517 2.625 4.4102 2.625 4.90024V7.35024C2.625 7.84029 2.625 8.08531 2.72037 8.27249C2.80426 8.43713 2.93812 8.57098 3.10276 8.65488C3.28993 8.75024 3.53496 8.75024 4.025 8.75024ZM4.375 8.75024H16.625V14.7002C16.625 15.6803 16.625 16.1704 16.4342 16.5447C16.2665 16.874 15.9988 17.1418 15.6695 17.3095C15.2952 17.5002 14.8051 17.5002 13.825 17.5002H7.175C6.1949 17.5002 5.70486 17.5002 5.33052 17.3095C5.00123 17.1418 4.73351 16.874 4.56574 16.5447C4.375 16.1704 4.375 15.6803 4.375 14.7002V8.75024Z"
              stroke="#3A4363"
              stroke-width="1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Vector Indexes
        </Link>
        <Link
          className={classNames("mb-[25px] flex items-center", {
            "sidebar-item-current": step == 4,
            "sidebar-item-finish": step > 4,
            "sidebar-item-default": step < 4,
          })}
          to={"/createBot/select"}
          // onClick={() => setStep(4)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            className="mr-2"
          >
            <path
              d="M3.50056 6.12524H17.5005M4.37556 3.50024H16.6255M6.03453 17.5002H14.9666C15.8618 17.5002 16.3094 17.5002 16.6644 17.3326C16.977 17.1849 17.2388 16.9479 17.4169 16.6515C17.6191 16.315 17.6636 15.8696 17.7527 14.9788L18.1447 11.0592C18.2247 10.2591 18.2647 9.85904 18.1334 9.54982C18.0181 9.27822 17.8147 9.05343 17.5558 8.91159C17.2612 8.75024 16.8592 8.75024 16.0551 8.75024H4.94604C4.14196 8.75024 3.73993 8.75024 3.44531 8.91159C3.18651 9.05343 2.98303 9.27822 2.86772 9.54982C2.73645 9.85904 2.77646 10.2591 2.85647 11.0592L3.24843 14.9788C3.33751 15.8696 3.38204 16.315 3.58421 16.6515C3.76227 16.9479 4.02415 17.1849 4.33678 17.3326C4.69173 17.5002 5.13933 17.5002 6.03453 17.5002ZM13.1255 13.1252C13.1255 14.0918 11.9503 14.8752 10.5005 14.8752C9.05084 14.8752 7.87556 14.0918 7.87556 13.1252C7.87556 12.1587 9.05084 11.3752 10.5005 11.3752C11.9503 11.3752 13.1255 12.1587 13.1255 13.1252Z"
              stroke="#3A4363"
              stroke-width="1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Plugins
        </Link>
        {/* <Link
          className={classNames("mb-[25px] flex items-center", {
            "sidebar-item-current": step == 5,
            "sidebar-item-finish": step > 5,
            "sidebar-item-default": step < 5,
          })}
          // onClick={() => setStep(4)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            className="mr-2"
          >
            <path
              d="M11.375 17.5002V15.7502C11.375 13.334 9.41622 11.3752 7 11.3752C4.58376 11.3752 2.625 13.334 2.625 15.7502V17.5002H11.375ZM11.375 17.5002H18.375V16.6252C18.375 14.0479 16.4162 12.2502 14 12.2502C12.7634 12.2502 11.6465 12.7976 10.8508 13.6775M9.625 6.12524C9.625 7.57499 8.44974 8.75024 7 8.75024C5.55026 8.75024 4.375 7.57499 4.375 6.12524C4.375 4.6755 5.55026 3.50024 7 3.50024C8.44974 3.50024 9.625 4.6755 9.625 6.12524ZM15.75 7.87524C15.75 8.84177 14.9665 9.62524 14 9.62524C13.0335 9.62524 12.25 8.84177 12.25 7.87524C12.25 6.90875 13.0335 6.12524 14 6.12524C14.9665 6.12524 15.75 6.90875 15.75 7.87524Z"
              stroke="#3A4363"
              stroke-width="1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Team members
        </Link> */}
       {/* <Link
          className={classNames("mb-[25px] flex items-center", {
            "sidebar-item-current": step == 6,
            "sidebar-item-finish": step > 6,
            "sidebar-item-default": step < 6,
          })}
          // onClick={() => setStep(4)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            className="mr-2"
          >
            <path
              d="M14 6.125V5.425C14 4.44491 14 3.95486 13.8092 3.58052C13.6415 3.25123 13.3738 2.98351 13.0445 2.81574C12.6702 2.625 12.1801 2.625 11.2 2.625H9.8C8.81991 2.625 8.32986 2.625 7.95552 2.81574C7.62623 2.98351 7.35851 3.25123 7.19074 3.58052C7 3.95486 7 4.44491 7 5.425V6.125M2.64881 8.77301C2.96429 9.07541 6.375 12.25 10.5 12.25C14.625 12.25 18.0357 9.07541 18.3512 8.77301M2.64881 8.77301C2.625 9.18138 2.625 9.68319 2.625 10.325V14.175C2.625 15.6452 2.625 16.3802 2.91111 16.9417C3.16278 17.4357 3.56435 17.8372 4.05828 18.0889C4.61979 18.375 5.35486 18.375 6.825 18.375H14.175C15.6452 18.375 16.3802 18.375 16.9417 18.0889C17.4357 17.8372 17.8372 17.4357 18.0889 16.9417C18.375 16.3802 18.375 15.6452 18.375 14.175V10.325C18.375 9.68319 18.375 9.18138 18.3512 8.77301M2.64881 8.77301C2.67954 8.24609 2.74992 7.87464 2.91111 7.55828C3.16278 7.06435 3.56435 6.66278 4.05828 6.41111C4.61979 6.125 5.35486 6.125 6.825 6.125H14.175C15.6452 6.125 16.3802 6.125 16.9417 6.41111C17.4357 6.66278 17.8372 7.06435 18.0889 7.55828C18.2501 7.87464 18.3205 8.24609 18.3512 8.77301"
              stroke="#3A4363"
              stroke-width="1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Plans and Pricing
        </Link> */}
        <Link
          className={classNames("mb-[25px] flex items-center", {
            "sidebar-item-current": step == 2,
            "sidebar-item-finish": step > 2,
            "sidebar-item-default": step < 2,
          })}
          // to={"/createBot/select"}
          // onClick={() => setStep(2)}
          onClick={handleLogout}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            class="mr-2"
          >
            <path
              d="M8.75027 8.75024V6.12524C8.75027 4.6755 7.57502 3.50024 6.12527 3.50024C4.67553 3.50024 3.50027 4.6755 3.50027 6.12524C3.50027 7.57499 4.67553 8.75024 6.12527 8.75024H8.75027ZM8.75027 8.75024V12.2502M8.75027 8.75024H16.6253"
              stroke="#3A4363"
              stroke-width="1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.2503 12.2502V14.8752C12.2503 16.325 13.4255 17.5002 14.8753 17.5002C16.3251 17.5002 17.5003 16.325 17.5003 14.8752C17.5003 13.4255 16.3251 12.2502 14.8753 12.2502H12.2503ZM12.2503 12.2502H14.8753"
              stroke="#3A4363"
              stroke-width="1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Log Out
        </Link>
        {/* <Link
          className={classNames("mb-[25px] flex items-center", {
            "sidebar-item-current": step == 7,
            "sidebar-item-finish": step > 7,
            "sidebar-item-default": step < 7,
          })}
          // onClick={() => setStep(4)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            className="mr-2"
          >
            <path
              d="M2.625 8.75C2.625 5.36726 5.36726 2.625 8.75 2.625V8.75H2.625Z"
              stroke="#3A4363"
              stroke-width="1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.8125 18.375C8.18813 18.375 5.25 15.4368 5.25 11.8125H11.8125V5.25C15.4368 5.25 18.375 8.18813 18.375 11.8125C18.375 15.4368 15.4368 18.375 11.8125 18.375Z"
              stroke="#3A4363"
              stroke-width="1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Account analytics
        </Link> */}
      </div>
    </div>
  );
};

export default HomeSideBar;
/*

border-radius: 4px;
border: 1px solid var(--main-12-e-4-e-5-ea, #E4E5EA);
background: #FFF;
box-shadow: 0px 2px 4px 0px rgba(28, 39, 76, 0.07);
*/
