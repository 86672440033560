import classNames from "classnames";

const BotType = (props) => {
  const { title, desc, currentType, actualType, soon } = props;
  const { setType } = props;
  return (
    <div
      className={classNames("bot-type transition", {
        "bot-type-unselected": currentType != actualType,
        "bot-type-selected": currentType == actualType,
      })}
      onClick={() => setType(actualType)}
    >
      <h1>
        {title}
        {soon && <span className="soon-badge">soon</span>}
      </h1>
      <h2>{desc}</h2>
    </div>
  );
};

export default BotType;
