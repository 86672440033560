import React from "react";
import RegisterForm from "./RegisterForm";

const Register = () => {
  return (
    <main className="flex flex-col bg-[#E4E5EA] md:flex-row text-left md:h-screen">
      <div className="flex w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <RegisterForm />
        <div className="hidden md:block">
          <div className="flex">
            <div className="w-7/12">
              <img
                src="/logo-black.svg"
                alt="logo"
                className="z-20 inline-block h-7 mb-10"
              />
              <p className="text-[28px] font-semibold">
                Sign up to access Bind AI{" "}
              </p>
              <p className="text-lg">No credit card required</p>
              <ol className="mt-8 text-lg">
                <li className="pb-3 flex items-start">
                  {" "}
                  <img
                    src="/assets/images/tick.svg"
                    alt="Tick"
                    className="pr-5 pt-1"
                  />
                  <div>
                    <p className="font-semibold text-xl">
                      Create AI Chatbots and AI Agents
                    </p>
                    <p className="mt-2">
                      Easily connect your data and create chatbots without
                      requiring manual intents
                    </p>
                  </div>
                </li>
                <li className="pb-3 flex items-start">
                  <img
                    src="/assets/images/tick.svg"
                    alt="Tick"
                    className="pr-5 pt-1"
                  />
                  <div>
                    <p className="font-semibold text-xl">
                      Access Bind AI Copilot
                    </p>
                    <p className="mt-2">
                      Use Bind AI copilot for chat, generating images, coding
                      assistance and more
                    </p>
                  </div>
                </li>
                <li className="flex items-start">
                  <img
                    src="/assets/images/tick.svg"
                    alt="Tick"
                    className="pr-5 pt-1"
                  />
                  <div>
                    <p className="font-semibold text-xl">
                      Customize with data and integrations
                    </p>
                    <p className="mt-2">
                      Use your internal data and plugins to train AI copilot for
                      your business needs
                    </p>
                  </div>
                </li>
              </ol>
              <img
                src="/assets/images/arrow.svg"
                alt="arrow"
                className="mt-8"
              />
            </div>
          </div>
        </div>
        {/* <div className="md:flex-1">
        <img
          src="/images/patrick-tomasso-j1dj50Td7CQ-unsplash.jpg"
          alt="Image placeholder"
          className="object-cover object-right w-screen h-64 md:h-screen"
        />
      </div> */}
      </div>
    </main>
  );
};

export default Register;
