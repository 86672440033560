import ChatBot from "../bots/chatbot";
import classNames from "classnames";
import { useRef, useState, useEffect } from "react";
import { trainPdf, setBase, trainText } from "../../reducers/botReducer";
import { useSelector, useDispatch } from "react-redux";
import ScaleLoader from "react-spinners/ScaleLoader";
import ChatBotDev from "../bots/chatbotdev";
import BotKnowledgeBaseType from "./BotKnowledgeBaseType";
import {
  updateCurrentBotKnowledge,
  getBaseData,
} from "../../reducers/botReducer";
import BotKnowledgeBaseintegration from "./BotKnowledgeBaseIntegration";
import { useNavigate, useParams } from "react-router";

const BotKnowledge = (props) => {
  const inputFile = useRef(null);
  const setStep = props.setStep;
  const { id } = useParams();
  const [isNew, setIsNew] = useState(1);
  const [base, setCurrentBase] = useState("");
  const [files, setFiles] = useState([]);
  const [text, setText] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [selectedExB, setSelectedExB] = useState(-1);

  const [type, setType] = useState(0);
  const [integrationType, setIntegrationType] = useState(0);
  const training = useSelector((state) => state.bot.training);
  // const id = useSelector((state) => state.bot.currentBot);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const baseData = useSelector((state) => state.bot.trainData);
  const userId=localStorage.getItem("userId");

  const navigate = useNavigate();

  const selectFile = async (event) => {
    event.preventDefault();
    // const { files } = event.target;
    const selectedFiles = event.target.files;
    console.log(selectedFiles);
    setFiles(selectedFiles);

    trainFromPdf(selectedFiles);
    // props.setCurrentFile(selectedFiles);
  };

  const dispatch = useDispatch();

  const openFileDialog = () => {
    if (inputFile !== null) inputFile.current.click();
  };
  const trainFromPdf = async (selectedFiles) => {
    // console.log(currentUser.id);
    if (selectedFiles.length > 0)
      await dispatch(
        trainPdf({ file:selectedFiles, botId: id, userId: userId })
      );

    console.log(selectedFiles);
    setFiles([]);
  };

  const trainFromText = async () => {
    // alert(text);
    if (text != "")
      await dispatch(trainText({ botId: id, text, userId: userId }));
  };

  const setKnowledge = async () => {
    const payload = {
      base,
      id,
    };
    await dispatch(setBase(payload));
    setStep(3);
  };

  const nextStep = async () => {
    const payload = {
      name,
      desc,
    };
    await dispatch(updateCurrentBotKnowledge(payload));
    navigate(`/createBot/plugin/${id}`);
  };

  const skipStep = () => {
    navigate(`/createBot/plugin/${id}`);
  };

  const prevStep = async () => {
    navigate(`/createBot/customize/${id}`);
  };

  useEffect(() => {
    if(userId){
    const payload = {
      userId: userId,
    };
    dispatch(getBaseData(payload));
  }
  }, [userId]);
  return (
    <div className="my-[20px] pr-[20px] ml-[20px] w-[80%]">
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={selectFile}
        multiple
      />
      <h1
        className="text-left mb-[10px]"
        style={{
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "normal",
          color: "var(--main-1-c-274-c, #1C274C)",
        }}
      >
        Add a Knowledge Base for your Bot
      </h1>
      <h2
        style={{
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          color: "var(--main-1-c-274-c, #1C274C)",
        }}
        className="mb-[30px]"
      >
        Knowledge Base allows you to connect your own data to the Bot, this
        results in more accurate and comprehensive responses. This is done by
        creating vector embeddings. You can select existing kb, create new, add
        more data to existing kb.
      </h2>

      <div>
        <div className="flex mb-5">
          <div
            className={classNames(
              "flex items-center justify-center mr-4 cursor-pointer",
              {
                "knowledge-base-type-selected": !isNew,
                "knowledge-base-type-unselected": isNew,
              }
            )}
            onClick={() => setIsNew(0)}
          >
            {isNew ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <g clip-path="url(#clip0_773_5520)">
                  <path
                    d="M8.5 6.375H11.3333C11.7229 6.375 12.0417 6.05625 12.0417 5.66667C12.0417 5.27708 11.7229 4.95833 11.3333 4.95833H8.5C8.11042 4.95833 7.79167 5.27708 7.79167 5.66667C7.79167 6.05625 8.11042 6.375 8.5 6.375ZM8.5 9.20833H11.3333C11.7229 9.20833 12.0417 8.88958 12.0417 8.5C12.0417 8.11042 11.7229 7.79167 11.3333 7.79167H8.5C8.11042 7.79167 7.79167 8.11042 7.79167 8.5C7.79167 8.88958 8.11042 9.20833 8.5 9.20833ZM8.5 12.0417H11.3333C11.7229 12.0417 12.0417 11.7229 12.0417 11.3333C12.0417 10.9437 11.7229 10.625 11.3333 10.625H8.5C8.11042 10.625 7.79167 10.9437 7.79167 11.3333C7.79167 11.7229 8.11042 12.0417 8.5 12.0417ZM4.95833 4.95833H6.375V6.375H4.95833V4.95833ZM4.95833 7.79167H6.375V9.20833H4.95833V7.79167ZM4.95833 10.625H6.375V12.0417H4.95833V10.625ZM14.1667 2.125H2.83333C2.44375 2.125 2.125 2.44375 2.125 2.83333V14.1667C2.125 14.5562 2.44375 14.875 2.83333 14.875H14.1667C14.5562 14.875 14.875 14.5562 14.875 14.1667V2.83333C14.875 2.44375 14.5562 2.125 14.1667 2.125ZM13.4583 13.4583H3.54167V3.54167H13.4583V13.4583Z"
                    fill="#848A9E"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_773_5520">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <g clip-path="url(#clip0_773_4188)">
                  <path
                    d="M8.5 6.375H11.3333C11.7229 6.375 12.0417 6.05625 12.0417 5.66667C12.0417 5.27708 11.7229 4.95833 11.3333 4.95833H8.5C8.11042 4.95833 7.79167 5.27708 7.79167 5.66667C7.79167 6.05625 8.11042 6.375 8.5 6.375ZM8.5 9.20833H11.3333C11.7229 9.20833 12.0417 8.88958 12.0417 8.5C12.0417 8.11042 11.7229 7.79167 11.3333 7.79167H8.5C8.11042 7.79167 7.79167 8.11042 7.79167 8.5C7.79167 8.88958 8.11042 9.20833 8.5 9.20833ZM8.5 12.0417H11.3333C11.7229 12.0417 12.0417 11.7229 12.0417 11.3333C12.0417 10.9437 11.7229 10.625 11.3333 10.625H8.5C8.11042 10.625 7.79167 10.9437 7.79167 11.3333C7.79167 11.7229 8.11042 12.0417 8.5 12.0417ZM4.95833 4.95833H6.375V6.375H4.95833V4.95833ZM4.95833 7.79167H6.375V9.20833H4.95833V7.79167ZM4.95833 10.625H6.375V12.0417H4.95833V10.625ZM14.1667 2.125H2.83333C2.44375 2.125 2.125 2.44375 2.125 2.83333V14.1667C2.125 14.5562 2.44375 14.875 2.83333 14.875H14.1667C14.5562 14.875 14.875 14.5562 14.875 14.1667V2.83333C14.875 2.44375 14.5562 2.125 14.1667 2.125ZM13.4583 13.4583H3.54167V3.54167H13.4583V13.4583Z"
                    fill="#3A4363"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_773_4188">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}

            <p>Existing Knowledge bases</p>
          </div>
          <div
            className={classNames(
              "flex items-center justify-center cursor-pointer",
              {
                "knowledge-base-type-selected": isNew,
                "knowledge-base-type-unselected": !isNew,
              }
            )}
            onClick={() => setIsNew(1)}
          >
            {!isNew ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <g clip-path="url(#clip0_773_4194)">
                  <path
                    d="M8.50008 1.41666C4.59008 1.41666 1.41675 4.58999 1.41675 8.49999C1.41675 12.41 4.59008 15.5833 8.50008 15.5833C12.4101 15.5833 15.5834 12.41 15.5834 8.49999C15.5834 4.58999 12.4101 1.41666 8.50008 1.41666ZM11.3334 9.20832H9.20842V11.3333C9.20842 11.7229 8.88967 12.0417 8.50008 12.0417C8.1105 12.0417 7.79175 11.7229 7.79175 11.3333V9.20832H5.66675C5.27716 9.20832 4.95842 8.88957 4.95842 8.49999C4.95842 8.11041 5.27716 7.79166 5.66675 7.79166H7.79175V5.66666C7.79175 5.27707 8.1105 4.95832 8.50008 4.95832C8.88967 4.95832 9.20842 5.27707 9.20842 5.66666V7.79166H11.3334C11.723 7.79166 12.0417 8.11041 12.0417 8.49999C12.0417 8.88957 11.723 9.20832 11.3334 9.20832Z"
                    fill="#848A9E"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_773_4194">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <g clip-path="url(#clip0_773_5525)">
                  <path
                    d="M8.50008 1.41663C4.59008 1.41663 1.41675 4.58996 1.41675 8.49996C1.41675 12.41 4.59008 15.5833 8.50008 15.5833C12.4101 15.5833 15.5834 12.41 15.5834 8.49996C15.5834 4.58996 12.4101 1.41663 8.50008 1.41663ZM11.3334 9.20829H9.20842V11.3333C9.20842 11.7229 8.88967 12.0416 8.50008 12.0416C8.1105 12.0416 7.79175 11.7229 7.79175 11.3333V9.20829H5.66675C5.27716 9.20829 4.95842 8.88954 4.95842 8.49996C4.95842 8.11038 5.27716 7.79163 5.66675 7.79163H7.79175V5.66663C7.79175 5.27704 8.1105 4.95829 8.50008 4.95829C8.88967 4.95829 9.20842 5.27704 9.20842 5.66663V7.79163H11.3334C11.723 7.79163 12.0417 8.11038 12.0417 8.49996C12.0417 8.88954 11.723 9.20829 11.3334 9.20829Z"
                    fill="#3A4363"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_773_5525">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}

            <p>Create a New Knowledge Base</p>
          </div>
        </div>
      </div>
      {isNew ? (
        <div>
          <h1
            className="text-left mb-[20px]"
            style={{
              fontFamily: "Inter",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              color: "var(--main-1-c-274-c, #1C274C)",
            }}
          >
            Enter knowledge base name and description
          </h1>

          <div className="flex w-full mb-[30px]">
            <input
              type="text"
              className="form-field mr-5 w-[350px] text-left"
              placeholder="iPhone PricesDB-NEW"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              className="form-field flex-1"
              type="text"
              placeholder="Short Bot description for internal use"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            />
          </div>
          <div className="flex justify-between mb-[20px]">
            <BotKnowledgeBaseType
              type={0}
              currentType={type}
              setType={setType}
              title={"Upload file"}
              desc={"Upload file from your local storage"}
            />
            <BotKnowledgeBaseType
              type={1}
              currentType={type}
              setType={setType}
              title={"Paste data directly"}
              desc={"Copy and paste data from your computer manually"}
            />
            <BotKnowledgeBaseType
              type={2}
              currentType={type}
              setType={setType}
              title={"Add integration"}
              desc={
                "Connect GoogleDrive, Zendesk, Salesforce account to automatically ..."
              }
            />
          </div>
          {type == 0 && (
            <div className="upload-document py-[60px] px-[40px] flex justify-center items-center mb-6">
              <div className="mr-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="49"
                  height="49"
                  viewBox="0 0 49 49"
                  fill="none"
                >
                  <path
                    d="M24.5 24.5V42.5"
                    stroke="#3A4363"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M41.2799 37.28C43.2306 36.2165 44.7716 34.5338 45.6597 32.4973C46.5477 30.4607 46.7323 28.1865 46.1843 26.0334C45.6363 23.8803 44.3869 21.971 42.6333 20.6069C40.8796 19.2428 38.7216 18.5015 36.4999 18.5H33.9799C33.3745 16.1585 32.2462 13.9847 30.6798 12.142C29.1134 10.2993 27.1496 8.8357 24.9361 7.86121C22.7226 6.88673 20.317 6.42672 17.9002 6.51576C15.4833 6.60481 13.1181 7.2406 10.9823 8.37533C8.84649 9.51006 6.99574 11.1142 5.56916 13.0672C4.14259 15.0201 3.1773 17.2711 2.74588 19.6508C2.31446 22.0305 2.42813 24.4771 3.07835 26.8065C3.72856 29.136 4.8984 31.2877 6.49992 33.1"
                    stroke="#3A4363"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M32.5 32.5L24.5 24.5L16.5 32.5"
                    stroke="#3A4363"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>
                <h1
                  className="text-left mb-[10px]"
                  style={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    color: "var(--main-1-c-274-c, #1C274C)",
                  }}
                >
                  Select a file or drag and drop here
                </h1>
                <h2
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    color: "var(--main-54848-a-9-e, #848A9E)",
                  }}
                >
                  JPG, PNG or PDF, file size no more than 1GB
                </h2>
              </div>
              <div className="flex-1"></div>
              <button
                className="px-[16px] py-[14px] select-file-button"
                onClick={() => openFileDialog()}
              >
                Select File
              </button>
            </div>
          )}
          {type == 1 && (
            <div className="textarea">
              <div className="textarea-header">Editor</div>
              <textarea
                className="default-text p-[20px] w-full"
                placeholder="Paste text here..."
                rows={10}
                value={text}
                onChange={(e) => setText(e.target.value)}
                onBlur={() => trainFromText()}
              ></textarea>
            </div>
          )}
          {type == 2 && (
            <div>
              <div className="flex justify-between mb-5">
                <BotKnowledgeBaseintegration
                  title={"Slack"}
                  desc={
                    "Slack Connect provides a secure way for organizations ..."
                  }
                  type={0}
                  setType={setIntegrationType}
                  currentType={integrationType}
                />
                <BotKnowledgeBaseintegration
                  title={"Salesforce"}
                  desc={
                    "Slack Connect provides a secure way for organizations ..."
                  }
                  type={1}
                  setType={setIntegrationType}
                  currentType={integrationType}
                />
                <BotKnowledgeBaseintegration
                  title={"Database"}
                  desc={
                    "Slack Connect provides a secure way for organizations ..."
                  }
                  type={2}
                  setType={setIntegrationType}
                  currentType={integrationType}
                />
              </div>
              <BotKnowledgeBaseintegration
                title={"Google Drive"}
                desc={
                  "Slack Connect provides a secure way for organizations ..."
                }
                type={3}
                setType={setIntegrationType}
                currentType={integrationType}
              />
            </div>
          )}
        </div>
      ) : (
        <div>
          <h1
            className="text-left mb-[20px]"
            style={{
              fontFamily: "Inter",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              color: "var(--main-1-c-274-c, #1C274C)",
            }}
          >
            Select from your existing knowledge bases{selectedExB}
          </h1>
          <div className="radius-md existing-knowledge-bases">
            <div className="flex existing-knowledge-bases-header">
              <div className="w-[5%]"></div>
              <div className="w-[70%]">Name</div>
              <div>Status</div>
            </div>
            <div>
              {baseData !== undefined &&
                baseData.map((item, index) => (
                  <div>
                    <div className="flex justify-start items-center existing-knowledge-bases-item">
                      <input
                        type="radio"
                        name="existingBaseItem"
                        className="shadow-none mr-4"
                        value={index}
                        onChange={(e) => setSelectedExB(e.target.value)}
                      />
                      <div className="w-[70%]">
                        <h1
                          className="text-left"
                          style={{
                            fontFamily: "Inter",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                            color: "var(--main-1-c-274-c, #1C274C)",
                          }}
                        >
                          {item.name}
                        </h1>
                        <h2
                          style={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            color: "var(--main-1-c-274-c, #1C274C)",
                          }}
                        >
                          {"Attached Data (uploaded file)"}
                        </h2>
                      </div>
                      <div
                        className="flex justify-between items-center w-[20%] pr-[10px]"
                        style={{
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                          color: "var(--main-1-c-274-c, #1C274C)",
                        }}
                      >
                        <p>{item.date}</p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_773_4092)">
                            <path
                              d="M8.49992 5.66668C9.27909 5.66668 9.91659 5.02918 9.91659 4.25001C9.91659 3.47084 9.27909 2.83334 8.49992 2.83334C7.72075 2.83334 7.08325 3.47084 7.08325 4.25001C7.08325 5.02918 7.72075 5.66668 8.49992 5.66668ZM8.49992 7.08334C7.72075 7.08334 7.08325 7.72084 7.08325 8.50001C7.08325 9.27918 7.72075 9.91668 8.49992 9.91668C9.27909 9.91668 9.91659 9.27918 9.91659 8.50001C9.91659 7.72084 9.27909 7.08334 8.49992 7.08334ZM8.49992 11.3333C7.72075 11.3333 7.08325 11.9708 7.08325 12.75C7.08325 13.5292 7.72075 14.1667 8.49992 14.1667C9.27909 14.1667 9.91659 13.5292 9.91659 12.75C9.91659 11.9708 9.27909 11.3333 8.49992 11.3333Z"
                              fill="#848A9E"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_773_4092">
                              <rect width="17" height="17" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    {index == selectedExB && (
                      <div
                        style={{ backgroundColor: "rgba(234, 238, 244, 0.32)" }}
                      >
                        <div className="flex px-[23px] py-[17px]">
                          <h3 className="w-[50%] existing-base-table-title">
                            Source
                          </h3>
                          <h3 className="w-[30%] existing-base-table-title">
                            Status
                          </h3>
                          <h3 className="existing-base-table-title">-</h3>
                        </div>
                        {item.base.map((baseItem, index) => (
                          <div className="flex px-[23px] py-[17px]">
                            <h3 className="w-[50%] existing-base-table-content">
                              {baseItem}
                            </h3>
                            <h3 className="w-[30%] existing-base-table-content">
                              {item.date}
                            </h3>
                            <h3 className="existing-base-table-content">
                              View
                            </h3>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-start">
        <button className="prev-button" onClick={() => prevStep()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <g clip-path="url(#clip0_696_2975)">
              <path
                d="M14.2888 6.4304C13.915 6.05665 13.3113 6.05665 12.9375 6.4304L8.53875 10.8291C8.165 11.2029 8.165 11.8066 8.53875 12.1804L12.9375 16.5791C13.3113 16.9529 13.915 16.9529 14.2888 16.5791C14.6625 16.2054 14.6625 15.6016 14.2888 15.2279L10.5704 11.5L14.2888 7.78165C14.6529 7.4079 14.6529 6.79456 14.2888 6.4304Z"
                fill="#1C274C"
              />
            </g>
            <defs>
              <clipPath id="clip0_696_2975">
                <rect width="23" height="23" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Previous step
        </button>
        <button className="next-button" onClick={() => nextStep()}>
          Save and Continue
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <g clip-path="url(#clip0_696_2972)">
              <path
                d="M8.9221 6.43039C8.54835 6.80414 8.54835 7.40789 8.9221 7.78164L12.6404 11.5L8.9221 15.2183C8.54835 15.5921 8.54835 16.1958 8.9221 16.5696C9.29585 16.9433 9.8996 16.9433 10.2733 16.5696L14.6721 12.1708C15.0458 11.7971 15.0458 11.1933 14.6721 10.8196L10.2733 6.4208C9.90918 6.05664 9.29585 6.05664 8.9221 6.43039Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_696_2972">
                <rect width="23" height="23" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
        <button className="skip-button" onClick={() => skipStep()}>
          Skip this step
        </button>
      </div>
      {training == true ? (
        <div
          style={{
            position: "absolute",
            width: "100%",
            left: 0,
            top: 0,
            height: `calc(100%)`,
            backgroundColor: "rgba(100, 100, 100, 0.5)",
          }}
          className="flex justify-center items-center flex-col"
        >
          <h1 className="text-white text-lg mb-3">
            Wait a moment. Chat bot is training...
          </h1>
          <ScaleLoader color="white" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default BotKnowledge;
