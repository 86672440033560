import CodeEditor from "@uiw/react-textarea-code-editor";
import { useState } from "react";
const TestApi = ({ apikey, setApikey, question, setQuestion }) => {
  const [code, setCode] = useState(
    `function_call:
    name:
    productsUsingGET arguments: |-
      {
        "params": {
          "countryCode": "US",
          "q": "men's large blue button down shirt",
        }
      }`
  );
  return (
    <div className="flex-1 mr-[12px]">
      <h1 className="deploy-env-title mb-[10px]">Test your API</h1>
      <div className="test-api-widget">
        <div
          className="flex justify-content items-center px-[20px] py-[12px]"
          style={{ borderBottom: "1px solid #F1F2F4" }}
        >
          <span className="deploy-text-default">
            Test your API with a sample request
          </span>
          <div className="flex-1"></div>
          {/* <div className="flex justify-center items-center default-text mr-2">
            Python
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <g clip-path="url(#clip0_1267_3648)">
                <path
                  d="M8.49995 4.12951L10.2425 5.87201C10.5187 6.14826 10.965 6.14826 11.2412 5.87201C11.5175 5.59576 11.5175 5.14951 11.2412 4.87326L8.99579 2.62076C8.71954 2.34451 8.27329 2.34451 7.99704 2.62076L5.75162 4.87326C5.47537 5.14951 5.47537 5.59576 5.75162 5.87201C6.02787 6.14826 6.47412 6.14826 6.75037 5.87201L8.49995 4.12951ZM8.49995 12.8703L6.75745 11.1278C6.4812 10.8516 6.03495 10.8516 5.7587 11.1278C5.48245 11.4041 5.48245 11.8503 5.7587 12.1266L8.00412 14.3791C8.28037 14.6553 8.72662 14.6553 9.00287 14.3791L11.2483 12.1337C11.5245 11.8574 11.5245 11.4112 11.2483 11.1349C10.972 10.8587 10.5258 10.8587 10.2495 11.1349L8.49995 12.8703Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_1267_3648">
                  <rect width="17" height="17" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="api-deploy-copyIcon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <g clip-path="url(#clip0_275_1677)">
                <path
                  d="M10.625 14.1667H3.54167V4.95835C3.54167 4.56877 3.22292 4.25002 2.83333 4.25002C2.44375 4.25002 2.125 4.56877 2.125 4.95835V14.1667C2.125 14.9459 2.7625 15.5834 3.54167 15.5834H10.625C11.0146 15.5834 11.3333 15.2646 11.3333 14.875C11.3333 14.4854 11.0146 14.1667 10.625 14.1667ZM14.1667 11.3334V2.83335C14.1667 2.05419 13.5292 1.41669 12.75 1.41669H6.375C5.59583 1.41669 4.95833 2.05419 4.95833 2.83335V11.3334C4.95833 12.1125 5.59583 12.75 6.375 12.75H12.75C13.5292 12.75 14.1667 12.1125 14.1667 11.3334ZM12.75 11.3334H6.375V2.83335H12.75V11.3334Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_275_1677">
                  <rect width="17" height="17" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div> */}
        </div>
        <div className="p-[16px] api-deploy-print">
          {/* <CodeEditor
            value={code}
            language="python"
            placeholder="Please enter Python code."
            // onChange={(evn) => setCode(evn.target.value)}
            padding={15}
            style={{
              fontSize: 14,
              backgroundColor: "#ffffff",
              fontFamily:
                "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
              height: "100%",
            }}
          /> */}
          <div className="flex justify-center items-center mb-3">
            <p className="default-text mr-3 w-[100px] text-center">ApiKey : </p>
            <input
              placeholder="Your API-KEY"
              className="flex-1 rounded-md default-text"
              value={apikey}
              onChange={(e) => setApikey(e.target.value)}
            />
          </div>
          <div className="flex justify-center items-center">
            <p className="default-text mr-3 w-[100px] text-center">
              Question :{" "}
            </p>
            <textarea
              placeholder="Ask what you want to know."
              className="flex-1 rounded-md default-text p-3 border"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              rows={9}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestApi;
