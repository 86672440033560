import classNames from "classnames";
import { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from "react-redux";
import { createBot, getBots } from "../../reducers/botReducer";
import { useNavigate } from "react-router";
import BotType from "./BotType";
import { updateCurrentBotType } from "../../reducers/botReducer";

const BotSelect = (props) => {
  const [type, setType] = useState(0);

  const [name, setName] = useState("");
  const setStep = props.setStep;
  const user = useSelector((state) => state.auth.currentUser);
  const bots = useSelector((state) => state.bot.bots);
  const curBot = useSelector((state) => state.bot.currentBot);
  const navigate = useNavigate();
  const userId=localStorage.getItem('userId');
  const nextStep = async () => {
    const payload = {
      // email: user.email,
      type,
      userId:userId
      // btype: templateType,
    };
    await dispatch(createBot(payload));
    const currentBot = {
      type,
    };
    await dispatch(updateCurrentBotType(currentBot));
    // alert(res.id);
    // navigate(`/createBot/customize/${curBot}`);
    // console.log(curBot);
    // setStep(1);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (curBot !== "") {
      console.log(curBot);
      navigate(`/createBot/customize/${curBot}`);
    }
  }, [curBot]);

  useEffect(() => {
    // dispatch(getBots({ email: user.email }));
  }, []);

  const goBotPage = (id) => {
    navigate(`/bot/${id}`);
  };

  return (
    <div className="mx-[20px] my-[20px] text-left">
      <h1
        className="text-left mb-[10px]"
        style={{
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "normal",
          color: "var(--main-1-c-274-c, #1C274C)",
        }}
      >
        Select the type of Generative AI Application you want to build
      </h1>
      <h2
        style={{
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          color: "var(--main-1-c-274-c, #1C274C)",
        }}
        className="mb-[40px]"
      >
        For each predefined templates, a prompt template, agent is pre created.
      </h2>

      <h1
        className="text-left mb-[10px]"
        style={{
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "normal",
          color: "var(--main-1-c-274-c, #1C274C)",
        }}
      >
        Create a custom bot for your website
      </h1>
      <h2
        style={{
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          color: "var(--main-1-c-274-c, #1C274C)",
        }}
      >
        Select something below
      </h2>
      <div className="flex flex-wrap my-5">
        <BotType
          title="Create AI Assistant"
          desc="Build your own chatGPT customized with your data and integrations."
          currentType={type}
          actualType={0}
          setType={setType}
        />
        <BotType
          title="Create a Custom LLM API"
          desc="Build GenAI powered workflows and integrate in you applications"
          currentType={type}
          actualType={1}
          setType={setType}
        />
        {/* <BotType
          title="Create a GenAI Slackbot"
          desc="Add text"
          currentType={type}
          actualType={2}
          setType={setType}
        /> */}
        {/* <BotType
          title="Sales Agent Chatbot"
          desc="Boost sales with a persuasive chatbot guiding customers through products and purchases"
          currentType={type}
          actualType={3}
          setType={setType}
        />
        <BotType
          title="I don't know, I just need a bot"
          desc="Idk what to choose I know that it's better to choose something bcs the result can be unpredictable."
          currentType={type}
          actualType={4}
          setType={setType}
        /> */}
        {/* <button
          className={classNames(
            "px-10 py-2 rounded-md border border-green-400 transition",
            {
              "bg-green-400 text-white": type == 0,
              "text-green-400": type != 0,
            }
          )}
          onClick={() => {
            setType(0);
            props.setType(0);
          }}
        >
          Conversational Chat Bot
        </button>
        <button
          className={classNames(
            "px-10 py-2 rounded-md border border-blue-400 transition",
            {
              "bg-blue-400 text-white": type == 1,
              "text-blue-400": type != 1,
            }
          )}
          onClick={() => {
            setType(1);
            props.setType(1);
          }}
        >
          Other Option
        </button> */}
      </div>

      <div className="flex justify-start">
        <button className="prev-button" onClick={() => nextStep()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <g clip-path="url(#clip0_696_2975)">
              <path
                d="M14.2888 6.4304C13.915 6.05665 13.3113 6.05665 12.9375 6.4304L8.53875 10.8291C8.165 11.2029 8.165 11.8066 8.53875 12.1804L12.9375 16.5791C13.3113 16.9529 13.915 16.9529 14.2888 16.5791C14.6625 16.2054 14.6625 15.6016 14.2888 15.2279L10.5704 11.5L14.2888 7.78165C14.6529 7.4079 14.6529 6.79456 14.2888 6.4304Z"
                fill="#1C274C"
              />
            </g>
            <defs>
              <clipPath id="clip0_696_2975">
                <rect width="23" height="23" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Previous step
        </button>

        <button className="next-button" onClick={() => nextStep()}>
          Save and Continue
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <g clip-path="url(#clip0_696_2972)">
              <path
                d="M8.9221 6.43039C8.54835 6.80414 8.54835 7.40789 8.9221 7.78164L12.6404 11.5L8.9221 15.2183C8.54835 15.5921 8.54835 16.1958 8.9221 16.5696C9.29585 16.9433 9.8996 16.9433 10.2733 16.5696L14.6721 12.1708C15.0458 11.7971 15.0458 11.1933 14.6721 10.8196L10.2733 6.4208C9.90918 6.05664 9.29585 6.05664 8.9221 6.43039Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_696_2972">
                <rect width="23" height="23" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default BotSelect;
