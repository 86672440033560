import { useState } from "react";
import {
  selectPrompt,
  publishBot,
  apiReqeust,
} from "../../reducers/botReducer";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import APIDeployEnvironment from "./utils/APIDeployEnvironment";
import TestApi from "./utils/TestApi";
import ResponseApi from "./utils/ResponseApi";

const DeployApi = (props) => {
  const [option, setOption] = useState(0);
  const { id } = useParams();
  const [apikey, setApikey] = useState("");
  const [question, setQuestion] = useState("");

  const currentBotDetail = useSelector((state) => state.bot.currentBotDetail);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const botPublish = async () => {
    const payload = {
      botId: id,
      name: currentBotDetail.name,
      desc: currentBotDetail.desc,
      knowledge_base_type: currentBotDetail.knowledge_base_type,
      knowledge_base_name: currentBotDetail.knowledge_base_name,
      knowledge_base_desc: currentBotDetail.knowledge_base_desc,
    };
    await dispatch(publishBot(payload));
  };

  const sendRequest = async () => {
    const payload = {
      botId: id,
      apikey,
      question,
    };
    await dispatch(apiReqeust(payload));
  };

  const prevStep = async () => {
    navigate(`/createBot/plugin/${id}`);
  };

  return (
    <div className="w-[80%] my-[20px] mx-[20px]">
      <h1
        className="text-left mb-[10px]"
        style={{
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "normal",
          color: "var(--main-1-c-274-c, #1C274C)",
        }}
      >
        Deploy your custom API
      </h1>
      <h2
        style={{
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          color: "var(--main-1-c-274-c, #1C274C)",
        }}
        className="mb-[30px]"
      >
        Deploy gen ai custom API. Check our
        <span style={{ textDecorationLine: "underline" }} className="ml-2">
          Deployment Deploy gen ai custom API documentation
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            style={{ display: "inline" }}
          >
            <g clip-path="url(#clip0_275_1044)">
              <path
                d="M12.75 13.4583H4.25C3.86042 13.4583 3.54167 13.1396 3.54167 12.75V4.25C3.54167 3.86042 3.86042 3.54167 4.25 3.54167H7.79167C8.18125 3.54167 8.5 3.22292 8.5 2.83333C8.5 2.44375 8.18125 2.125 7.79167 2.125H3.54167C2.75542 2.125 2.125 2.7625 2.125 3.54167V13.4583C2.125 14.2375 2.7625 14.875 3.54167 14.875H13.4583C14.2375 14.875 14.875 14.2375 14.875 13.4583V9.20833C14.875 8.81875 14.5562 8.5 14.1667 8.5C13.7771 8.5 13.4583 8.81875 13.4583 9.20833V12.75C13.4583 13.1396 13.1396 13.4583 12.75 13.4583ZM9.91667 2.83333C9.91667 3.22292 10.2354 3.54167 10.625 3.54167H12.4596L5.9925 10.0087C5.71625 10.285 5.71625 10.7312 5.9925 11.0075C6.26875 11.2837 6.715 11.2837 6.99125 11.0075L13.4583 4.54042V6.375C13.4583 6.76458 13.7771 7.08333 14.1667 7.08333C14.5562 7.08333 14.875 6.76458 14.875 6.375V2.83333C14.875 2.44375 14.5562 2.125 14.1667 2.125H10.625C10.2354 2.125 9.91667 2.44375 9.91667 2.83333Z"
                fill="#1C274C"
              />
            </g>
            <defs>
              <clipPath id="clip0_275_1044">
                <rect width="17" height="17" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
      </h2>
      <APIDeployEnvironment id={id} status="none" />
      <div className="flex mt-10">
        <TestApi
          apikey={apikey}
          setApikey={setApikey}
          question={question}
          setQuestion={setQuestion}
        />
        <ResponseApi />
      </div>
      <div className="flex justify-center">
        <button className="next-button" onClick={sendRequest}>
          Send request
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            className="ml-2"
          >
            <g clip-path="url(#clip0_1267_3637)">
              <path
                d="M2.40817 14.4501L14.7686 9.15178C15.3423 8.90386 15.3423 8.09636 14.7686 7.84845L2.40817 2.55011C1.94067 2.3447 1.42359 2.69178 1.42359 3.1947L1.4165 6.46011C1.4165 6.81428 1.67859 7.11886 2.03275 7.16136L12.0415 8.50011L2.03275 9.83178C1.67859 9.88136 1.4165 10.1859 1.4165 10.5401L1.42359 13.8055C1.42359 14.3084 1.94067 14.6555 2.40817 14.4501Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1267_3637">
                <rect width="17" height="17" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
      <div className="flex justify-start items-center">
        <button className="prev-button" onClick={() => prevStep()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <g clip-path="url(#clip0_696_2975)">
              <path
                d="M14.2888 6.4304C13.915 6.05665 13.3113 6.05665 12.9375 6.4304L8.53875 10.8291C8.165 11.2029 8.165 11.8066 8.53875 12.1804L12.9375 16.5791C13.3113 16.9529 13.915 16.9529 14.2888 16.5791C14.6625 16.2054 14.6625 15.6016 14.2888 15.2279L10.5704 11.5L14.2888 7.78165C14.6529 7.4079 14.6529 6.79456 14.2888 6.4304Z"
                fill="#1C274C"
              />
            </g>
            <defs>
              <clipPath id="clip0_696_2975">
                <rect width="23" height="23" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Previous step
        </button>
        <button className="underline-button" onClick={() => botPublish()}>
          Save without deploying
        </button>
      </div>
    </div>
  );
};

export default DeployApi;
