import ChatBot from "../bots/chatbot";
import { useState } from "react";
import { selectPrompt, publishBot } from "../../reducers/botReducer";
import { useSelector, useDispatch } from "react-redux";
import ChatBotDev from "../bots/chatbotdev";
import DeployEnvironment from "./DeployEnvironment";
import DeploymentHistory from "./DeploymentHistory";
import { useNavigate, useParams } from "react-router";

const DeployBot = (props) => {
  const [option, setOption] = useState(0);
  const { id } = useParams();
  const currentBotDetail = useSelector((state) => state.bot.currentBotDetail);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const botPublish = async () => {
    const payload = {
      botId: id,
      name: currentBotDetail.name,
      desc: currentBotDetail.desc,
      knowledge_base_type: currentBotDetail.knowledge_base_type,
      knowledge_base_name: currentBotDetail.knowledge_base_name,
      knowledge_base_desc: currentBotDetail.knowledge_base_desc,
    };
    await dispatch(publishBot(payload));
  };

  const prevStep = async () => {
    navigate(`/createBot/plugin/${id}`);
  };

  return (
    // <div className="flex">
    //   <div className="p-3">
    //     <h1 className="text-3xl m-6">
    //       Generate JS for embedding in bot developer website
    //     </h1>

    //     <textarea
    //       className="w-full p-3 border rounded-md m-6"
    //       rows={10}
    //       placeholder={"< javascript code which will >"}
    //       value={prompts[option]}
    //     ></textarea>
    //     <div>
    //       <button
    //         className="bg-black px-10 py-2 rounded-md text-white m-6"
    //         onClick={() => setStep(2)}
    //       >
    //         Prev Step
    //       </button>
    //       <button
    //         className="border border-red-500 px-10 py-2 rounded-md text-red-500 m-6 transition hover:bg-red-500 hover:text-white"
    //         // onClick={() => setStep(2)}
    //         onClick={() => botPublish()}
    //       >
    //         Publish Your Bot
    //       </button>
    //     </div>
    //   </div>
    //   <div className="m-5 w-full text-left px-6 border rounded-md pb-2">
    //     <div className="mt-6 pt-10 mb-12">
    //       <h1 className="my-3">This is a Chatbot in test mode.</h1>
    //       <p className="my-3">
    //         Bot developer can test questions as soon as any updates are made to
    //         the prompt template. or a knowledgebase is added.
    //       </p>
    //       <p className="my-6">
    //         This panel is available in all screens (except the first screen)
    //       </p>
    //     </div>
    //     <ChatBot id={""} />
    //   </div>
    // </div>
    <div className="w-[80%] my-[20px] mx-[20px]">
      <h1
        className="text-left mb-[10px]"
        style={{
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "normal",
          color: "var(--main-1-c-274-c, #1C274C)",
        }}
      >
        Deploy chatbot
      </h1>
      <h2
        style={{
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          color: "var(--main-1-c-274-c, #1C274C)",
        }}
        className="mb-[30px]"
      >
        Deployment generates a custom application which you can share with your
        users or use it for internal testing. Please note that, the LLM call
        volume is limited.
        <span style={{ textDecorationLine: "underline" }} className="ml-2">
          Contact us if you're looking to deploy to your customers.
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            style={{ display: "inline" }}
          >
            <g clip-path="url(#clip0_275_1044)">
              <path
                d="M12.75 13.4583H4.25C3.86042 13.4583 3.54167 13.1396 3.54167 12.75V4.25C3.54167 3.86042 3.86042 3.54167 4.25 3.54167H7.79167C8.18125 3.54167 8.5 3.22292 8.5 2.83333C8.5 2.44375 8.18125 2.125 7.79167 2.125H3.54167C2.75542 2.125 2.125 2.7625 2.125 3.54167V13.4583C2.125 14.2375 2.7625 14.875 3.54167 14.875H13.4583C14.2375 14.875 14.875 14.2375 14.875 13.4583V9.20833C14.875 8.81875 14.5562 8.5 14.1667 8.5C13.7771 8.5 13.4583 8.81875 13.4583 9.20833V12.75C13.4583 13.1396 13.1396 13.4583 12.75 13.4583ZM9.91667 2.83333C9.91667 3.22292 10.2354 3.54167 10.625 3.54167H12.4596L5.9925 10.0087C5.71625 10.285 5.71625 10.7312 5.9925 11.0075C6.26875 11.2837 6.715 11.2837 6.99125 11.0075L13.4583 4.54042V6.375C13.4583 6.76458 13.7771 7.08333 14.1667 7.08333C14.5562 7.08333 14.875 6.76458 14.875 6.375V2.83333C14.875 2.44375 14.5562 2.125 14.1667 2.125H10.625C10.2354 2.125 9.91667 2.44375 9.91667 2.83333Z"
                fill="#1C274C"
              />
            </g>
            <defs>
              <clipPath id="clip0_275_1044">
                <rect width="17" height="17" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
      </h2>

      <DeployEnvironment status="prod" id={id} />
      {/* <DeployEnvironment status="dev" /> */}

      {/* <h1
          className="text-left mb-[10px]"
          style={{
            fontFamily: "Inter",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            color: "var(--main-1-c-274-c, #1C274C)",
          }}
        >
          All deployments history
        </h1>

        <div className="knowledge-basis">
          <div className="knowledge-basis-header">
            <h1 className="w-[20%]">Environment</h1>
            <h1 className="w-[40%]">Deployment</h1>
            <h1>Status</h1>
          </div>
          <div className="knowledge-basis-content">
            <DeploymentHistory
              type="Prod"
              title="New knowledgebase2"
              desc="Technical support documentation"
              status={0}
            />
            <DeploymentHistory
              type="Prod"
              title="iPhone PricesDB"
              desc="Price list for iPhones"
              status={1}
            />
            <DeploymentHistory
              type="Dev"
              title="Product list form website long title Ap..."
              desc="Scraping form apple.com"
              status={0}
            />
          </div>
        </div> */}
      <div className="flex justify-start">
        <button className="prev-button" onClick={() => prevStep()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <g clip-path="url(#clip0_696_2975)">
              <path
                d="M14.2888 6.4304C13.915 6.05665 13.3113 6.05665 12.9375 6.4304L8.53875 10.8291C8.165 11.2029 8.165 11.8066 8.53875 12.1804L12.9375 16.5791C13.3113 16.9529 13.915 16.9529 14.2888 16.5791C14.6625 16.2054 14.6625 15.6016 14.2888 15.2279L10.5704 11.5L14.2888 7.78165C14.6529 7.4079 14.6529 6.79456 14.2888 6.4304Z"
                fill="#1C274C"
              />
            </g>
            <defs>
              <clipPath id="clip0_696_2975">
                <rect width="23" height="23" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Previous step
        </button>
        <button className="next-button" onClick={() => botPublish()}>
          Deploy to Production
        </button>
      </div>

      {/* <select
          className="border rounded-md p-3 m-6"
          onChange={(e) => setOption(e.target.value)}
        >
          <option value={0} disabled>
            Dropdown which allows you to pick an option.
          </option>
          <hr />
          <option value={1}>Bot for sales</option>
          <option value={2}>Bot for support</option>
        </select>
        <h1 className="text-3xl m-6">Prompt template</h1>
        <textarea
          className="w-full p-3 border rounded-md m-6"
          rows={10}
          placeholder={`Show the full langchain Prompt template for the bot. Bot developer should be able to edit the template in this box.

You are a helpful customer support assistant. Respond to questions in a friendly tone
Use the below data

"...""`}
          value={prompts[option]}
        ></textarea>
        <button
          className="px-10 py-2 rounded-md text-red-600 m-6 border border-red-600 transition hover:bg-red-600 hover:text-white"
          onClick={() => promptSelect()}
        >
          Select Prompt
        </button>
        <div>
          <button
            className="bg-black px-10 py-2 rounded-md text-white m-6"
            onClick={() => setStep(0)}
          >
            Prev Step
          </button>
          <button
            className="bg-black px-10 py-2 rounded-md text-white m-6"
            onClick={() => setStep(2)}
          >
            Next Step
          </button>
        </div>
      </div>
      <div className="w-[300px] m-5 text-left px-6 border rounded-md pb-2">
        <div className="mt-6 pt-10 mb-12">
          <h1 className="my-3">This is a Chatbot in test mode.</h1>
          <p className="my-3">
            Bot developer can test questions as soon as any updates are made to
            the prompt template. or a knowledgebase is added.
          </p>
          <p className="my-6">
            This panel is available in all screens (except the first screen)
          </p>
        </div>
        <ChatBot id={""} /> */}
    </div>
  );
};

export default DeployBot;
