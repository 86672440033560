import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { history } from "../history";

const initialState = {
  currentUser: {},
  errors: "",
};

export const registerUser = createAsyncThunk(
  "auth/register",
  async (payload) => {
    let formData = new FormData();
    formData.append("firstname", payload.firstname);
    formData.append("lastname", payload.lastname);
    formData.append("email", payload.email);
    formData.append("userId", payload.userId);
    formData.append("password", payload.password);
    const response = await axios.post(
      process.env.REACT_APP_NEW_BACKEND_URL+"/register",
      formData
    );
    return response.data;
  }
);

export const loginUserWithId = createAsyncThunk(
  "auth/loginId",
  async (payload) => {
    let formData = new FormData();
    console.log(payload.id);
    formData.append("userId", payload.id);
    const response = await axios.post(
      process.env.REACT_APP_NEW_BACKEND_URL+"/loginId",
      formData
    );
    return response.data;
  }
);

export const loginUser = createAsyncThunk("auth/login", async (payload) => {
  let formData = new FormData();
  formData.append("email", payload.email);
  formData.append("password", payload.password);
  const response = await axios.post(
    process.env.REACT_APP_NEW_BACKEND_URL+"/login",
    formData
  );
  return response.data;
});

export const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.currentUser = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      if (payload.status === "error") {
        state.errors = payload.msg;
        NotificationManager.error("", state.errors, 2000);
      } else {
        // NotificationManager.success("Registration successful. Check your email for verification.");
        // history.navigate("/login");
      }
    });
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      if (payload.status === "error") {
        state.errors = payload.msg;
        NotificationManager.error("", state.errors, 2000);
      } else {
        state.currentUser = payload.user;
        localStorage.setItem("user", payload.user.email);
        NotificationManager.success("Successfully Logged In!");
        // history.navigate("/");
      }
    });
    builder.addCase(loginUserWithId.fulfilled, (state, { payload }) => {
      if (payload.status === "error") {
        state.errors = payload.msg;
      } else {
        state.currentUser = payload.user;
        localStorage.setItem("userId", payload.user.id);
        // NotificationManager.success("Successfully LogedIn!");
        // history.navigate("/createBot/select");
      }
    });
  },
});

export const { logout } = authReducer.actions;
export default authReducer.reducer;
