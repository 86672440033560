import { useSelector, useDispatch } from "react-redux";

const ConsoleOutput = () => {
  const outputText = useSelector((state) => state.bot.output);
  const botDetail = useSelector((state) => state.bot.currentBotDetail);

  const renderConversation = () => {
    if (!outputText || outputText.length === 0) {
      return <p>No conversation available</p>;
    }

    return outputText.map((message, index) => {
      if (message?.type === "HumanMessage") {
        return (
          <div key={index}>
            <p>
              <strong>Human: </strong> {message?.content}
            </p>
          </div>
        );
      } else if (message?.type === "AIMessage") {
        return (
          <div key={index}>
            <p>
              <strong>AI: </strong> {message?.content}
            </p>
          </div>
        );
      }
    });
  };

  return (
    <div className="console-output-item">
      <div
        className="flex px-[20px] py-[15px] justify-center items-center"
        style={{ borderBottom: "1px solid var(--main-12-e-4-e-5-ea, #E4E5EA)" }}
      >
        <div className="flex">
          <p className="console-output-text-enable mr-[24px]">
            Run-time LLM logs
          </p>
          {/* <p className="console-output-text-disable">OUTPUT</p> */}
        </div>
        <div className="flex-1"></div>
        {/* <div className="flex">
          <button className="flex justify-center items-center console-output-btn mr-[6px]">
            Expand{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <g clip-path="url(#clip0_1698_1524)">
                <path
                  d="M4.95857 10.6246C5.23482 10.9008 5.68107 10.9008 5.95732 10.6246L8.70565 7.87627L11.454 10.6246C11.7302 10.9008 12.1765 10.9008 12.4527 10.6246C12.729 10.3483 12.729 9.9021 12.4527 9.62585L9.20149 6.3746C8.92524 6.09835 8.47899 6.09835 8.20274 6.3746L4.95149 9.62585C4.68232 9.89502 4.68232 10.3483 4.95857 10.6246Z"
                  fill="#1C274C"
                />
              </g>
              <defs>
                <clipPath id="clip0_1698_1524">
                  <rect width="17" height="17" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
          <button className="flex justify-center items-center console-output-btn">
            Hide{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <g clip-path="url(#clip0_1698_1531)">
                <path
                  d="M12.9623 4.04459C12.686 3.76834 12.2398 3.76834 11.9635 4.04459L8.49977 7.50126L5.03602 4.03751C4.75977 3.76126 4.31352 3.76126 4.03727 4.03751C3.76102 4.31376 3.76102 4.76001 4.03727 5.03626L7.50102 8.50001L4.03727 11.9638C3.76102 12.24 3.76102 12.6863 4.03727 12.9625C4.31352 13.2388 4.75977 13.2388 5.03602 12.9625L8.49977 9.49876L11.9635 12.9625C12.2398 13.2388 12.686 13.2388 12.9623 12.9625C13.2385 12.6863 13.2385 12.24 12.9623 11.9638L9.49852 8.50001L12.9623 5.03626C13.2314 4.76709 13.2314 4.31376 12.9623 4.04459Z"
                  fill="#1C274C"
                />
              </g>
              <defs>
                <clipPath id="clip0_1698_1531">
                  <rect width="17" height="17" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div> */}
      </div>
      <div
        style={{
          overflow: "auto",
          height: "195px",
          width: `calc(100vw - 300px)`,
          textAlign: "left",
        }}
        className="px-3"
      >
        {(botDetail?.prompt || outputText.length > 0) && (
          <div>
            <div>
              <h1>Prompt Template</h1>
              <div className="output-text style={{ minHeight: '50px' }}">
                {botDetail?.prompt && botDetail.prompt}
              </div>
            </div>
            <div>
              <h1>User Conversation</h1>
              <div className="output-text style={{ minHeight: '50px' }}">
                {" "}
                {renderConversation()}
              </div>
            </div>
            <div>
              <h1>Retrieved Embeddings:</h1>
              {/* <pre className="output-text">{""}</pre> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConsoleOutput;
