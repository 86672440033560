import { useState, useEffect, useRef } from "react";
import BotThinking from "./BotThinking";
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import classNames from "classnames";
import { getBot } from "../../reducers/botReducer";
import useWebSocket from "react-use-websocket";
import { Link } from "react-router-dom";

const ChatBotProd = ({ botId }) => {
  const [history, setHistory] = useState([]);
  const [msg, setMsg] = useState("");
  const [status, setStatus] = useState(0);

  const [show, setShow] = useState(1);

  const [isSending, setIsSending] = useState(0);

  const currentBot = useSelector((state) => state.bot.bot);
  const dispatch = useDispatch();
  const bottomEl = useRef(null);
  const scrollToBottom = () => {
    bottomEl?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const { sendMessage, lastMessage } = useWebSocket(
    "wss://dolphin-app-m4323.ondigitalocean.app/echo2"
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (msg != "") {
      if (status == 1) {
        NotificationManager.warning("", "Bot is thinking...", 2000);
      } else {
        let tmp = [...history];
        tmp.push({
          type: "user",
          msg,
        });
        setHistory([...tmp]);
        setMsg("");
        setStatus(1);
        sendMessage(
          JSON.stringify({
            botId,
            question: msg,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (lastMessage !== null) {
      let tmp = [...history];
      tmp.push({
        type: "bot",
        msg: lastMessage["data"].split("@@@")[0],
      });
      // console.log(lastMessage["data"]);
      setHistory([...tmp]);
      setStatus(0);
    }
  }, [lastMessage]);

  const getCurrentBot = async () => {
    await dispatch(
      getBot({
        botId,
      })
    );
  };

  useEffect(() => {
    getCurrentBot();
  }, []);
  useEffect(() => {
    scrollToBottom();
  }, [history]);
  return (
    <>
      {show == 1 ? (
        <div className="fixed w-full sm:w-[700px] h-[400px] sm:h-[700px] flex flex-col production-chatbot-section-mobile sm:production-chatbot-section">
          <div
            className="p-3 flex justify-center items-center"
            style={{ borderBottom: "2px solid #EDEEF1", position: "relative" }}
          >
            <p className="chatbot-production-title mr-2 text-[12px] sm:text-[20px]">
              {currentBot.name}
            </p>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              className="chatbot-production-alert"
            >
              <g clip-path="url(#clip0_516_472)">
                <path
                  d="M10.0001 1.66675C5.40008 1.66675 1.66675 5.40008 1.66675 10.0001C1.66675 14.6001 5.40008 18.3334 10.0001 18.3334C14.6001 18.3334 18.3334 14.6001 18.3334 10.0001C18.3334 5.40008 14.6001 1.66675 10.0001 1.66675ZM10.0001 14.1667C9.54175 14.1667 9.16675 13.7917 9.16675 13.3334V10.0001C9.16675 9.54175 9.54175 9.16675 10.0001 9.16675C10.4584 9.16675 10.8334 9.54175 10.8334 10.0001V13.3334C10.8334 13.7917 10.4584 14.1667 10.0001 14.1667ZM10.8334 7.50008H9.16675V5.83341H10.8334V7.50008Z"
                  fill="#D6D8DE"
                />
              </g>
              <defs>
                <clipPath id="clip0_516_472">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div className="chatbot-production-desc text-left">
              This is the Apple Support Bot designed to answer your questions
              regarding Apple devices. It knows the current prices of all
              products and knows everything in general!
            </div>
            {/* <div className="chatbot-production-expand flex justify-center items-center ml-4">
              <p className="chatbot-production-expand-text mr-1">
                Expand to FullScreen
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <g clip-path="url(#clip0_516_490)">
                  <path
                    d="M4.25008 9.91675C3.8605 9.91675 3.54175 10.2355 3.54175 10.6251V12.7501C3.54175 13.1397 3.8605 13.4584 4.25008 13.4584H6.37508C6.76466 13.4584 7.08342 13.1397 7.08342 12.7501C7.08342 12.3605 6.76466 12.0417 6.37508 12.0417H4.95841V10.6251C4.95841 10.2355 4.63966 9.91675 4.25008 9.91675ZM4.25008 7.08342C4.63966 7.08342 4.95841 6.76466 4.95841 6.37508V4.95841H6.37508C6.76466 4.95841 7.08342 4.63966 7.08342 4.25008C7.08342 3.8605 6.76466 3.54175 6.37508 3.54175H4.25008C3.8605 3.54175 3.54175 3.8605 3.54175 4.25008V6.37508C3.54175 6.76466 3.8605 7.08342 4.25008 7.08342ZM12.0417 12.0417H10.6251C10.2355 12.0417 9.91675 12.3605 9.91675 12.7501C9.91675 13.1397 10.2355 13.4584 10.6251 13.4584H12.7501C13.1397 13.4584 13.4584 13.1397 13.4584 12.7501V10.6251C13.4584 10.2355 13.1397 9.91675 12.7501 9.91675C12.3605 9.91675 12.0417 10.2355 12.0417 10.6251V12.0417ZM9.91675 4.25008C9.91675 4.63966 10.2355 4.95841 10.6251 4.95841H12.0417V6.37508C12.0417 6.76466 12.3605 7.08342 12.7501 7.08342C13.1397 7.08342 13.4584 6.76466 13.4584 6.37508V4.25008C13.4584 3.8605 13.1397 3.54175 12.7501 3.54175H10.6251C10.2355 3.54175 9.91675 3.8605 9.91675 4.25008Z"
                    fill="#1C274C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_516_490">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div> */}
            <div className="flex-1"></div>
            <Link
              className="chatbot-production-powered cursor-pointer text-[10px] sm:text-[16px]"
              to={
                "https://www.getbind.co/?utm_source=demo-bot&utm_medium=demo&utm_campaign=demo-bot"
              }
              target="_blank"
            >
              Powered by bind
            </Link>
            <div className="chatbot-production-powered-desc text-left">
              This chatbot is created with the help of "Bind". Bind is a very
              good assistant bot for your website or application. It can solve
              any tasks!
              <div className="flex justify-center items-center mt-4">
                <p className="chatbot-production-powered-link mr-2 cursor-pointer">
                  Create Bot with Bind
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                >
                  <g clip-path="url(#clip0_563_2917)">
                    <path
                      d="M9.75 10.2917H3.25C2.95208 10.2917 2.70833 10.0479 2.70833 9.75V3.25C2.70833 2.95208 2.95208 2.70833 3.25 2.70833H5.95833C6.25625 2.70833 6.5 2.46458 6.5 2.16667C6.5 1.86875 6.25625 1.625 5.95833 1.625H2.70833C2.10708 1.625 1.625 2.1125 1.625 2.70833V10.2917C1.625 10.8875 2.1125 11.375 2.70833 11.375H10.2917C10.8875 11.375 11.375 10.8875 11.375 10.2917V7.04167C11.375 6.74375 11.1313 6.5 10.8333 6.5C10.5354 6.5 10.2917 6.74375 10.2917 7.04167V9.75C10.2917 10.0479 10.0479 10.2917 9.75 10.2917ZM7.58333 2.16667C7.58333 2.46458 7.82708 2.70833 8.125 2.70833H9.52792L4.5825 7.65375C4.37125 7.865 4.37125 8.20625 4.5825 8.4175C4.79375 8.62875 5.135 8.62875 5.34625 8.4175L10.2917 3.47208V4.875C10.2917 5.17292 10.5354 5.41667 10.8333 5.41667C11.1313 5.41667 11.375 5.17292 11.375 4.875V2.16667C11.375 1.86875 11.1313 1.625 10.8333 1.625H8.125C7.82708 1.625 7.58333 1.86875 7.58333 2.16667Z"
                      fill="#0053C9"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_563_2917">
                      <rect width="13" height="13" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div className="flex-1 pt-4" style={{ overflowY: "auto" }}>
            {history.map((item) =>
              item.type == "user" ? (
                <div className="user-chat-msg flex">
                  {item.msg}
                  <div className="flex-1"></div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_517_106)">
                      <path
                        d="M9.91667 7.79167C9.91667 8.18125 9.59792 8.5 9.20833 8.5H2.83333C2.44375 8.5 2.125 8.18125 2.125 7.79167C2.125 7.40208 2.44375 7.08333 2.83333 7.08333H9.20833C9.59792 7.08333 9.91667 7.40208 9.91667 7.79167ZM2.125 4.95833C2.125 5.34792 2.44375 5.66667 2.83333 5.66667H9.20833C9.59792 5.66667 9.91667 5.34792 9.91667 4.95833C9.91667 4.56875 9.59792 4.25 9.20833 4.25H2.83333C2.44375 4.25 2.125 4.56875 2.125 4.95833ZM7.08333 10.625C7.08333 10.2354 6.76458 9.91667 6.375 9.91667H2.83333C2.44375 9.91667 2.125 10.2354 2.125 10.625C2.125 11.0146 2.44375 11.3333 2.83333 11.3333H6.375C6.76458 11.3333 7.08333 11.0146 7.08333 10.625ZM12.7571 9.11625L13.26 8.61333C13.5362 8.33708 13.9825 8.33708 14.2587 8.61333L14.7617 9.11625C15.0379 9.3925 15.0379 9.83875 14.7617 10.115L14.2587 10.6179L12.7571 9.11625ZM12.2542 9.61917L8.59917 13.2742C8.53542 13.3379 8.5 13.4229 8.5 13.5221V14.5208C8.5 14.7192 8.65583 14.875 8.85417 14.875H9.85292C9.945 14.875 10.0371 14.8396 10.1008 14.7688L13.7558 11.1137L12.2542 9.61917Z"
                        fill="#A9ADBB"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_517_106">
                        <rect width="17" height="17" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              ) : (
                <div className="bot-chat-msg">
                  <div className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      className="mr-2"
                    >
                      <g clip-path="url(#clip0_516_500)">
                        <path
                          d="M19.46 8.00002L20.25 6.25002L22 5.46002C22.39 5.28002 22.39 4.73002 22 4.55002L20.25 3.76002L19.46 2.00002C19.28 1.61002 18.73 1.61002 18.55 2.00002L17.76 3.75002L16 4.54002C15.61 4.72002 15.61 5.27002 16 5.45002L17.75 6.24002L18.54 8.00002C18.72 8.39002 19.28 8.39002 19.46 8.00002ZM11.5 9.50002L9.91004 6.00002C9.56004 5.22002 8.44004 5.22002 8.09004 6.00002L6.50004 9.50002L3.00004 11.09C2.22004 11.45 2.22004 12.56 3.00004 12.91L6.50004 14.5L8.09004 18C8.45004 18.78 9.56004 18.78 9.91004 18L11.5 14.5L15 12.91C15.78 12.55 15.78 11.44 15 11.09L11.5 9.50002ZM18.54 16L17.75 17.75L16 18.54C15.61 18.72 15.61 19.27 16 19.45L17.75 20.24L18.54 22C18.72 22.39 19.27 22.39 19.45 22L20.24 20.25L22 19.46C22.39 19.28 22.39 18.73 22 18.55L20.25 17.76L19.46 16C19.28 15.61 18.72 15.61 18.54 16Z"
                          fill="url(#paint0_linear_516_500)"
                        />
                      </g>
                      <defs>
                        <linearGradient
                          id="paint0_linear_516_500"
                          x1="12.3538"
                          y1="1.70752"
                          x2="12.3538"
                          y2="22.2925"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FF51D9" />
                          <stop offset="1" stop-color="#A981EE" />
                        </linearGradient>
                        <clipPath id="clip0_516_500">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <p style={{ width: "90%" }}>{item.msg}</p>
                  </div>
                  <div className="flex ml-8 mt-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      className="mr-2"
                    >
                      <g clip-path="url(#clip0_517_93)">
                        <path
                          d="M10 13.3333H3.33333V4.66658C3.33333 4.29992 3.03333 3.99992 2.66667 3.99992C2.3 3.99992 2 4.29992 2 4.66658V13.3333C2 14.0666 2.6 14.6666 3.33333 14.6666H10C10.3667 14.6666 10.6667 14.3666 10.6667 13.9999C10.6667 13.6333 10.3667 13.3333 10 13.3333ZM13.3333 10.6666V2.66659C13.3333 1.93325 12.7333 1.33325 12 1.33325H6C5.26667 1.33325 4.66667 1.93325 4.66667 2.66659V10.6666C4.66667 11.3999 5.26667 11.9999 6 11.9999H12C12.7333 11.9999 13.3333 11.3999 13.3333 10.6666ZM12 10.6666H6V2.66659H12V10.6666Z"
                          fill="#A9ADBB"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_517_93">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      className="mr-2"
                    >
                      <g clip-path="url(#clip0_517_99)">
                        <path
                          d="M6.00008 14.0001H12.0001C12.5534 14.0001 13.0267 13.6667 13.2267 13.1867L15.2401 8.48675C15.3001 8.33341 15.3334 8.17341 15.3334 8.00008V6.66675C15.3334 5.93341 14.7334 5.33341 14.0001 5.33341H9.79342L10.4267 2.28675L10.4467 2.07341C10.4467 1.80008 10.3334 1.54675 10.1534 1.36675L9.44675 0.666748L5.05341 5.06008C4.81341 5.30008 4.66675 5.63341 4.66675 6.00008V12.6667C4.66675 13.4001 5.26675 14.0001 6.00008 14.0001ZM6.00008 6.00008L8.89342 3.10675L8.00008 6.66675H14.0001V8.00008L12.0001 12.6667H6.00008V6.00008ZM0.666748 6.00008H3.33341V14.0001H0.666748V6.00008Z"
                          fill="#A9ADBB"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_517_99">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_517_102)">
                        <path
                          d="M10.0001 2H4.00008C3.44675 2 2.97341 2.33333 2.77341 2.81333L0.760081 7.51333C0.700081 7.66667 0.666748 7.82667 0.666748 8V9.33333C0.666748 10.0667 1.26675 10.6667 2.00008 10.6667H6.20675L5.57341 13.7133L5.55341 13.9267C5.55341 14.2 5.66675 14.4533 5.84675 14.6333L6.55341 15.3333L10.9467 10.94C11.1867 10.7 11.3334 10.3667 11.3334 10V3.33333C11.3334 2.6 10.7334 2 10.0001 2ZM10.0001 10L7.10675 12.8933L8.00008 9.33333H2.00008V8L4.00008 3.33333H10.0001V10ZM12.6667 2H15.3334V10H12.6667V2Z"
                          fill="#A9ADBB"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_517_102">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              )
            )}
            <div ref={bottomEl}></div>
          </div>
          <form
            className="m-0 sm:m-[12px] flex px-1 sm:px-3"
            style={{ border: "1px solid #E4E5EA", borderRadius: "15px" }}
            onSubmit={handleSubmit}
          >
            <input
              type="text"
              className="flex-1 text-[10px] sm:text-[16px]"
              style={{
                border: "none",
                outline: "none",
                background: "transparent",
                color: "var(--main-1-c-274-c, #1C274C)",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              }}
              placeholder="Enter your message here ..."
              value={msg}
              onChange={(e) => {
                e.target.value != "" ? setIsSending(1) : setIsSending(0);
                return setMsg(e.target.value);
              }}
            />
            <div
              className="my-2 mr-2 sm:mr-5"
              style={{
                height: "65%",
                width: "2px",
                backgroundColor: "#E4E5EA",
              }}
            ></div>
            <button
              className="flex items-center justify-center cursor-pointer"
              type="submit"
            >
              <p
                className={classNames("mr-2 text-[10px] sm:text-[16px]", {
                  "chatbot-production-send-text-active": isSending,
                  "chatbot-production-send-text-inactive": !isSending,
                })}
              >
                Send
              </p>
              {isSending ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M7.56391 10.4362L9.70855 15.4403C10.0037 16.1291 10.9218 16.2019 11.2898 15.5491C12.0161 14.2611 13.0926 12.1839 14.1692 9.49254C16.0564 4.77446 17 1 17 1M7.56391 10.4362L2.55975 8.2915C1.871 7.99634 1.79823 7.0782 2.45096 6.71019C3.73897 5.98392 5.8162 4.90738 8.50752 3.83085C13.2256 1.94362 17 1 17 1M7.56391 10.4362L17 1"
                    stroke="#1C274C"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M7.56391 10.4362L9.70855 15.4403C10.0037 16.1291 10.9218 16.2019 11.2898 15.5491C12.0161 14.2611 13.0926 12.1839 14.1692 9.49254C16.0564 4.77446 17 1 17 1M7.56391 10.4362L2.55975 8.2915C1.871 7.99634 1.79823 7.0782 2.45096 6.71019C3.73897 5.98392 5.8162 4.90738 8.50752 3.83085C13.2256 1.94362 17 1 17 1M7.56391 10.4362L17 1"
                    stroke="#848A9E"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </button>
          </form>
        </div>
      ) : null}
      <div
        style={{
          width: "50px",
          height: "50px",
          backgroundColor: "#1C274C",
          borderRadius: "50%",
          right: "40px",
          bottom: "10px",
        }}
        className="flex justify-center items-center fixed "
        onClick={() => setShow(1 - show)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="44"
          height="44"
          viewBox="0 0 44 44"
          fill="none"
        >
          <path
            d="M14.8867 17.0316L22 24.1449L29.1133 17.0316C29.8283 16.3166 30.9833 16.3166 31.6983 17.0316C32.4133 17.7466 32.4133 18.9016 31.6983 19.6166L23.2833 28.0316C22.5683 28.7466 21.4133 28.7466 20.6983 28.0316L12.2833 19.6166C11.5683 18.9016 11.5683 17.7466 12.2833 17.0316C12.9983 16.3349 14.1717 16.3166 14.8867 17.0316Z"
            fill="white"
          />
        </svg>
      </div>
    </>
  );
};

export default ChatBotProd;
