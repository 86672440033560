import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { history } from "../../history";
import { auth } from "../../firebaseConfig";
import { NotificationManager } from "react-notifications";
import { confirmPasswordReset } from "firebase/auth";

const ResetPassword = () => {
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [oobCode, setOobCode] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const oobCode = params.get("oobCode");
    if (oobCode && oobCode !== "null") {
      setOobCode(oobCode);
    } else {
      history.navigate("/login");
      console.log("no oobcode in params");
    }
  }, [location, history]);

  const handleChangePassword = async () => {
    let errs = {};

    if (password === "") errs["password"] = "Password field is required!";
    if (confirmPassword === "")
      errs["confirmPassword"] = "Confirm Password field is required!";
    if (Object.keys(errs).length === 0) {
      if (password !== confirmPassword) {
        NotificationManager.error("Passwords do not match", "", 2000);
        return;
      }
      setIsLoading(true);

      try {
        await confirmPasswordReset(auth, oobCode, password);
        NotificationManager.success(
          "Successfully reset your password",
          "",
          2000
        );
        history.navigate("/login");
      } catch (err) {
        console.log("this is err", err);
        NotificationManager.error(
          "The password reset link is invalid or expired. Please request a new one.",
          "",
          2000
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log(errs);
      for (let err = 0; err < Object.keys(errs).length; err++)
        NotificationManager.error("", Object.values(errs)[err], 2000);
    }
  };

  return (
    <main className="relative flex flex-col h-screen bg-gray-100 md:flex-row text-left">
      <div className="flex w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="md:w-1/2 text-left">
          <div className="md:w-3/4 mx-auto z-10 flex flex-col justify-center h-screen md:h-fit px-4  bg-white md:pt-12 rounded-lg">
            <div className="flex flex-col justify-center flex-1 pb-12">
              <div className="px-8">
                <div className="space-y-1 lg:pb-6">
                  <h2 className="text-[36px] font-semibold  text-gray-800">
                    Reset Password
                  </h2>
                </div>

                <div className="block space-y-4">
                  <div className="space-y-2">
                    <input
                      id="password"
                      type="password"
                      value={password}
                      placeholder="Please Enter Your Password"
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full border-gray-300 rounded-md text-sm"
                      required
                    />
                  </div>
                  <div className="space-y-2">
                    <input
                      id="confirmPassword"
                      type="password"
                      value={confirmPassword}
                      placeholder="Please Enter Your Confirm Password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="w-full border-gray-300 rounded-md text-sm"
                      required
                    />
                  </div>
                  <button
                    onClick={handleChangePassword}
                    disabled={isLoading}
                    className="flex items-center justify-center w-full px-4 py-4 space-x-1 text-sm font-semibold leading-4 text-center transition-colors duration-300 bg-[#4529FA] rounded-md hover:bg-[#4529FA] text-blue-50"
                  >
                    {isLoading ? (
                      <span className="mr-2">Reset...</span>
                    ) : (
                      "Reset Password"
                    )}
                  </button>

                  <div className="text-xs text-gray-700 w-2/3 mx-auto text-center pt-3">
                    You acknowledge that you read, and agree to our{" "}
                    <a href="" className="font-semibold underline">
                      Terms of Service{" "}
                    </a>{" "}
                    and our{" "}
                    <a href="" className="font-semibold underline">
                      Privacy Policy.{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 hidden md:block">
          <div className="w-7/12">
            <img
              src="/logo-black.svg"
              alt="logo"
              className="z-20 inline-block h-7"
            />
            <div className="mt-20">
              <p className="text-[28px] font-semibold">
                Log in to access Bind AI{" "}
              </p>
              <p className="text-lg">Unlock access to Bind AI Copilot and Bind AI Studio.</p>
              <ol className="mt-8 text-lg">
                <li className="pb-3 flex items-start">
                  {" "}
                  <img
                    src="/assets/images/tick.svg"
                    alt="Tick"
                    className="pr-5 pt-1"
                  />
                  <span>Create AI Chatbots and AI Agents with Bind Studio</span>
                </li>
                <li className="pb-3 flex items-start">
                  <img
                    src="/assets/images/tick.svg"
                    alt="Tick"
                    className="pr-5 pt-1"
                  />
                  Use Bind AI copilot for chat, generating images and more
                </li>
                <li className="flex items-start">
                  <img
                    src="/assets/images/tick.svg"
                    alt="Tick"
                    className="pr-5 pt-1"
                  />
                  Store your chat history and connect your data
                </li>
              </ol>
              <img
                src="/assets/images/arrow.svg"
                alt="arrow"
                className="mt-8"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ResetPassword;
