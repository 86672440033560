import Navbar from "./Navbar";
import { useSelector, useDispatch } from "react-redux";
import CreateBot from "./bots";
import { useState, useEffect } from "react";
import BotSelect from "./steps/BotSelect";
import BotUpdate from "./steps/BotUpdate";
import BotKnowledge from "./steps/BotKnowledge";
import BotPublish from "./steps/BotPublish";
import BotPrompt from "./steps/BotPrompt";
import Sidebar from "./Sidebar";
import { loginUserWithId } from "../reducers/authReducer";
import BotPlugin from "./steps/BotPlugin";
import ChatBotDev from "./bots/chatbotdev";
import { useParams } from "react-router";
import ConsoleOutput from "./steps/utils/ConsoleOutput";

const Main = ({ step }) => {
  // const user = useSelector((state) => state.auth.currentUser);
  // const [step, setStep] = useState(0);
  let { id } = useParams();
  const [type, setType] = useState(0);
  const [name, setName] = useState("");
  const [prompt, setPrompt] = useState("");
  const [base, setBase] = useState("");
  const userId=localStorage.getItem("userId");

  const publishBot = () => {
    const botInfo = {
      type,
      name,
      prompt,
      base,
    };
    console.log(botInfo);
  };
  const dispatch = useDispatch();
  const loginWithId = (account) => {
    dispatch(loginUserWithId(account));
  };

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      const id = localStorage.getItem("userId");
      const account = {
        id: id,
      };
      console.log(account);
      loginWithId(account);
    }
  }, []);

  let bot;
  switch (step) {
    case 0:
      bot = <BotSelect setType={setType} setName={setName} />;
      break;
    case 1:
      bot = <BotPrompt setBase={setBase} id={id} />;
      break;
    case 2:
      bot = <BotKnowledge setPrompt={setBase} id={id} />;
      break;
    case 3:
      bot = <BotPlugin setBase={setBase} id={id} />;
      break;
    case 4:
      bot = <BotPublish publishBot={publishBot} id={id} />;
      break;
    default:
      bot = null;
  }
  return (
    <div className="flex w-full h-full">
      <Sidebar step={step} />
      <div className="w-full">
        <Navbar step={step} type={1} />

        {!userId ? null : ( // <h1 className="text-lg">This is the Main Content</h1>
          <div style={{ width: "100%" }}>
            {step == 0 && bot}
            {step > 0 && (
              <div>
                <div className="text-left flex">
                  {bot}
                  {step != 4 && <ChatBotDev id={id} />}
                </div>
                {step < 4 && <ConsoleOutput />}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Main;
