import React from "react";

const CustomModal = ({ children }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-50">
      {children}
    </div>
  );
};

export default CustomModal;
