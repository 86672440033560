import classNames from "classnames";
import { useState, useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { createApiKey, recreateApiKey } from "../../../reducers/botReducer";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

const APIDeployEnvironment = ({ status, id }) => {
  const directLinkClicked = () => {};

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const apikey = useSelector((state) => state.bot.apikey);
  const copyJsCode = (value) => {
    navigator.clipboard.writeText(value);
    NotificationManager.success("Successfully Copied!");
  };
  const userId=localStorage.getItem('userId');

  const create_ApiKey = async () => {
    const payload = {
      botId: id,
      userId: userId,
    };
    await dispatch(createApiKey(payload));
  };

  const generateNewApi = async () => {
    const payload = {
      botId: id,
      userId: userId,
    };
    await dispatch(recreateApiKey(payload));
  };

  useEffect(() => {
    create_ApiKey();
  }, []);
  return (
    <div
      className={classNames("deploy-env", {
        "deploy-pro-env": 1,
      })}
    >
      <div className="flex justify-center items-center">
        <h1 className="deploy-env-title mr-2">API Production environment</h1>
        <div className="flex-1"></div>
        {
          <Link
            className="flex justify-center items-center"
            to={
              "https://www.getbind.co/contact?utm_source=in-app-api-deploy&utm_medium=in-app-api-deploy&utm_campaign=in-app-api-deploy"
            }
            target="_blank"
          >
            <span className="deploy-text-default">Contact US</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              className="ml-2"
            >
              <g clip-path="url(#clip0_275_1702)">
                <path
                  d="M12.75 13.4583H4.25C3.86042 13.4583 3.54167 13.1396 3.54167 12.75V4.25C3.54167 3.86042 3.86042 3.54167 4.25 3.54167H7.79167C8.18125 3.54167 8.5 3.22292 8.5 2.83333C8.5 2.44375 8.18125 2.125 7.79167 2.125H3.54167C2.75542 2.125 2.125 2.7625 2.125 3.54167V13.4583C2.125 14.2375 2.7625 14.875 3.54167 14.875H13.4583C14.2375 14.875 14.875 14.2375 14.875 13.4583V9.20833C14.875 8.81875 14.5562 8.5 14.1667 8.5C13.7771 8.5 13.4583 8.81875 13.4583 9.20833V12.75C13.4583 13.1396 13.1396 13.4583 12.75 13.4583ZM9.91667 2.83333C9.91667 3.22292 10.2354 3.54167 10.625 3.54167H12.4596L5.9925 10.0087C5.71625 10.285 5.71625 10.7312 5.9925 11.0075C6.26875 11.2837 6.715 11.2837 6.99125 11.0075L13.4583 4.54042V6.375C13.4583 6.76458 13.7771 7.08333 14.1667 7.08333C14.5562 7.08333 14.875 6.76458 14.875 6.375V2.83333C14.875 2.44375 14.5562 2.125 14.1667 2.125H10.625C10.2354 2.125 9.91667 2.44375 9.91667 2.83333Z"
                  fill="#1C274C"
                />
              </g>
              <defs>
                <clipPath id="clip0_275_1702">
                  <rect width="17" height="17" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        }
      </div>
      <hr className="my-5" />
      <div className="flex">
        <div className="flex-1 mr-[14px]">
          <p className="deploy-text-default mb-[8px]">Your custom API</p>
          <div className="flex api-deploy-item items-center">
            <div className="flex-1">
              https://dolphin-app-m4323.ondigitalocean.app/api/{id}
            </div>
            <div
              className="api-deploy-copyIcon cursor-pointer"
              onClick={() =>
                copyJsCode(
                  `${process.env.REACT_APP_NEW_BACKEND_URL+`/api/${id}`}`
                )
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <g clip-path="url(#clip0_275_1677)">
                  <path
                    d="M10.625 14.1667H3.54167V4.95835C3.54167 4.56877 3.22292 4.25002 2.83333 4.25002C2.44375 4.25002 2.125 4.56877 2.125 4.95835V14.1667C2.125 14.9459 2.7625 15.5834 3.54167 15.5834H10.625C11.0146 15.5834 11.3333 15.2646 11.3333 14.875C11.3333 14.4854 11.0146 14.1667 10.625 14.1667ZM14.1667 11.3334V2.83335C14.1667 2.05419 13.5292 1.41669 12.75 1.41669H6.375C5.59583 1.41669 4.95833 2.05419 4.95833 2.83335V11.3334C4.95833 12.1125 5.59583 12.75 6.375 12.75H12.75C13.5292 12.75 14.1667 12.1125 14.1667 11.3334ZM12.75 11.3334H6.375V2.83335H12.75V11.3334Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_275_1677">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <p className="deploy-text-default mb-[8px]">Your API key</p>
          <div className="flex api-deploy-item items-center">
            <div className="flex-1">
              {apikey == null ? "YourAPI-Key123" : apikey}
            </div>
            <div
              className="api-deploy-copyIcon cursor-pointer"
              onClick={() =>
                copyJsCode(apikey == null ? "YourAPI-Key123" : apikey)
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <g clip-path="url(#clip0_275_1677)">
                  <path
                    d="M10.625 14.1667H3.54167V4.95835C3.54167 4.56877 3.22292 4.25002 2.83333 4.25002C2.44375 4.25002 2.125 4.56877 2.125 4.95835V14.1667C2.125 14.9459 2.7625 15.5834 3.54167 15.5834H10.625C11.0146 15.5834 11.3333 15.2646 11.3333 14.875C11.3333 14.4854 11.0146 14.1667 10.625 14.1667ZM14.1667 11.3334V2.83335C14.1667 2.05419 13.5292 1.41669 12.75 1.41669H6.375C5.59583 1.41669 4.95833 2.05419 4.95833 2.83335V11.3334C4.95833 12.1125 5.59583 12.75 6.375 12.75H12.75C13.5292 12.75 14.1667 12.1125 14.1667 11.3334ZM12.75 11.3334H6.375V2.83335H12.75V11.3334Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_275_1677">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="flex mt-3">
          <button className="next-button">
            Deploy to Production
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              className="ml-2"
            >
              <g clip-path="url(#clip0_1267_3609)">
                <path
                  d="M12.0066 7.24623L7.99741 11.2554C7.72116 11.5316 7.27491 11.5316 6.99866 11.2554L4.99408 9.25082C4.71783 8.97457 4.71783 8.52832 4.99408 8.25206C5.27033 7.97581 5.71658 7.97581 5.99283 8.25206L7.49449 9.75373L11.0007 6.24748C11.277 5.97123 11.7232 5.97123 11.9995 6.24748C12.2828 6.52373 12.2828 6.96998 12.0066 7.24623ZM2.83366 8.49998C2.83366 6.84957 3.55616 5.36915 4.68949 4.33498L5.77324 5.41873C5.99283 5.63832 6.37533 5.48248 6.37533 5.16373V2.12498C6.37533 1.92665 6.21949 1.77082 6.02116 1.77082H2.98241C2.66366 1.77082 2.50783 2.15332 2.73449 2.3729L3.68366 3.32915C2.29533 4.61832 1.41699 6.4529 1.41699 8.49998C1.41699 11.8646 3.76866 14.6837 6.91366 15.4062C7.35991 15.5054 7.79199 15.1725 7.79199 14.7121C7.79199 14.3791 7.55824 14.0958 7.23241 14.0179C4.71783 13.4441 2.83366 11.1916 2.83366 8.49998ZM15.5837 8.49998C15.5837 5.1354 13.232 2.31623 10.087 1.59373C9.64074 1.49457 9.20866 1.82748 9.20866 2.2879C9.20866 2.62082 9.44241 2.90415 9.76824 2.98207C12.2828 3.55582 14.167 5.80832 14.167 8.49998C14.167 10.1504 13.4445 11.6308 12.3112 12.665L11.2274 11.5812C11.0078 11.3616 10.6253 11.5175 10.6253 11.8362V14.875C10.6253 15.0733 10.7812 15.2291 10.9795 15.2291H14.0182C14.337 15.2291 14.4928 14.8466 14.2662 14.6271L13.317 13.6708C14.7053 12.3816 15.5837 10.5471 15.5837 8.49998Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1267_3609">
                  <rect width="17" height="17" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
          <div className="flex-1"></div>
          <span className="flex items-center copy-code">
            <span
              className="deploy-text-default"
              style={{ textDecorationLine: "underline" }}
              onClick={() => generateNewApi()}
            >
              Generate a new API key
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default APIDeployEnvironment;
