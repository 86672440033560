import { useState } from "react";
import BotThinking from "./BotThinking";
import { setConsoleOutput } from "../../reducers/botReducer";
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import TypingText from "../steps/utils/TypingText";

const ChatBotDev = ({ id }) => {
  const [history, setHistory] = useState([]);
  const [msg, setMsg] = useState("");
  const [status, setStatus] = useState(0);
  const [typingEffectIndex, setTypingEffectIndex] = useState(null);
  const user = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();

  const botName = useSelector((state) => state.bot.currentBotDetail.name);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (msg != "") {
      if (status == 1) {
        NotificationManager.warning("", "Bot is thinking...", 2000);
      } else {
        try {
          const sessionKey = localStorage.getItem(`testSessionKey-${id}`);
          const userId = localStorage.getItem("userId")
          const formData = new FormData();
          formData.append("botId", id);
          formData.append("sessionKey", sessionKey);
          formData.append("inputText", msg);
          userId && formData.append("userId", userId);
          setStatus(1);
          let tmp = [...history];
          tmp.push({
            type: "HumanMessage",
            content: msg,
          });
          dispatch(
            setConsoleOutput({
              type: "add",
              content: {
                type: "HumanMessage",
                content: msg,
              },
            })
          );
          setMsg("");
          setHistory([...tmp]);

          const response = await axios.post(
            process.env.REACT_APP_NEW_BACKEND_URL+"/chat",
            formData
          );

          tmp.push({
            type: "AIMessage",
            content: response.data.answer,
          });

          dispatch(
            setConsoleOutput({
              type: "add",
              content: {
                type: "AIMessage",
                content: response.data.answer,
              },
            })
          );

          setHistory([...tmp]);
          setTypingEffectIndex(tmp.length - 1);

          setStatus(0);
        } catch (error) {
          console.log("Error sending message:", error);
        }
      }
    }
  };

  useEffect(() => {
    const generateSessionKey = () => {
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith("testSessionKey")) {
          localStorage.removeItem(key);
        }
      });

      const sessionKey = Math.random().toString(36).substring(2, 15);
      localStorage.setItem(`testSessionKey-${id}`, sessionKey);
    };

    generateSessionKey();
    dispatch(
      setConsoleOutput({
        type: "clear",
      })
    );
  }, []);

  return (
    <div className="w-[400px] chatbotdev flex flex-col sticky top-[50px]">
      <div className="chatbotdev-header flex">
        <h1 className="chatbotdev-title flex justify-center items-center">
          {botName == "" ? "New Bot" : botName}
        </h1>
        <span className="chatbotdev-badge flex justify-center items-center">
          Dev
        </span>
        <div className="flex-1"></div>
        {/* <span className="chatbotdev-more flex justify-center items-center">
          More
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            className="ml-1"
          >
            <g clip-path="url(#clip0_370_116)">
              <path
                d="M8.50016 5.66667C9.27933 5.66667 9.91683 5.02917 9.91683 4.25C9.91683 3.47084 9.27933 2.83334 8.50016 2.83334C7.721 2.83334 7.0835 3.47084 7.0835 4.25C7.0835 5.02917 7.721 5.66667 8.50016 5.66667ZM8.50016 7.08334C7.721 7.08334 7.0835 7.72084 7.0835 8.5C7.0835 9.27917 7.721 9.91667 8.50016 9.91667C9.27933 9.91667 9.91683 9.27917 9.91683 8.5C9.91683 7.72084 9.27933 7.08334 8.50016 7.08334ZM8.50016 11.3333C7.721 11.3333 7.0835 11.9708 7.0835 12.75C7.0835 13.5292 7.721 14.1667 8.50016 14.1667C9.27933 14.1667 9.91683 13.5292 9.91683 12.75C9.91683 11.9708 9.27933 11.3333 8.50016 11.3333Z"
                fill="#1C274C"
              />
            </g>
            <defs>
              <clipPath id="clip0_370_116">
                <rect width="17" height="17" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span> */}
      </div>
      {history.length == 0 ? (
        <div className="flex-1 flex justify-center items-center flex-col">
          <h3 className="chatbotdev-template">
            This is a Live testing area for your LLM application
          </h3>
          <h3 className="chatbotdev-template">
            All the changes you make to the setup, will be available to test in
            real-time here.
          </h3>
          <h3 className="chatbotdev-template">
            Look at Run-time Logs section below to view the LLM model outputs,
            planner steps, embeddings used, tools used and more.
          </h3>
        </div>
      ) : (
        <div className="flex-1 mx-5 my-2 flex flex-col chat-container">
          {history.map((item, index) =>
            item.type == "HumanMessage" ? (
              <div className="chat-msg flex items-center">
                <img src="/assets/images/user.png" className="w-10 mr-2" />
                <p>{item.content}</p>
              </div>
            ) : (
              <div className="chat-msg flex items-center">
                <img src="/assets/images/bot.png" className="w-10 mr-2" />
                <p>
                  <TypingText
                    text={item.content}
                    isTyping={index === typingEffectIndex}
                  />
                </p>
              </div>
            )
          )}
          {status ? <BotThinking /> : null}
        </div>
      )}

      <form className="border mx-3 flex mb-2" onSubmit={handleSubmit}>
        <input
          className="chat-input"
          type="text"
          placeholder="Enter your messages here"
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
        />
        <button className="chat-send" type="submit">
          Send
        </button>
      </form>
    </div>
  );
};

export default ChatBotDev;
