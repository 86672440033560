import { useParams } from "react-router";
import Navbar from "./Navbar.js";
import SideBar from "./Sidebar.js";
import { useState, useRef, useEffect, useTransition } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBot } from "../../../reducers/botReducer";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import TypingText from "../../steps/utils/TypingText.js";
import { Link } from "react-router-dom";
import EmailDialog from "./EmailDialog.js";
import PremiumDialog from "./PremiumDialog.js";
import { current } from "@reduxjs/toolkit";
import { useLocation } from "react-router-dom";
import axios from "axios";
import LoginForm from "../../users/LoginForm.js";
import CustomModal from "./CustomModal.js";
import RegisterForm from "../../users/RegisterForm.js";
import { loginUserWithId } from "../../../reducers/authReducer.js";

const WebBot = () => {
  let { id } = useParams();
  const [msg, setMsg] = useState("");
  const [status, setStatus] = useState(0);
  const [isSending, setIsSending] = useState(0);
  const [openDlg, setOpenDlg] = useState(0);
  const [openRegisterDlg, setOpenRegisterDlg] = useState(0);
  const [openPremiumDlg, setOpenPremiumDlg] = useState(0);
  const [isConnect, setIsConnect] = useState(0);
  const [customizeIndex, setCustomizeIndex] = useState(1);
  const currentBot = useSelector((state) => state.bot.bot);
  const user = useSelector((state) => state.auth.currentUser);
  const [typingEffectIndex, setTypingEffectIndex] = useState(null);
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");

  const [history, setHistory] = useState([]);

  const bottomEl = useRef(null);
  const scrollToBottom = () => {
    bottomEl?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!localStorage.getItem("userId") && history.length >= 6) {
      setOpenDlg(1);
    } else {
      if (msg != "") {
        if (status == 1) {
          NotificationManager.warning("", "Bot is thinking...", 2000);
        } else {
          try {
            const sessionKey = localStorage.getItem(`sessionKey-${id}`);
            const userId = localStorage.getItem(`userId`);
            const formData = new FormData();
            formData.append("botId", id);
            formData.append("sessionKey", sessionKey);
            formData.append("inputText", msg);
            userId && formData.append("userId", userId);
            setStatus(1);
            let tmp = [...history];
            tmp.push({
              type: "HumanMessage",
              content: msg,
            });
            setMsg("");
            setHistory([...tmp]);

            const response = await axios.post(
              process.env.REACT_APP_NEW_BACKEND_URL + "/chat",
              formData
            );

            tmp.push({
              type: "AIMessage",
              content: response.data.answer,
            });

            setHistory([...tmp]);
            setTypingEffectIndex(tmp.length - 1);

            setStatus(0);
          } catch (error) {
            console.log("Error sending message:", error);
          }
        }
      }
    }
  };

  const startChat = async () => {
    const sessionKey = localStorage.getItem(`sessionKey-${id}`);
    const userId = localStorage.getItem(`userId`);
    try {
      setStatus(1);
      let tmp = [...history];
      const res = await axios.get(
        `${process.env.REACT_APP_NEW_BACKEND_URL}/chatHistory?session=${sessionKey}`
      );

      const previousMessages = res?.data?.msg || [];

      if (previousMessages.length > 0) {
        tmp.push(...previousMessages);
        setHistory([...tmp]);
      }

      if (location.state !== null) {
        const txt = location.state.msg;
        if (txt !== "") {
          // setStatus(1);
          window.history.replaceState(null, "", window.location.href);
          tmp.push({
            type: "HumanMessage",
            content: txt,
          });
          setHistory([...tmp]);
          const formData = new FormData();
          formData.append("botId", id);
          formData.append("sessionKey", sessionKey);
          formData.append("inputText", txt);
          userId && formData.append("userId", userId);
          const response = await axios.post(
            process.env.REACT_APP_NEW_BACKEND_URL + "/chat",
            formData
          );
          tmp.push({
            type: "AIMessage",
            content: response.data.answer,
          });

          setHistory([...tmp]);
          setTypingEffectIndex(tmp.length - 1);
        }
      }
      setStatus(0);
    } catch (error) {
      console.log("Error sending message:", error);
    }
  };

  const getCurrentBot = async () => {
    await dispatch(
      getBot({
        botId: id,
      })
    );
  };

  const location = useLocation();

  useEffect(() => {
    dispatch(loginUserWithId({ id: userId }));
  }, [userId]);

  useEffect(() => {
    const generateSessionKey = () => {
      const sessionKey = Math.random().toString(36).substring(2, 15);
      localStorage.setItem(`sessionKey-${id}`, sessionKey);
    };

    if (!localStorage.getItem(`sessionKey-${id}`)) {
      generateSessionKey();
    }

    getCurrentBot();
    startChat();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [history]);

  return (
    <div>
      {openDlg == 1 ? (
        <CustomModal>
          <LoginForm
            setOpenDlg={setOpenDlg}
            setOpenRegisterDlg={setOpenRegisterDlg}
          />
        </CustomModal>
      ) : null}
      {openRegisterDlg == 1 ? (
        <CustomModal>
          <RegisterForm
            setOpenDlg={setOpenDlg}
            setOpenRegisterDlg={setOpenRegisterDlg}
          />
        </CustomModal>
      ) : null}
      {openPremiumDlg == 1 ? (
        <PremiumDialog setOpenPremiumDlg={setOpenPremiumDlg} />
      ) : null}
      <Navbar />
      <div className="flex" style={{ height: `calc(100vh - 100px)` }}>
        <SideBar />
        <div className="flex-1 flex flex-col mx-0 sm:mx-[100px]">
          <div
            className="flex-1 pt-4 flex flex-col justify-start"
            style={{ overflowY: "auto" }}
          >
            <div className="display-bot-details">
              <div className="flex">
                <img
                  src="/assets/images/webbot-icon.png"
                  className="mr-[16px]"
                />
                <div className="w-[90%]">
                  <h4 className="webbot-title">{currentBot.name}</h4>
                  <p>{currentBot.desc}</p>
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col justify-end">
              {history.map((item, index) =>
                item.type == "HumanMessage" ? (
                  <div className="user-chat-msg flex">
                    {item.content}
                    <div className="flex-1"></div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_517_106)">
                        <path
                          d="M9.91667 7.79167C9.91667 8.18125 9.59792 8.5 9.20833 8.5H2.83333C2.44375 8.5 2.125 8.18125 2.125 7.79167C2.125 7.40208 2.44375 7.08333 2.83333 7.08333H9.20833C9.59792 7.08333 9.91667 7.40208 9.91667 7.79167ZM2.125 4.95833C2.125 5.34792 2.44375 5.66667 2.83333 5.66667H9.20833C9.59792 5.66667 9.91667 5.34792 9.91667 4.95833C9.91667 4.56875 9.59792 4.25 9.20833 4.25H2.83333C2.44375 4.25 2.125 4.56875 2.125 4.95833ZM7.08333 10.625C7.08333 10.2354 6.76458 9.91667 6.375 9.91667H2.83333C2.44375 9.91667 2.125 10.2354 2.125 10.625C2.125 11.0146 2.44375 11.3333 2.83333 11.3333H6.375C6.76458 11.3333 7.08333 11.0146 7.08333 10.625ZM12.7571 9.11625L13.26 8.61333C13.5362 8.33708 13.9825 8.33708 14.2587 8.61333L14.7617 9.11625C15.0379 9.3925 15.0379 9.83875 14.7617 10.115L14.2587 10.6179L12.7571 9.11625ZM12.2542 9.61917L8.59917 13.2742C8.53542 13.3379 8.5 13.4229 8.5 13.5221V14.5208C8.5 14.7192 8.65583 14.875 8.85417 14.875H9.85292C9.945 14.875 10.0371 14.8396 10.1008 14.7688L13.7558 11.1137L12.2542 9.61917Z"
                          fill="#A9ADBB"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_517_106">
                          <rect width="17" height="17" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                ) : (
                  <div className="bot-chat-msg">
                    <div className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        className="mr-2"
                      >
                        <g clip-path="url(#clip0_516_500)">
                          <path
                            d="M19.46 8.00002L20.25 6.25002L22 5.46002C22.39 5.28002 22.39 4.73002 22 4.55002L20.25 3.76002L19.46 2.00002C19.28 1.61002 18.73 1.61002 18.55 2.00002L17.76 3.75002L16 4.54002C15.61 4.72002 15.61 5.27002 16 5.45002L17.75 6.24002L18.54 8.00002C18.72 8.39002 19.28 8.39002 19.46 8.00002ZM11.5 9.50002L9.91004 6.00002C9.56004 5.22002 8.44004 5.22002 8.09004 6.00002L6.50004 9.50002L3.00004 11.09C2.22004 11.45 2.22004 12.56 3.00004 12.91L6.50004 14.5L8.09004 18C8.45004 18.78 9.56004 18.78 9.91004 18L11.5 14.5L15 12.91C15.78 12.55 15.78 11.44 15 11.09L11.5 9.50002ZM18.54 16L17.75 17.75L16 18.54C15.61 18.72 15.61 19.27 16 19.45L17.75 20.24L18.54 22C18.72 22.39 19.27 22.39 19.45 22L20.24 20.25L22 19.46C22.39 19.28 22.39 18.73 22 18.55L20.25 17.76L19.46 16C19.28 15.61 18.72 15.61 18.54 16Z"
                            fill="url(#paint0_linear_516_500)"
                          />
                        </g>
                        <defs>
                          <linearGradient
                            id="paint0_linear_516_500"
                            x1="12.3538"
                            y1="1.70752"
                            x2="12.3538"
                            y2="22.2925"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#FF51D9" />
                            <stop offset="1" stop-color="#A981EE" />
                          </linearGradient>
                          <clipPath id="clip0_516_500">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <p style={{ width: "90%" }}>
                        {
                          <TypingText
                            text={item.content}
                            isTyping={index === typingEffectIndex}
                          />
                        }
                      </p>
                    </div>
                    <div className="flex ml-8 mt-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        className="mr-2"
                      >
                        <g clip-path="url(#clip0_517_93)">
                          <path
                            d="M10 13.3333H3.33333V4.66658C3.33333 4.29992 3.03333 3.99992 2.66667 3.99992C2.3 3.99992 2 4.29992 2 4.66658V13.3333C2 14.0666 2.6 14.6666 3.33333 14.6666H10C10.3667 14.6666 10.6667 14.3666 10.6667 13.9999C10.6667 13.6333 10.3667 13.3333 10 13.3333ZM13.3333 10.6666V2.66659C13.3333 1.93325 12.7333 1.33325 12 1.33325H6C5.26667 1.33325 4.66667 1.93325 4.66667 2.66659V10.6666C4.66667 11.3999 5.26667 11.9999 6 11.9999H12C12.7333 11.9999 13.3333 11.3999 13.3333 10.6666ZM12 10.6666H6V2.66659H12V10.6666Z"
                            fill="#A9ADBB"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_517_93">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        className="mr-2"
                      >
                        <g clip-path="url(#clip0_517_99)">
                          <path
                            d="M6.00008 14.0001H12.0001C12.5534 14.0001 13.0267 13.6667 13.2267 13.1867L15.2401 8.48675C15.3001 8.33341 15.3334 8.17341 15.3334 8.00008V6.66675C15.3334 5.93341 14.7334 5.33341 14.0001 5.33341H9.79342L10.4267 2.28675L10.4467 2.07341C10.4467 1.80008 10.3334 1.54675 10.1534 1.36675L9.44675 0.666748L5.05341 5.06008C4.81341 5.30008 4.66675 5.63341 4.66675 6.00008V12.6667C4.66675 13.4001 5.26675 14.0001 6.00008 14.0001ZM6.00008 6.00008L8.89342 3.10675L8.00008 6.66675H14.0001V8.00008L12.0001 12.6667H6.00008V6.00008ZM0.666748 6.00008H3.33341V14.0001H0.666748V6.00008Z"
                            fill="#A9ADBB"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_517_99">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_517_102)">
                          <path
                            d="M10.0001 2H4.00008C3.44675 2 2.97341 2.33333 2.77341 2.81333L0.760081 7.51333C0.700081 7.66667 0.666748 7.82667 0.666748 8V9.33333C0.666748 10.0667 1.26675 10.6667 2.00008 10.6667H6.20675L5.57341 13.7133L5.55341 13.9267C5.55341 14.2 5.66675 14.4533 5.84675 14.6333L6.55341 15.3333L10.9467 10.94C11.1867 10.7 11.3334 10.3667 11.3334 10V3.33333C11.3334 2.6 10.7334 2 10.0001 2ZM10.0001 10L7.10675 12.8933L8.00008 9.33333H2.00008V8L4.00008 3.33333H10.0001V10ZM12.6667 2H15.3334V10H12.6667V2Z"
                            fill="#A9ADBB"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_517_102">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                )
              )}
            </div>
            <div ref={bottomEl}></div>
          </div>
          <div
            className="flex justify-center items-center cursor-pointer mb-[18px]"
            onClick={() => setIsConnect(1 - isConnect)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              className="mr-[8px]"
            >
              <g clip-path="url(#clip0_2532_8754)">
                <path
                  d="M17 11H20C21.11 11 22 10.1 22 9V5C22 3.89 21.1 3 20 3H17C15.89 3 15 3.9 15 5V6H9.01V5C9.01 3.89 8.11 3 7.01 3H4C2.9 3 2 3.9 2 5V9C2 10.11 2.9 11 4 11H7C8.11 11 9 10.1 9 9V8H11V15.01C11 16.66 12.34 18 13.99 18H15V19C15 20.11 15.9 21 17 21H20C21.11 21 22 20.1 22 19V15C22 13.89 21.1 13 20 13H17C15.89 13 15 13.9 15 15V16H13.99C13.45 16 13 15.55 13 15.01V8H15V9C15 10.1 15.9 11 17 11Z"
                  fill="#3A4363"
                />
              </g>
              <defs>
                <clipPath id="clip0_2532_8754">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Connect your data to customize this bot
            {isConnect == 0 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M8.1251 15.0005L12.0051 11.1205L15.8851 15.0005C16.2751 15.3905 16.9051 15.3905 17.2951 15.0005C17.6851 14.6105 17.6851 13.9805 17.2951 13.5905L12.7051 9.00047C12.3151 8.61047 11.6851 8.61047 11.2951 9.00047L6.7051 13.5905C6.3151 13.9805 6.3151 14.6105 6.7051 15.0005C7.0951 15.3805 7.7351 15.3905 8.1251 15.0005Z"
                  fill="#1C274C"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M8.1251 8.99953L12.0051 12.8795L15.8851 8.99953C16.2751 8.60953 16.9051 8.60953 17.2951 8.99953C17.6851 9.38953 17.6851 10.0195 17.2951 10.4095L12.7051 14.9995C12.3151 15.3895 11.6851 15.3895 11.2951 14.9995L6.7051 10.4095C6.3151 10.0195 6.3151 9.38953 6.7051 8.99953C7.0951 8.61953 7.7351 8.60953 8.1251 8.99953Z"
                  fill="#1C274C"
                />
              </svg>
            )}
          </div>
          {isConnect == 1 && (
            <div className="customize-bot-section">
              <div className="flex flex-col sm:flex-row justify-center items-center mb-[24px]">
                <div
                  className={classNames(
                    "flex customize-bot-item w-[300px] sm:w-[150px] mb-[8px] sm:mb-0 mr-[16px]",
                    {
                      "customize-bot-item-selected": customizeIndex == 0,
                      "customize-bot-item-unselected": customizeIndex != 0,
                    }
                  )}
                  onClick={() => setCustomizeIndex(0)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="20"
                    viewBox="0 0 17 20"
                    fill="none"
                  >
                    <path
                      d="M15.91 5.41L11.08 0.58C10.71 0.21 10.2 0 9.67 0H2.5C1.4 0 0.51 0.9 0.51 2L0.5 18C0.5 19.1 1.39 20 2.49 20H14.5C15.6 20 16.5 19.1 16.5 18V6.83C16.5 6.3 16.29 5.79 15.91 5.41ZM11.3 13H9.5V16C9.5 16.55 9.05 17 8.5 17C7.95 17 7.5 16.55 7.5 16V13H5.71C5.26 13 5.04 12.46 5.36 12.15L8.16 9.36C8.36 9.17 8.67 9.17 8.87 9.36L11.66 12.15C11.96 12.46 11.74 13 11.3 13ZM10.5 7C9.95 7 9.5 6.55 9.5 6V1.5L15 7H10.5Z"
                      fill="black"
                    />
                  </svg>
                  Upload
                </div>
                <div
                  className={classNames(
                    "flex customize-bot-item w-[300px] sm:w-[150px] mb-[8px] sm:mb-0  mr-[16px]",
                    {
                      "customize-bot-item-selected": customizeIndex == 1,
                      "customize-bot-item-unselected": customizeIndex != 1,
                    }
                  )}
                  onClick={() => setCustomizeIndex(1)}
                >
                  <img src="/assets/images/zendesk.png" />
                  Zendesk
                </div>
                <div
                  className={classNames(
                    "flex customize-bot-item w-[300px] sm:w-[150px] mb-[8px] sm:mb-0  mr-[16px]",
                    {
                      "customize-bot-item-selected": customizeIndex == 2,
                      "customize-bot-item-unselected": customizeIndex != 2,
                    }
                  )}
                  onClick={() => setCustomizeIndex(2)}
                >
                  <img src="/assets/images/intercom.png" />
                  Intercom
                </div>
                <div
                  className={classNames(
                    "flex customize-bot-item w-[300px] sm:w-[150px] mb-[8px] sm:mb-0 mr-[16px]",
                    {
                      "customize-bot-item-selected": customizeIndex == 3,
                      "customize-bot-item-unselected": customizeIndex != 3,
                    }
                  )}
                  onClick={() => setCustomizeIndex(3)}
                >
                  <img src="/assets/images/drive.png" />
                  Drive
                </div>
              </div>
              <div className="flex justify-center items-center">
                <div
                  className="continue-button flex justify-center items-center mr-[34px]"
                  onClick={() => setOpenPremiumDlg(1)}
                >
                  Continue
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_2532_8986)">
                      <path
                        d="M8.92216 6.42996C8.54841 6.80371 8.54841 7.40746 8.92216 7.78121L12.6405 11.4995L8.92216 15.2179C8.54841 15.5916 8.54841 16.1954 8.92216 16.5691C9.29591 16.9429 9.89966 16.9429 10.2734 16.5691L14.6722 12.1704C15.0459 11.7966 15.0459 11.1929 14.6722 10.8191L10.2734 6.42038C9.90924 6.05621 9.29591 6.05621 8.92216 6.42996Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2532_8986">
                        <rect width="23" height="23" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="cursor-pointer" onClick={() => setIsConnect(0)}>
                  Cancel
                </div>
              </div>
            </div>
          )}
          <form
            className="m-0 sm:m-[12px] flex px-1 sm:px-3 mb-2"
            style={{ border: "1px solid #E4E5EA", borderRadius: "15px" }}
            onSubmit={handleSubmit}
          >
            <input
              type="text"
              className="flex-1 text-[10px] sm:text-[16px]"
              style={{
                border: "none",
                outline: "none",
                background: "transparent",
                color: "var(--main-1-c-274-c, #1C274C)",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
              }}
              placeholder="Enter your message here ..."
              value={msg}
              onChange={(e) => {
                e.target.value != "" ? setIsSending(1) : setIsSending(0);
                return setMsg(e.target.value);
              }}
            />
            <div
              className="my-2 mr-2 sm:mr-5"
              style={{
                height: "65%",
                width: "2px",
                backgroundColor: "#E4E5EA",
              }}
            ></div>
            <button
              className="flex items-center justify-center cursor-pointer"
              type="submit"
            >
              <p
                className={classNames("mr-2 hidden sm:block sm:text-[16px]", {
                  "chatbot-production-send-text-active": isSending,
                  "chatbot-production-send-text-inactive": !isSending,
                })}
              >
                Send
              </p>
              {isSending ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M7.56391 10.4362L9.70855 15.4403C10.0037 16.1291 10.9218 16.2019 11.2898 15.5491C12.0161 14.2611 13.0926 12.1839 14.1692 9.49254C16.0564 4.77446 17 1 17 1M7.56391 10.4362L2.55975 8.2915C1.871 7.99634 1.79823 7.0782 2.45096 6.71019C3.73897 5.98392 5.8162 4.90738 8.50752 3.83085C13.2256 1.94362 17 1 17 1M7.56391 10.4362L17 1"
                    stroke="#1C274C"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M7.56391 10.4362L9.70855 15.4403C10.0037 16.1291 10.9218 16.2019 11.2898 15.5491C12.0161 14.2611 13.0926 12.1839 14.1692 9.49254C16.0564 4.77446 17 1 17 1M7.56391 10.4362L2.55975 8.2915C1.871 7.99634 1.79823 7.0782 2.45096 6.71019C3.73897 5.98392 5.8162 4.90738 8.50752 3.83085C13.2256 1.94362 17 1 17 1M7.56391 10.4362L17 1"
                    stroke="#848A9E"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </button>
          </form>
          <div className="flex px-2">
            <Link
              className="flex justify-center items-center description"
              to={
                "https://www.getbind.co/?utm_source=chat-app-bottom&utm_medium=chat-bot&utm_campaign=chat-bot"
              }
              target="_blank"
            >
              Created with Bind
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                className="ml-1"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.17708 0L9.82292 0C11.6231 0 12.2759 0.187436 12.934 0.539403C13.5921 0.891369 14.1086 1.40787 14.4606 2.06598C14.8126 2.7241 15 3.37689 15 5.17708V9.82292C15 11.6231 14.8126 12.2759 14.4606 12.934C14.1086 13.5921 13.5921 14.1086 12.934 14.4606C12.2759 14.8126 11.6231 15 9.82292 15H5.17708C3.37689 15 2.7241 14.8126 2.06598 14.4606C1.40787 14.1086 0.891369 13.5921 0.539403 12.934C0.187436 12.2759 0 11.6231 0 9.82292L0 5.17708C0 3.37689 0.187436 2.7241 0.539403 2.06598C0.891369 1.40787 1.40787 0.891369 2.06598 0.539403C2.7241 0.187436 3.37689 0 5.17708 0Z"
                  fill="#1C274C"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.96223 3.80092C8.02232 3.56051 8.26593 3.41434 8.50635 3.47444C8.74677 3.53455 8.89297 3.77816 8.83284 4.01858L7.03796 11.1979C6.97788 11.4383 6.73427 11.5845 6.49383 11.5243C6.25341 11.4643 6.10724 11.2207 6.16734 10.9802L7.96223 3.80092ZM5.12508 5.57315L3.19878 7.49941L5.12508 9.42566C5.30032 9.60088 5.30032 9.885 5.12508 10.0602C4.94984 10.2354 4.66573 10.2354 4.4905 10.0602L2.24691 7.81669C2.07167 7.64147 2.07167 7.35735 2.24691 7.18213L4.4905 4.93859C4.66573 4.76336 4.94984 4.76336 5.12508 4.93859C5.30032 5.11382 5.30032 5.39793 5.12508 5.57315ZM9.87512 9.42566L11.8014 7.49941L9.87512 5.57315C9.6999 5.39793 9.6999 5.11382 9.87512 4.93859C10.0503 4.76336 10.3345 4.76336 10.5097 4.93859L12.7533 7.18213C12.9285 7.35735 12.9285 7.64147 12.7533 7.81669L10.5097 10.0602C10.3345 10.2354 10.0503 10.2354 9.87512 10.0602C9.6999 9.885 9.6999 9.60088 9.87512 9.42566Z"
                  fill="white"
                />
              </svg>
            </Link>
            <div className="flex-1"></div>
            <div className="hidden sm:block disable-text">
              Bind can provide inaccurate information, including about people.
              Always double-check its answers. Your{" "}
              <span className="privacy">privacy</span> in Bind
            </div>
            <div className="block sm:hidden disable-text">
              Always check its answers. <span className="privacy">privacy</span>{" "}
              in Bind
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebBot;
