import "./App.css";
import LogIn from "./components/users/LogIn";
import Register from "./components/users/Register";
import Main from "./components/Main";
import Home from "./components/Home";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import { history } from "./history";
import BotPage from "./components/bots/BotPage";
import WebBot from "./components/bots/WebBot/WebBot.js";
import WebBotHome from "./components/bots/WebBot/Home.js";
import ProtectedRoute from "./utils/ProtectedRoute.js";
import ForgotPassword from "./components/users/ForgotPassword.js";
import ResetPassword from "./components/users/ResetPassword.js";
import HandleAuthLink from "./components/users/HandleAuthLink.js";
import VerifyEmail from "./components/users/VerifyEmail.js";


<style>
  @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
  @import
  url('https://fonts.googleapis.com/css2?family=Inter:wght@300&family=JetBrains+Mono:wght@100&display=swap');
</style>;

function App() {
  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <div className="App">
      <NotificationContainer />
      <Routes>
          <Route element={<ProtectedRoute />}>
          <Route path="/login" element={<LogIn />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<Home step={1} />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/createBot/select" element={<Main step={0} />} />
          <Route path="/createBot/customize/:id" element={<Main step={1} />} />
          <Route path="/createBot/knowledge/:id" element={<Main step={2} />} />
          <Route path="/createBot/plugin/:id" element={<Main step={3} />} />
          <Route path="/createBot/publish/:id" element={<Main step={4} />} />
          <Route path="/bot/:id" element={<BotPage />} />
          {/* <Route path="/pricing" element={<Pricing />} /> */}
        </Route> 
        {/* <Route path="/api/v1/:id" element={<ApiResponse />} /> */}
        <Route path="/verifyEmail" element={<VerifyEmail />} />
        <Route path="/auth-link" element={<HandleAuthLink />} />
        <Route path="/webbot/:id" element={<WebBot />} />
        <Route path="/webbot" element={<WebBotHome />} />
      </Routes>
    </div>
  );
}

export default App;
