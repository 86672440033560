import classNames from "classnames";

const BotKnowledgeBaseintegration = ({
  title,
  desc,
  type,
  currentType,
  setType,
}) => {
  return (
    <div
      className={classNames("p-[20px] cursor-pointer w-[350px] my-5", {
        "bot-knowledge-base-integration-selected": type == currentType,
        "bot-knowledge-base-integration-unselected": type != currentType,
      })}
      onClick={() => setType(type)}
    >
      {type == 0 && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.55975 25.4657C6.43661 25.4633 7.27685 25.1138 7.89668 24.4935C8.5165 23.8733 8.86547 23.0328 8.86725 22.1559V18.8484H5.55975C4.68328 18.8502 3.84314 19.1989 3.22296 19.8182C2.60277 20.4376 2.25297 21.2772 2.25 22.1537C2.25225 23.9784 3.73275 25.4589 5.5575 25.4612L5.55975 25.4657ZM13.8443 18.8462C12.9674 18.8486 12.1272 19.1981 11.5073 19.8184C10.8875 20.4386 10.5385 21.2791 10.5368 22.1559V30.4427C10.5413 32.2674 12.0218 33.7457 13.8443 33.7502C14.7207 33.7478 15.5606 33.3986 16.1804 32.7788C16.8001 32.1591 17.1494 31.3192 17.1518 30.4427V22.1537C17.1494 21.2772 16.8001 20.4373 16.1804 19.8176C15.5606 19.1978 14.7207 18.8486 13.8443 18.8462Z"
            fill="#E01E5A"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.5368 5.55975C10.539 7.3845 12.0195 8.865 13.8443 8.86725H17.1518V5.55975C17.15 4.68328 16.8013 3.84314 16.182 3.22296C15.5626 2.60277 14.723 2.25297 13.8465 2.25C12.97 2.25238 12.1301 2.60161 11.5104 3.22137C10.8906 3.84113 10.5414 4.68103 10.539 5.5575L10.5368 5.55975ZM17.1518 13.8443C17.1494 12.9678 16.8001 12.1279 16.1804 11.5081C15.5606 10.8884 14.7207 10.5391 13.8443 10.5368H5.5575C4.68103 10.5391 3.84113 10.8884 3.22137 11.5081C2.60161 12.1279 2.25238 12.9678 2.25 13.8443C2.2545 15.6713 3.735 17.1495 5.5575 17.1518H13.8465C14.723 17.1494 15.5629 16.8001 16.1826 16.1804C16.8024 15.5606 17.1494 14.7207 17.1518 13.8443Z"
            fill="#36C5F0"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.1558 17.154C23.0323 17.1516 23.8722 16.8024 24.492 16.1826C25.1117 15.5629 25.461 14.723 25.4633 13.8465V5.5575C25.461 4.68142 25.112 3.84186 24.4928 3.22216C23.8735 2.60246 23.0342 2.25297 22.1581 2.25C21.2816 2.25238 20.4417 2.60161 19.822 3.22137C19.2022 3.84113 18.853 4.68103 18.8506 5.5575V13.8465C18.8551 15.669 20.3311 17.1495 22.1558 17.154ZM30.4403 10.5368C29.5639 10.5391 28.724 10.8884 28.1042 11.5081C27.4844 12.1279 27.1352 12.9678 27.1328 13.8443V17.1518H30.4403C31.3168 17.15 32.1569 16.8013 32.7771 16.182C33.3973 15.5626 33.7471 14.723 33.7501 13.8465C33.7477 12.97 33.3985 12.1301 32.7787 11.5104C32.159 10.8906 31.3191 10.5414 30.4426 10.539L30.4403 10.5368Z"
            fill="#2EB67D"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.8481 22.1558C18.8526 23.9806 20.3309 25.4611 22.1556 25.4633H30.4424C31.3185 25.461 32.158 25.112 32.7777 24.4928C33.3974 23.8735 33.7469 23.0342 33.7499 22.1581C33.7475 21.2816 33.3983 20.4417 32.7785 19.822C32.1588 19.2022 31.3189 18.853 30.4424 18.8506H22.1556C21.2792 18.853 20.4393 19.2022 19.8195 19.822C19.1998 20.4417 18.8505 21.2794 18.8481 22.1558ZM25.4631 30.4403C25.4608 29.5639 25.1115 28.724 24.4918 28.1042C23.872 27.4844 23.0321 27.1352 22.1556 27.1328H18.8481V30.4403C18.8526 32.2673 20.3309 33.7456 22.1556 33.7478C23.0321 33.7455 23.872 33.3962 24.4918 32.7765C25.1115 32.1567 25.4608 31.3168 25.4631 30.4403Z"
            fill="#ECB22E"
          />
        </svg>
      )}
      {type == 1 && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
        >
          <path
            d="M14.9841 8.10689C16.144 6.89835 17.7588 6.14885 19.5447 6.14885C21.9187 6.14885 23.99 7.47265 25.093 9.43786C26.0515 9.00957 27.1125 8.77135 28.2288 8.77135C32.5107 8.77135 35.9821 12.273 35.9821 16.5922C35.9821 20.912 32.5107 24.4136 28.2288 24.4136C27.7062 24.4136 27.1955 24.3614 26.7016 24.2615C25.7303 25.9941 23.8793 27.1647 21.7548 27.1647C20.8654 27.1647 20.0242 26.9593 19.2752 26.594C18.2905 28.9102 15.9964 30.5342 13.3227 30.5342C10.5383 30.5342 8.1653 28.7724 7.25441 26.3015C6.85635 26.386 6.44394 26.4301 6.02078 26.4301C2.70563 26.4301 0.0180664 23.7149 0.0180664 20.3649C0.0180664 18.1199 1.22558 16.1599 3.01968 15.1112C2.6503 14.2612 2.44487 13.3232 2.44487 12.337C2.44487 8.48446 5.57251 5.36143 9.43018 5.36143C11.6951 5.36143 13.708 6.4383 14.9841 8.10689Z"
            fill="#00A1E0"
          />
          <path
            d="M5.22712 18.4156C5.20458 18.4745 5.23532 18.4868 5.24249 18.497C5.31012 18.5462 5.37877 18.5816 5.44793 18.621C5.81474 18.8157 6.16106 18.8726 6.52326 18.8726C7.26098 18.8726 7.71899 18.4801 7.71899 17.8485V17.8362C7.71899 17.2521 7.20207 17.04 6.71691 16.8869L6.6539 16.8664C6.28811 16.7475 5.97253 16.645 5.97253 16.4043V16.3915C5.97253 16.1855 6.15696 16.0339 6.44283 16.0339C6.76046 16.0339 7.13752 16.1394 7.38035 16.2736C7.38035 16.2736 7.45156 16.3197 7.47769 16.2506C7.49203 16.2137 7.61499 15.8827 7.6278 15.8469C7.64163 15.8079 7.61704 15.7792 7.59193 15.7639C7.31478 15.5953 6.93157 15.4801 6.53504 15.4801L6.46127 15.4806C5.78605 15.4806 5.31473 15.8884 5.31473 16.4729V16.4852C5.31473 17.1015 5.83472 17.3013 6.32192 17.4407L6.40031 17.4647C6.75534 17.5739 7.06118 17.6676 7.06118 17.9176V17.9299C7.06118 18.1584 6.86241 18.3285 6.5417 18.3285C6.41721 18.3285 6.02017 18.3259 5.59137 18.0549C5.53963 18.0247 5.5094 18.0027 5.46944 17.9786C5.44844 17.9653 5.39567 17.9422 5.37262 18.0119L5.22712 18.4156Z"
            fill="white"
          />
          <path
            d="M16.0272 18.4156C16.0046 18.4745 16.0354 18.4868 16.0425 18.497C16.1102 18.5462 16.1788 18.5816 16.248 18.621C16.6148 18.8157 16.9611 18.8726 17.3233 18.8726C18.061 18.8726 18.519 18.4801 18.519 17.8485V17.8362C18.519 17.2521 18.0021 17.04 17.517 16.8869L17.4539 16.8664C17.0882 16.7475 16.7726 16.645 16.7726 16.4043V16.3915C16.7726 16.1855 16.957 16.0339 17.2429 16.0339C17.5605 16.0339 17.9376 16.1394 18.1804 16.2736C18.1804 16.2736 18.2516 16.3197 18.2777 16.2506C18.2921 16.2137 18.415 15.8827 18.4278 15.8469C18.4417 15.8079 18.4171 15.7792 18.392 15.7639C18.1148 15.5953 17.7316 15.4801 17.3351 15.4801L17.2613 15.4806C16.5861 15.4806 16.1148 15.8884 16.1148 16.4729V16.4852C16.1148 17.1015 16.6348 17.3013 17.122 17.4407L17.2004 17.4647C17.5554 17.5739 17.8617 17.6676 17.8617 17.9176V17.9299C17.8617 18.1584 17.6625 18.3285 17.3418 18.3285C17.2173 18.3285 16.8202 18.3259 16.3914 18.0549C16.3397 18.0247 16.3089 18.0037 16.27 17.9786C16.2567 17.9699 16.1942 17.9458 16.1727 18.0119L16.0272 18.4156Z"
            fill="white"
          />
          <path
            d="M23.3999 17.1779C23.3999 17.535 23.3333 17.8163 23.2022 18.015C23.0726 18.2118 22.8763 18.3076 22.6028 18.3076C22.3287 18.3076 22.1335 18.2123 22.0059 18.015C21.8768 17.8168 21.8113 17.535 21.8113 17.1779C21.8113 16.8214 21.8768 16.5406 22.0059 16.3439C22.1335 16.1492 22.3287 16.0544 22.6028 16.0544C22.8763 16.0544 23.0726 16.1492 23.2027 16.3439C23.3333 16.5406 23.3999 16.8214 23.3999 17.1779ZM24.0157 16.516C23.9553 16.3116 23.861 16.1313 23.7355 15.9812C23.61 15.8306 23.4511 15.7097 23.2626 15.6215C23.0746 15.5339 22.8523 15.4894 22.6028 15.4894C22.3528 15.4894 22.1304 15.5339 21.9424 15.6215C21.7539 15.7097 21.5951 15.8306 21.469 15.9812C21.344 16.1318 21.2498 16.3121 21.1888 16.516C21.1289 16.7194 21.0986 16.9418 21.0986 17.1779C21.0986 17.4141 21.1289 17.637 21.1888 17.8398C21.2498 18.0437 21.3435 18.2241 21.4695 18.3747C21.5951 18.5253 21.7544 18.6457 21.9424 18.7313C22.1309 18.8168 22.3528 18.8604 22.6028 18.8604C22.8523 18.8604 23.0741 18.8168 23.2626 18.7313C23.4506 18.6457 23.61 18.5253 23.7355 18.3747C23.861 18.2246 23.9553 18.0442 24.0157 17.8398C24.0762 17.6364 24.1064 17.4136 24.1064 17.1779C24.1064 16.9423 24.0762 16.7194 24.0157 16.516Z"
            fill="white"
          />
          <path
            d="M29.0726 18.2124C29.0522 18.1524 28.9943 18.175 28.9943 18.175C28.9046 18.2093 28.8093 18.241 28.7079 18.2569C28.6049 18.2728 28.4917 18.281 28.3703 18.281C28.0721 18.281 27.8354 18.1924 27.6658 18.0172C27.4958 17.842 27.4005 17.5586 27.4015 17.1754C27.4025 16.8266 27.4865 16.5643 27.6377 16.3645C27.7878 16.1657 28.0163 16.0637 28.3211 16.0637C28.5752 16.0637 28.7688 16.0929 28.9717 16.157C28.9717 16.157 29.0204 16.178 29.0434 16.1145C29.0972 15.9649 29.1372 15.8578 29.1946 15.6933C29.211 15.6467 29.171 15.6267 29.1567 15.6211C29.0767 15.5899 28.8882 15.5391 28.7458 15.5176C28.6126 15.4971 28.4569 15.4864 28.2837 15.4864C28.025 15.4864 27.7944 15.5304 27.5972 15.6185C27.4005 15.7061 27.2335 15.827 27.1013 15.9777C26.9691 16.1283 26.8687 16.3086 26.8016 16.5125C26.735 16.7159 26.7012 16.9393 26.7012 17.1754C26.7012 17.6862 26.839 18.0991 27.111 18.4014C27.3836 18.7047 27.7929 18.8589 28.3267 18.8589C28.6423 18.8589 28.9661 18.7948 29.1987 18.7031C29.1987 18.7031 29.2432 18.6816 29.2238 18.6299L29.0726 18.2124Z"
            fill="white"
          />
          <path
            d="M30.1499 16.8362C30.1791 16.6379 30.2339 16.4729 30.3184 16.3444C30.446 16.1492 30.6407 16.0421 30.9143 16.0421C31.1878 16.0421 31.3687 16.1497 31.4983 16.3444C31.5843 16.4729 31.6217 16.6451 31.6366 16.8362H30.1499ZM32.2232 16.4002C32.1709 16.203 32.0413 16.0037 31.9563 15.9125C31.8221 15.768 31.6909 15.6671 31.5608 15.6107C31.3907 15.538 31.1868 15.4898 30.9634 15.4898C30.7032 15.4898 30.467 15.5334 30.2754 15.6235C30.0833 15.7137 29.9219 15.8367 29.7954 15.9898C29.6688 16.1425 29.5735 16.3244 29.5131 16.5308C29.4521 16.7363 29.4214 16.9602 29.4214 17.1963C29.4214 17.4366 29.4531 17.6605 29.5162 17.8618C29.5797 18.0647 29.6811 18.2435 29.8184 18.3915C29.9552 18.5406 30.1314 18.6574 30.3425 18.7389C30.552 18.8198 30.8067 18.8618 31.0992 18.8613C31.7012 18.8593 32.0183 18.7251 32.1489 18.6528C32.172 18.64 32.194 18.6175 32.1663 18.5529L32.0301 18.1712C32.0096 18.1144 31.9517 18.1354 31.9517 18.1354C31.8026 18.1907 31.5905 18.2901 31.0961 18.2891C30.7729 18.2886 30.5331 18.1933 30.383 18.0442C30.2288 17.8915 30.1535 17.6671 30.1402 17.3505L32.2247 17.3526C32.2247 17.3526 32.2795 17.3516 32.2852 17.2983C32.2872 17.2757 32.3569 16.87 32.2232 16.4002Z"
            fill="white"
          />
          <path
            d="M13.4556 16.8362C13.4853 16.6379 13.5396 16.4729 13.6241 16.3444C13.7517 16.1492 13.9463 16.0421 14.2199 16.0421C14.4935 16.0421 14.6743 16.1497 14.8045 16.3444C14.89 16.4729 14.9274 16.6451 14.9423 16.8362H13.4556ZM15.5283 16.4002C15.4761 16.203 15.347 16.0037 15.2619 15.9125C15.1277 15.768 14.9966 15.6671 14.8664 15.6107C14.6964 15.538 14.4925 15.4898 14.2691 15.4898C14.0094 15.4898 13.7727 15.5334 13.5811 15.6235C13.389 15.7137 13.2276 15.8367 13.101 15.9898C12.9745 16.1425 12.8792 16.3244 12.8188 16.5308C12.7583 16.7363 12.7271 16.9602 12.7271 17.1963C12.7271 17.4366 12.7588 17.6605 12.8218 17.8618C12.8854 18.0647 12.9868 18.2435 13.1241 18.3915C13.2609 18.5406 13.4371 18.6574 13.6482 18.7389C13.8577 18.8198 14.1123 18.8618 14.4049 18.8613C15.0068 18.8593 15.3239 18.7251 15.4546 18.6528C15.4776 18.64 15.4997 18.6175 15.472 18.5529L15.3362 18.1712C15.3152 18.1144 15.2573 18.1354 15.2573 18.1354C15.1083 18.1907 14.8967 18.2901 14.4013 18.2891C14.0785 18.2886 13.8388 18.1933 13.6887 18.0442C13.5344 17.8915 13.4591 17.6671 13.4458 17.3505L15.5304 17.3526C15.5304 17.3526 15.5852 17.3516 15.5909 17.2983C15.5929 17.2757 15.6626 16.87 15.5283 16.4002Z"
            fill="white"
          />
          <path
            d="M8.94967 18.201C8.86821 18.1359 8.85694 18.1195 8.82928 18.0775C8.78829 18.0135 8.76729 17.9223 8.76729 17.8065C8.76729 17.6231 8.82774 17.4914 8.95325 17.4028C8.95172 17.4033 9.13256 17.2466 9.55778 17.2522C9.85645 17.2563 10.1234 17.3003 10.1234 17.3003V18.2481H10.1239C10.1239 18.2481 9.85901 18.305 9.56085 18.3229C9.13666 18.3485 8.94813 18.2005 8.94967 18.201ZM9.77909 16.7363C9.69456 16.7302 9.58493 16.7266 9.45378 16.7266C9.27498 16.7266 9.10234 16.7491 8.94045 16.7927C8.77753 16.8362 8.63101 16.9043 8.50498 16.9945C8.37844 17.0852 8.2765 17.201 8.20272 17.3383C8.12895 17.4756 8.09155 17.6374 8.09155 17.8188C8.09155 18.0032 8.12332 18.1636 8.18684 18.2947C8.25037 18.4264 8.34207 18.536 8.45888 18.6206C8.57466 18.7051 8.71759 18.7671 8.88358 18.8045C9.04701 18.8419 9.23246 18.8608 9.43534 18.8608C9.64897 18.8608 9.86209 18.8434 10.0685 18.8081C10.273 18.7732 10.524 18.7225 10.5937 18.7066C10.6628 18.6902 10.7397 18.6692 10.7397 18.6692C10.7914 18.6564 10.7873 18.6011 10.7873 18.6011L10.7863 16.6948C10.7863 16.2768 10.6746 15.9668 10.4548 15.7747C10.2361 15.5831 9.91383 15.4863 9.49733 15.4863C9.34107 15.4863 9.08953 15.5078 8.93891 15.538C8.93891 15.538 8.48347 15.6261 8.29596 15.7726C8.29596 15.7726 8.25498 15.7983 8.27752 15.8556L8.42507 16.2522C8.44351 16.3034 8.4932 16.286 8.4932 16.286C8.4932 16.286 8.50908 16.2798 8.52753 16.2691C8.92866 16.0508 9.43585 16.0575 9.43585 16.0575C9.66126 16.0575 9.83442 16.1026 9.95123 16.1922C10.065 16.2793 10.1229 16.411 10.1229 16.6887V16.7768C9.94355 16.7512 9.77909 16.7363 9.77909 16.7363Z"
            fill="white"
          />
          <path
            d="M26.5922 15.6623C26.6081 15.6151 26.5748 15.5926 26.561 15.5875C26.5256 15.5736 26.3484 15.5362 26.2116 15.5275C25.9498 15.5116 25.8043 15.5557 25.6742 15.6141C25.5451 15.6725 25.4016 15.7668 25.3217 15.8738V15.6202C25.3217 15.5849 25.2966 15.5567 25.2618 15.5567H24.7274C24.6926 15.5567 24.6675 15.5849 24.6675 15.6202V18.7294C24.6675 18.7643 24.6962 18.793 24.731 18.793H25.2787C25.3135 18.793 25.3417 18.7643 25.3417 18.7294V17.1761C25.3417 16.9676 25.3647 16.7596 25.4108 16.629C25.4559 16.4999 25.5174 16.3964 25.5932 16.3221C25.6696 16.2483 25.7561 16.1966 25.8509 16.1674C25.9477 16.1377 26.0548 16.1279 26.1306 16.1279C26.2398 16.1279 26.3596 16.1561 26.3596 16.1561C26.3996 16.1607 26.4221 16.1361 26.4355 16.0998C26.4713 16.0045 26.5728 15.7191 26.5922 15.6623Z"
            fill="white"
          />
          <path
            d="M21.4515 14.2209C21.3849 14.2004 21.3245 14.1866 21.2456 14.1717C21.1657 14.1574 21.0704 14.1502 20.9623 14.1502C20.5852 14.1502 20.2881 14.2568 20.0796 14.4668C19.8721 14.6758 19.7312 14.994 19.6605 15.4125L19.6349 15.5534H19.1615C19.1615 15.5534 19.1041 15.5514 19.0918 15.6139L19.0145 16.0478C19.0088 16.0888 19.0268 16.1149 19.0821 16.1149H19.5427L19.0754 18.7236C19.0391 18.9336 18.9971 19.1063 18.9504 19.2374C18.9048 19.3665 18.8603 19.4633 18.8049 19.534C18.7517 19.6017 18.7015 19.6519 18.6144 19.6811C18.5426 19.7052 18.4596 19.7164 18.369 19.7164C18.3188 19.7164 18.2516 19.7082 18.202 19.698C18.1528 19.6882 18.1266 19.6775 18.0892 19.6616C18.0892 19.6616 18.0355 19.6411 18.0139 19.6949C17.997 19.7395 17.8741 20.0771 17.8592 20.1186C17.8449 20.1601 17.8654 20.1924 17.8915 20.2021C17.953 20.2236 17.9986 20.238 18.0821 20.2579C18.1979 20.2851 18.2957 20.2866 18.3874 20.2866C18.579 20.2866 18.7542 20.2595 18.8992 20.2072C19.0447 20.1544 19.1717 20.0627 19.2845 19.9388C19.4059 19.8045 19.4822 19.6642 19.555 19.4721C19.6272 19.2825 19.6892 19.0468 19.7384 18.7722L20.2081 16.1149H20.8946C20.8946 16.1149 20.9525 16.1169 20.9643 16.0539L21.0422 15.6205C21.0473 15.579 21.0299 15.5534 20.974 15.5534H20.3075C20.3111 15.5386 20.3413 15.3039 20.4177 15.0831C20.4505 14.9894 20.5119 14.913 20.5637 14.8608C20.6149 14.8095 20.6738 14.7732 20.7384 14.7522C20.8045 14.7306 20.8798 14.7204 20.9623 14.7204C21.0248 14.7204 21.0868 14.7276 21.1334 14.7373C21.1979 14.7511 21.223 14.7583 21.2399 14.7634C21.3081 14.7839 21.3173 14.7639 21.3306 14.7312L21.4899 14.2936C21.5063 14.2465 21.4659 14.2265 21.4515 14.2209Z"
            fill="white"
          />
          <path
            d="M12.1392 18.7295C12.1392 18.7643 12.1141 18.7925 12.0792 18.7925H11.5265C11.4916 18.7925 11.467 18.7643 11.467 18.7295V14.2806C11.467 14.2457 11.4916 14.2176 11.5265 14.2176H12.0792C12.1141 14.2176 12.1392 14.2457 12.1392 14.2806V18.7295Z"
            fill="white"
          />
        </svg>
      )}
      {type == 2 && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
        >
          <path
            d="M28.3629 23.401V31.2471C28.3629 33.8744 22.0155 36.0001 14.1814 36.0001C9.21938 36.0001 4.84854 35.1462 2.31673 33.8504C1.01503 33.1877 0.202922 32.4114 0.0357891 31.5695C0.0118828 31.462 0 31.3546 0 31.2471V23.401C0 23.377 0 23.3532 0.00590625 23.3294C0 23.3174 0 23.3114 0.00590625 23.3055C0.00590625 23.2636 0.0118828 23.2219 0.0178594 23.1801C0.0238359 23.1622 0.0238359 23.1442 0.0298125 23.1263C0.0357891 23.0785 0.0476719 23.0308 0.0656719 22.983V22.977C0.0895781 22.8816 0.125367 22.7919 0.167203 22.6964C0.955406 20.9827 4.47244 19.5676 9.18956 18.9764C9.80459 18.8987 10.4435 18.8331 11.0943 18.7853H11.1302C12.1154 18.7077 13.1366 18.6719 14.1815 18.6719C15.2264 18.6719 16.2475 18.7077 17.2327 18.7853H17.2685C17.9193 18.8331 18.5582 18.8987 19.1732 18.9764C23.8904 19.5675 27.4074 20.9827 28.1956 22.6964C28.2374 22.7919 28.2732 22.8816 28.2971 22.977V22.983C28.3151 23.0308 28.3269 23.0785 28.3329 23.1263C28.3449 23.186 28.3508 23.2397 28.3568 23.3055V23.3294C28.3629 23.3532 28.3629 23.377 28.3629 23.401Z"
            fill="#39526C"
          />
          <path
            opacity="0.1"
            d="M28.3629 23.4248V23.801C28.3629 26.4283 22.0155 28.5599 14.1814 28.5599C6.34725 28.5599 0 26.4283 0 23.801V23.4248C0 23.4845 0.00590625 23.5382 0.0118828 23.592C0.197016 25.4431 3.54677 27.0314 8.30581 27.7539C8.47301 27.7778 8.63424 27.8017 8.80144 27.8255C9.0701 27.8673 9.33884 27.9031 9.61348 27.933C9.72696 27.939 9.84635 27.951 9.96574 27.9688C10.6286 28.0405 11.3153 28.0943 12.0198 28.13C12.4915 28.1539 12.9692 28.1718 13.4529 28.1778C13.6917 28.1838 13.9366 28.1838 14.1814 28.1838C14.4262 28.1838 14.671 28.1838 14.9098 28.1778C15.3935 28.1718 15.8712 28.1539 16.3429 28.13C17.0475 28.0943 17.7342 28.0405 18.397 27.9688C18.5164 27.951 18.6358 27.939 18.7493 27.933C19.024 27.9031 19.2926 27.8673 19.5613 27.8255C19.7285 27.8017 19.8897 27.7778 20.0569 27.7539C24.816 27.0314 28.1657 25.4431 28.3508 23.592C28.3569 23.5382 28.3629 23.4845 28.3629 23.4248Z"
            fill="#E8E8E9"
          />
          <path
            d="M28.3629 23.377V23.4248C28.3629 23.4845 28.3569 23.5382 28.3509 23.592C28.1001 25.4491 24.7682 27.0195 20.057 27.7539C19.8898 27.7778 19.7286 27.8017 19.5614 27.8255C19.3942 27.8495 19.227 27.8673 19.0598 27.8912C18.9583 27.9032 18.8508 27.9151 18.7493 27.9331C18.6359 27.939 18.5164 27.951 18.3971 27.9688C17.9551 28.0106 17.5014 28.0524 17.0416 28.0823C16.8087 28.1003 16.5758 28.1181 16.3429 28.1301C15.8712 28.148 15.3935 28.1659 14.9098 28.1778C14.671 28.1838 14.4262 28.1838 14.1814 28.1838C13.9366 28.1838 13.6917 28.1838 13.4529 28.1778C12.9692 28.1659 12.4916 28.148 12.0198 28.1301C11.7869 28.1181 11.554 28.1003 11.3212 28.0823C10.8614 28.0524 10.4076 28.0106 9.96567 27.9688C9.84628 27.951 9.72682 27.939 9.61341 27.9331C9.51195 27.9151 9.40444 27.9032 9.30291 27.8912C9.1357 27.8673 8.9685 27.8495 8.80137 27.8255C8.63416 27.8017 8.47294 27.7778 8.30574 27.7539C3.59459 27.0195 0.262688 25.4491 0.0118828 23.592C0.00590625 23.5382 0 23.4845 0 23.4248V23.377C0 23.3592 0 23.3472 0.00590625 23.3293C0 23.3174 0 23.3114 0.00590625 23.3054C0.0118828 23.2397 0.0178594 23.186 0.0298125 23.1263C0.0357891 23.0964 0.0416953 23.0666 0.0416953 23.0367C0.0357891 23.0248 0.0416953 23.0129 0.0476719 23.0009C0.0476719 22.989 0.0476719 22.971 0.0536484 22.9531C0.0656016 22.9173 0.0715781 22.8755 0.0895078 22.8397C0.107438 22.7859 0.131344 22.7263 0.15518 22.6665C0.895641 20.9647 4.26931 19.5555 8.82527 18.9286C9.36865 18.851 9.92995 18.7853 10.5091 18.7375C10.5151 18.7315 10.527 18.7315 10.533 18.7315C11.6974 18.624 12.9215 18.5703 14.1814 18.5703C15.4413 18.5703 16.6654 18.624 17.8298 18.7315C17.8358 18.7315 17.8477 18.7315 17.8537 18.7375C18.4329 18.7853 18.9942 18.851 19.5375 18.9286C24.0935 19.5555 27.4672 20.9647 28.2076 22.6665C28.2494 22.7621 28.2852 22.8576 28.3092 22.9531C28.3211 23.0129 28.3211 23.0666 28.333 23.1263C28.3449 23.186 28.3509 23.2397 28.3569 23.3054V23.3293C28.3569 23.3412 28.3629 23.3592 28.3629 23.377Z"
            fill="#CEDCE4"
          />
          <path
            d="M23.6737 29.9849V32.1778C22.5313 32.5233 21.2206 32.8071 19.7866 33.0137V30.8207C21.2206 30.6141 22.5313 30.3303 23.6737 29.9849Z"
            fill="#CEDCE4"
          />
          <path
            opacity="0.3"
            d="M28.3091 22.9531C28.3032 22.9651 28.3032 22.971 28.2972 22.977V22.983C27.6284 25.3953 21.5677 27.2822 14.1815 27.2822C6.79513 27.2822 0.734477 25.3953 0.0656641 22.983V22.977C0.0596875 22.971 0.0596875 22.9651 0.0596875 22.9591L0.0537109 22.9531C0.0656641 22.9173 0.0716406 22.8755 0.0895703 22.8397C0.1075 22.7859 0.131406 22.7263 0.155242 22.6665C0.895703 20.9647 4.26937 19.5555 8.82534 18.9286C9.36871 18.851 9.93002 18.7853 10.5092 18.7375C10.5152 18.7315 10.5271 18.7315 10.5331 18.7315C11.6975 18.624 12.9215 18.5703 14.1815 18.5703C15.4414 18.5703 16.6655 18.624 17.8298 18.7315C17.8358 18.7315 17.8478 18.7315 17.8537 18.7375C18.433 18.7853 18.9943 18.851 19.5376 18.9286C24.0936 19.5555 27.4673 20.9647 28.2077 22.6665C28.2494 22.7621 28.2852 22.8576 28.3091 22.9531Z"
            fill="#040000"
          />
          <path
            d="M28.3629 14.1218V21.962C28.3629 22.2007 28.3091 22.4396 28.2076 22.6665C28.2016 22.6785 28.1957 22.6904 28.1957 22.6964C27.1388 24.9773 21.2692 26.715 14.1814 26.715C7.09362 26.715 1.224 24.9773 0.167133 22.6964C0.167133 22.6904 0.161156 22.6785 0.15518 22.6665C0.131273 22.6068 0.107438 22.5471 0.0895078 22.4933C0.0715781 22.4337 0.0536484 22.3799 0.0416953 22.3202C0.0416953 22.3202 0.0416953 22.3142 0.0416953 22.3083C0.0238359 22.2605 0.0177891 22.2067 0.0118125 22.153C0.00590625 22.0873 0 22.0276 0 21.9619V14.1159C0 13.9785 0.0239063 13.8352 0.0596953 13.7038C0.0656719 13.6919 0.0656719 13.6799 0.0716484 13.674C0.0955547 13.5844 0.131344 13.4949 0.167203 13.4113C0.961383 11.6975 4.48432 10.2824 9.19554 9.69124C9.81056 9.61362 10.4435 9.54795 11.0943 9.50013C12.0856 9.42856 13.1186 9.38672 14.1815 9.38672C15.2443 9.38672 16.2773 9.42856 17.2685 9.50013C17.9194 9.54795 18.5523 9.61362 19.1673 9.69124C23.8785 10.2824 27.4015 11.6975 28.1957 13.4113C28.2315 13.4949 28.2673 13.5844 28.2912 13.674C28.2972 13.6799 28.2972 13.6919 28.3032 13.7038C28.339 13.8411 28.3629 13.9785 28.3629 14.1218Z"
            fill="#39526C"
          />
          <path
            opacity="0.1"
            d="M28.3629 14.1455V14.5217C28.3629 16.51 24.7145 18.2119 19.5375 18.9284C19.4181 18.9403 19.2927 18.9583 19.1733 18.9762C17.6208 19.1672 15.9369 19.2747 14.1815 19.2747C12.426 19.2747 10.7421 19.1672 9.18956 18.9762C9.0701 18.9583 8.94474 18.9403 8.82535 18.9284C3.64838 18.2119 0 16.51 0 14.5217V14.1455C0 14.1993 0.00590625 14.259 0.0118828 14.3127C0.202992 16.2056 3.71398 17.8238 8.65814 18.5224C8.70588 18.5284 8.75363 18.5343 8.80144 18.5403C9.18359 18.5999 9.56573 18.6418 9.96581 18.6836C10.145 18.7015 10.3241 18.7194 10.5092 18.7373C10.7003 18.7552 10.8973 18.7731 11.0943 18.7851H11.1302C11.8825 18.8448 12.6588 18.8806 13.453 18.8926C13.6918 18.8985 13.9366 18.8985 14.1815 18.8985C14.4263 18.8985 14.6711 18.8985 14.9099 18.8926C15.7041 18.8806 16.4803 18.8448 17.2327 18.7851H17.2685C17.4656 18.7731 17.6626 18.7552 17.8536 18.7373C18.0387 18.7194 18.2178 18.7015 18.397 18.6836C18.7971 18.6418 19.1792 18.5999 19.5614 18.5403L19.7047 18.5224C24.6488 17.8238 28.1598 16.2056 28.3509 14.3127C28.3569 14.259 28.3629 14.1993 28.3629 14.1455Z"
            fill="#E8E8E9"
          />
          <path
            d="M28.3629 14.0918V14.1456C28.3629 14.1993 28.3569 14.259 28.3509 14.3128C28.0941 16.2056 24.601 17.8119 19.7047 18.5224L19.5614 18.5404C19.3942 18.5642 19.227 18.5821 19.0598 18.606C18.8389 18.6299 18.6239 18.6538 18.3971 18.6837C18.2119 18.7016 18.0209 18.7195 17.8298 18.7314C17.6327 18.7493 17.4357 18.7672 17.2327 18.7851C17.167 18.7851 17.1072 18.7911 17.0416 18.7971C16.8087 18.815 16.5758 18.827 16.3429 18.8389C15.8712 18.8628 15.3935 18.8807 14.9098 18.8926H13.4529C12.9692 18.8807 12.4916 18.8628 12.0198 18.8389C11.7869 18.827 11.554 18.815 11.3212 18.7971C11.2555 18.7911 11.1958 18.7851 11.13 18.7851C10.9271 18.7672 10.73 18.7493 10.533 18.7314C10.3418 18.7195 10.1508 18.7016 9.96567 18.6837C9.73877 18.6538 9.52383 18.6299 9.30291 18.606C9.1357 18.5821 8.9685 18.5642 8.80137 18.5404C8.75363 18.5344 8.70581 18.5284 8.65807 18.5224C3.76179 17.8118 0.268664 16.2056 0.0118828 14.3128C0.00590625 14.259 0 14.1993 0 14.1456V14.0918C0 14.0321 0.00590625 13.9724 0.0118828 13.9127C0.0178594 13.853 0.0238359 13.7933 0.0417656 13.7336C0.0417656 13.7157 0.0477422 13.6918 0.0537188 13.6738H28.3092C28.3222 13.7236 28.3203 13.7752 28.3286 13.8252C28.3433 13.8979 28.3527 13.9716 28.357 14.0461C28.3575 14.0615 28.3629 14.0762 28.3629 14.0918Z"
            fill="#CEDCE4"
          />
          <path
            d="M23.674 20.7007V22.8937C22.5315 23.2391 21.2208 23.5229 19.7869 23.7295V21.5366C21.2209 21.3298 22.5315 21.0461 23.674 20.7007Z"
            fill="#CEDCE4"
          />
          <path
            opacity="0.3"
            d="M28.3033 13.6738V13.7037C27.6166 16.1161 21.5619 18.0029 14.1816 18.0029C6.80124 18.0029 0.746557 16.1161 0.0598145 13.7037V13.6738H28.3033Z"
            fill="#040000"
          />
          <path
            d="M28.3629 4.83657V12.6767C28.3629 12.9274 28.3031 13.1723 28.1957 13.4111C28.1539 13.5007 28.1061 13.5843 28.0524 13.6738C26.6909 15.8235 21.0004 17.4357 14.1815 17.4357C7.36242 17.4357 1.67196 15.8235 0.3105 13.6738C0.256781 13.5843 0.208969 13.5007 0.167203 13.4111C0.167203 13.4051 0.161227 13.3932 0.15525 13.3812C0.131344 13.3276 0.107508 13.2678 0.0895781 13.2081C0.0716484 13.1604 0.0596953 13.1126 0.0477422 13.0648C0.0417656 13.0469 0.0358594 13.0231 0.0358594 13.0051C0.0239063 12.9573 0.0179297 12.9155 0.0119531 12.8678C0.00590625 12.8021 0 12.7424 0 12.6767V4.83059C0.0477422 2.21525 6.3772 0.107422 14.1815 0.107422C19.0061 0.107422 23.2695 0.913555 25.8311 2.14958C27.3239 2.86613 28.2435 3.73196 28.351 4.66944C28.3569 4.72309 28.3629 4.78285 28.3629 4.83657Z"
            fill="#39526C"
          />
          <path
            opacity="0.1"
            d="M28.3629 4.86035V5.23652C28.3629 7.27263 24.5413 9.01027 19.1673 9.69096C17.6148 9.88207 15.937 9.98951 14.1815 9.98951C12.426 9.98951 10.748 9.88207 9.19554 9.69096C3.82149 9.01027 0 7.27263 0 5.23659V4.86042C0 6.99806 4.19175 8.80137 9.96581 9.39846C10.3361 9.43432 10.7122 9.47011 11.0943 9.49992C12.0856 9.57164 13.1186 9.61341 14.1815 9.61341C15.2443 9.61341 16.2773 9.57164 17.2685 9.49992C17.6507 9.47011 18.0269 9.43432 18.3971 9.39846C24.1711 8.8013 28.3629 6.99799 28.3629 4.86035Z"
            fill="#E8E8E9"
          />
          <path
            d="M28.3629 4.80677V4.83659C28.327 6.9802 24.1472 8.78948 18.3971 9.39853C18.0268 9.43439 17.6507 9.47018 17.2685 9.49999C16.2773 9.57171 15.2442 9.61348 14.1814 9.61348C13.1185 9.61348 12.0855 9.57171 11.0943 9.49999C10.7121 9.47018 10.3359 9.43439 9.96574 9.39853C4.21566 8.78948 0.0358594 6.9802 0 4.83659V4.80677C0 2.15557 6.34725 0 14.1815 0C18.9106 0 23.0964 0.788203 25.6759 1.99434C27.2881 2.74669 28.2733 3.67221 28.3509 4.66945C28.3629 4.71122 28.3629 4.75896 28.3629 4.80677Z"
            fill="#CEDCE4"
          />
          <path
            d="M23.674 11.4165V13.6095C22.5315 13.9549 21.2208 14.2387 19.7869 14.4454V12.2524C21.2209 12.0457 22.5315 11.7619 23.674 11.4165Z"
            fill="#CEDCE4"
          />
          <path
            opacity="0.2"
            d="M14.1815 0V36C9.21945 36 4.84861 35.1461 2.3168 33.8503C1.0151 33.1876 0.202992 32.4113 0.0358594 31.5694C0.0118828 31.4619 0 31.3545 0 31.2469V23.3769C0 23.3591 0 23.3471 0.00590625 23.3293C0 23.3173 0 23.3113 0.00590625 23.3054C0.00590625 23.2635 0.0118828 23.2218 0.0178594 23.18C0.0178594 23.1322 0.0238359 23.0844 0.0417656 23.0366C0.0358594 23.0247 0.0417656 23.0128 0.0477422 23.0008C0.0477422 22.9829 0.0477422 22.971 0.0596953 22.959C0.0656719 22.9172 0.0775547 22.8814 0.0895781 22.8396C0.107508 22.7858 0.131414 22.7262 0.15525 22.6664C0.131344 22.6067 0.107508 22.547 0.0895781 22.4932C0.0716484 22.4336 0.0537187 22.3798 0.0417656 22.3201C0.0417656 22.3201 0.0417656 22.3142 0.0417656 22.3082C0.0239062 22.2604 0.0178594 22.2067 0.0118828 22.1529C0.00590625 22.0872 0 22.0275 0 21.9618V14.0919C0 14.0322 0.00590625 13.9724 0.0118828 13.9128C0.0178594 13.853 0.0238359 13.7933 0.0417656 13.7336C0.0417656 13.7157 0.0477422 13.6978 0.0596953 13.6739C0.0775547 13.5784 0.113414 13.4769 0.15525 13.3813C0.131344 13.3277 0.107508 13.2679 0.0895781 13.2082C0.0716484 13.1605 0.0596953 13.1126 0.0477422 13.0649C0.0417656 13.047 0.0358594 13.0231 0.0358594 13.0052C0.0239062 12.9574 0.0179297 12.9156 0.0119531 12.8679C0.00590625 12.8021 0 12.7424 0 12.6767V4.80677C0 2.15557 6.34725 0 14.1815 0Z"
            fill="white"
          />
        </svg>
      )}
      {type == 3 && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.00024 33.75L11.9998 23.25H35.9999L29.9998 33.75H6.00024Z"
            fill="#3777E3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24.0004 23.25H36.0001L24.0004 2.25H12L24.0004 23.25Z"
            fill="#FFCF63"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 23.25L6.00036 33.75L18 12.75L11.9999 2.25L0 23.25Z"
            fill="#11A861"
          />
        </svg>
      )}
      <h1 className="text-left mb-[10px]">{title}</h1>
      <h2>{desc}</h2>

      <p
        className="mt-2"
        style={{
          color: "var(--main-1-c-274-c, #1C274C)",
          fontFamily: "Inter",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
          textDecorationLine: "underline",
        }}
      >
        Connect
      </p>
    </div>
  );
};

export default BotKnowledgeBaseintegration;
