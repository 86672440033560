import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../reducers/authReducer";
import { useEffect } from "react";
import { loginUserWithId } from "../reducers/authReducer";

const Navbar = ({ step, type }) => {
  const userId=localStorage.getItem("userId");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const UserLogOut = () => {
    dispatch(logout());
  };

  // const loginWithId = (account) => {
  //   dispatch(loginUserWithId(account));
  // };

  // useEffect(() => {
  //   if (localStorage.getItem("userId")) {
  //     const id = localStorage.getItem("userId");
  //     const account = {
  //       id: id,
  //     };
  //     console.log(account);
  //     loginWithId(account);
  //   }
  // }, []);

  return (
    <div className="navbar hidden sm:block">
      {!userId ? (
        <div className="flex">
          {/* <img className="h-[30px] ml-6" src="/logo.png" /> */}
          <div className="flex-1"></div>
          <Link className="mr-3" to={"/login"}>
            Log In
          </Link>
          <Link className="mr-3" to={"/register"}>
            Register
          </Link>
        </div>
      ) : (
        <div className="flex justify-between items-center">
          <div className="flex">
            {type == 1 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_342_13)">
                  <path
                    d="M8.82499 3.825C8.50832 3.50833 8.08332 3.33333 7.64166 3.33333H3.33332C2.41666 3.33333 1.67499 4.08333 1.67499 5L1.66666 15C1.66666 15.9167 2.41666 16.6667 3.33332 16.6667H16.6667C17.5833 16.6667 18.3333 15.9167 18.3333 15V6.66667C18.3333 5.75 17.5833 5 16.6667 5H9.99999L8.82499 3.825Z"
                    fill="#848A9E"
                  />
                  <rect x="4" y="8" width="12" height="6" rx="1" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_342_13">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <></>
            )}
            {type == 1 ? (
              <span className="default-text ml-[5px]">Step {step + 1}/5</span>
            ) : (
              <span className="default-text ml-[5px]"></span>
            )}
          </div>
          <div>
            <Link
              className="default-text mr-[20px]"
              to={
                "https://medium.com/@getbind.co/how-to-build-complex-llm-applications-with-your-own-data-and-services-c9186e92d926"
              }
              target="_blank"
            >
              What are LLMs?
            </Link>
            <Link
              className="default-text mr-[20px]"
              to={
                "https://medium.com/@getbind.co/how-to-create-a-pizza-ordering-ai-bot-using-llms-b3763b1c8d5c"
              }
              target="_blank"
            >
              {" "}
              Demo : Pizza Bot
            </Link>
          </div>
          <div className="flex">
            {/* <div className="mr-[12px] relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clip-path="url(#clip0_335_10)">
                  <path
                    d="M12.0001 22C13.1001 22 14.0001 21.1 14.0001 20H10.0001C10.0001 21.1 10.8901 22 12.0001 22ZM18.0001 16V11C18.0001 7.93 16.3601 5.36 13.5001 4.68V4C13.5001 3.17 12.8301 2.5 12.0001 2.5C11.1701 2.5 10.5001 3.17 10.5001 4V4.68C7.63005 5.36 6.00005 7.92 6.00005 11V16L4.71005 17.29C4.08005 17.92 4.52005 19 5.41005 19H18.5801C19.4701 19 19.9201 17.92 19.2901 17.29L18.0001 16Z"
                    fill="#E4E5EA"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_335_10">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="8"
                viewBox="0 0 9 8"
                fill="none"
                className="badge"
              >
                <ellipse
                  cx="4.37573"
                  cy="4"
                  rx="3.9779"
                  ry="4"
                  fill="#9E1C1C"
                />
              </svg>
            </div>
            <Link className="text-white mr-3 flex">
              <div className="flex justify-center items-center">
                <img
                  src="/images/user.jpg"
                  className="ml-3"
                  style={{ borderRadius: "50%", width: "30px" }}
                />
              </div>
            </Link> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
