import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import { useParams, useNavigate } from "react-router";
import { Link } from "react-router-dom";

const BotPlugin = (props) => {
  const setPrompt = props.setPrompt;
  const { id } = useParams();

  const [type, setType] = useState(0);
  const [exIndex, setExIndex] = useState(0);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const nextStep = async () => {
    // let payload = {
    //   prompt: template,
    // };
    // await dispatch(updateCurrentBotPrompt(payload));

    // if (botName == "") {
    //   NotificationManager.warning("Pleae Input BotName...", "", 2000);
    //   return;
    // }
    // if (botDesc == "") {
    //   NotificationManager.warning("Pleae Input Bot Description", "", 2000);
    //   return;
    // }
    // payload = {
    //   name: botName,
    //   desc: botDesc,
    //   knowledge_base_type: type,
    // };
    // await dispatch(updateCurrentBotName(payload));

    navigate(`/createBot/publish/${id}`);
  };

  const prevStep = async () => {
    navigate(`/createBot/knowledge/${id}`);
  };

  useEffect(() => {
    // promptSelect();
  }, []);
  return (
    <div className="w-[80%] my-[20px] mx-[20px]">
      <h1
        className="text-left mb-[10px]"
        style={{
          fontFamily: "Inter",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "normal",
          color: "var(--main-1-c-274-c, #1C274C)",
        }}
      >
        Plugins
      </h1>
      <h2
        style={{
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          color: "var(--main-1-c-274-c, #1C274C)",
        }}
        className="mb-[30px]"
      >
        Looking to use Plugins?
        <Link
          style={{ textDecorationLine: "underline" }}
          to={
            "https://www.getbind.co/contact?utm_source=in-app-plugins&utm_medium=in-app-plugins&utm_campaign=in-app-plugins"
          }
          target="_blank"
        >
          Contact us!
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            style={{ display: "inline" }}
          >
            <g clip-path="url(#clip0_275_1044)">
              <path
                d="M12.75 13.4583H4.25C3.86042 13.4583 3.54167 13.1396 3.54167 12.75V4.25C3.54167 3.86042 3.86042 3.54167 4.25 3.54167H7.79167C8.18125 3.54167 8.5 3.22292 8.5 2.83333C8.5 2.44375 8.18125 2.125 7.79167 2.125H3.54167C2.75542 2.125 2.125 2.7625 2.125 3.54167V13.4583C2.125 14.2375 2.7625 14.875 3.54167 14.875H13.4583C14.2375 14.875 14.875 14.2375 14.875 13.4583V9.20833C14.875 8.81875 14.5562 8.5 14.1667 8.5C13.7771 8.5 13.4583 8.81875 13.4583 9.20833V12.75C13.4583 13.1396 13.1396 13.4583 12.75 13.4583ZM9.91667 2.83333C9.91667 3.22292 10.2354 3.54167 10.625 3.54167H12.4596L5.9925 10.0087C5.71625 10.285 5.71625 10.7312 5.9925 11.0075C6.26875 11.2837 6.715 11.2837 6.99125 11.0075L13.4583 4.54042V6.375C13.4583 6.76458 13.7771 7.08333 14.1667 7.08333C14.5562 7.08333 14.875 6.76458 14.875 6.375V2.83333C14.875 2.44375 14.5562 2.125 14.1667 2.125H10.625C10.2354 2.125 9.91667 2.44375 9.91667 2.83333Z"
                fill="#1C274C"
              />
            </g>
            <defs>
              <clipPath id="clip0_275_1044">
                <rect width="17" height="17" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </h2>

      <h2
        style={{
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          color: "var(--main-1-c-274-c, #1C274C)",
        }}
        className="mb-[30px]"
      >
        How do you plan to integrate the plugin into your bot?
      </h2>

      <div className="flex mb-[24px]">
        <div
          className={classNames("cursor-pointer mr-[10px]", {
            "plugin-item": type == 1,
            "plugin-item-active": type == 0,
          })}
          onClick={() => setType(0)}
        >
          <h2
            style={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              color: "var(--main-1-c-274-c, #1C274C)",
            }}
            className="mb-[10px]"
          >
            Select existing plugin
          </h2>
          <h2
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              color: "var(--main-1-c-274-c, #1C274C)",
            }}
          >
            How do you plan to integrate the plugin into your bot?
          </h2>
        </div>
        <div
          className={classNames("cursor-pointer", {
            "plugin-item": type == 0,
            "plugin-item-active": type == 1,
          })}
          onClick={() => setType(1)}
        >
          <h2
            style={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              color: "var(--main-1-c-274-c, #1C274C)",
            }}
            className="mb-[10px]"
          >
            Create a new plugin
          </h2>
          <h2
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              color: "var(--main-1-c-274-c, #1C274C)",
            }}
          >
            Connect your internal services to the LLM
          </h2>
        </div>
      </div>

      <div className="plugin-utils flex mb-[10px]">
        <div className="flex items-center mr-[25px]">
          <h2
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              color: "var(--main-1-c-274-c, #1C274C)",
            }}
            className="mr-[5px]"
          >
            Categories
          </h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              d="M6.42848 7.35363L9.50015 10.4253L12.5718 7.35363C12.8806 7.04488 13.3793 7.04488 13.6881 7.35363C13.9968 7.66238 13.9968 8.16113 13.6881 8.46988L10.0543 12.1036C9.74557 12.4124 9.24682 12.4124 8.93807 12.1036L5.30432 8.46988C4.99557 8.16113 4.99557 7.66238 5.30432 7.35363C5.61307 7.0528 6.11973 7.04488 6.42848 7.35363Z"
              fill="black"
            />
          </svg>
        </div>
        <div className="flex items-center mr-[25px]">
          <h2
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              color: "var(--main-1-c-274-c, #1C274C)",
            }}
            className="mr-[5px]"
          >
            Sort by
          </h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              d="M6.42848 7.35363L9.50015 10.4253L12.5718 7.35363C12.8806 7.04488 13.3793 7.04488 13.6881 7.35363C13.9968 7.66238 13.9968 8.16113 13.6881 8.46988L10.0543 12.1036C9.74557 12.4124 9.24682 12.4124 8.93807 12.1036L5.30432 8.46988C4.99557 8.16113 4.99557 7.66238 5.30432 7.35363C5.61307 7.0528 6.11973 7.04488 6.42848 7.35363Z"
              fill="black"
            />
          </svg>
        </div>
        <div className="flex items-center mr-[25px]">
          <h2
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              color: "var(--main-1-c-274-c, #1C274C)",
            }}
            className="mr-[5px]"
          >
            Filters
          </h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              d="M6.42848 7.35363L9.50015 10.4253L12.5718 7.35363C12.8806 7.04488 13.3793 7.04488 13.6881 7.35363C13.9968 7.66238 13.9968 8.16113 13.6881 8.46988L10.0543 12.1036C9.74557 12.4124 9.24682 12.4124 8.93807 12.1036L5.30432 8.46988C4.99557 8.16113 4.99557 7.66238 5.30432 7.35363C5.61307 7.0528 6.11973 7.04488 6.42848 7.35363Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div
          className={classNames({
            "existing-plugin-item": exIndex != 0,
            "existing-plugin-item-active": exIndex == 0,
          })}
          onClick={() => setExIndex(0)}
        >
          <div className="flex items-center mb-[15px]">
            <img
              src="/assets/images/search_internet.svg"
              className="mr-[10px]"
            />
            <h2
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
              className={classNames({
                "text-[#4529FA]": exIndex == 0,
                "text-[#1C274C]": exIndex != 0,
              })}
            >
              Search Internet
            </h2>
          </div>
          <div className="mb-[12px]">
            Offered by
            <span
              style={{ textDecorationLine: "underline" }}
              className="ml-[10px]"
            >
              Brave Search
            </span>
          </div>
          <p className="mb-[12px]">
            Power your search and AI applications with the fastest growing
            independent search engine since Bing. Of it ...
          </p>
          <div className="mb-[12px]">
            <span style={{ textDecorationLine: "underline" }}>See details</span>
          </div>
          <hr className="mb-[12px]" />
          <div
            className={classNames("flex justify-center items-center", {
              "text-[#4529FA]": exIndex == 0,
            })}
          >
            {exIndex != 0 ? "Connect to Bot" : "Connected"}
            {exIndex != 0 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <g clip-path="url(#clip0_1783_9456)">
                  <path
                    d="M6.58023 4.75398C6.30398 5.03023 6.30398 5.47648 6.58023 5.75273L9.32857 8.50106L6.58023 11.2494C6.30398 11.5256 6.30398 11.9719 6.58023 12.2481C6.85648 12.5244 7.30273 12.5244 7.57898 12.2481L10.8302 8.9969C11.1065 8.72065 11.1065 8.2744 10.8302 7.99815L7.57898 4.7469C7.30982 4.47773 6.85648 4.47773 6.58023 4.75398Z"
                    fill="#1C274C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1783_9456">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="ml-1"
              >
                <g clip-path="url(#clip0_1783_9468)">
                  <path
                    d="M8.49984 1.41699C4.58984 1.41699 1.4165 4.59033 1.4165 8.50033C1.4165 12.4103 4.58984 15.5837 8.49984 15.5837C12.4098 15.5837 15.5832 12.4103 15.5832 8.50033C15.5832 4.59033 12.4098 1.41699 8.49984 1.41699ZM6.58025 11.5391L4.03734 8.99616C3.76109 8.71991 3.76109 8.27366 4.03734 7.99741C4.31359 7.72116 4.75984 7.72116 5.03609 7.99741L7.08317 10.0374L11.9565 5.16408C12.2328 4.88783 12.679 4.88783 12.9553 5.16408C13.2315 5.44033 13.2315 5.88658 12.9553 6.16283L7.579 11.5391C7.30984 11.8153 6.8565 11.8153 6.58025 11.5391Z"
                    fill="#4529FA"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1783_9468">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
        </div>
        <div
          className={classNames({
            "existing-plugin-item": exIndex != 1,
            "existing-plugin-item-active": exIndex == 1,
          })}
          onClick={() => setExIndex(1)}
        >
          <div className="flex items-center mb-[15px]">
            <img src="/assets/images/send_email.svg" className="mr-[10px]" />
            <h2
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
              className={classNames({
                "text-[#4529FA]": exIndex == 1,
                "text-[#1C274C]": exIndex != 1,
              })}
            >
              Send Email
            </h2>
          </div>
          <div className="mb-[12px]">
            Offered by
            <span
              style={{ textDecorationLine: "underline" }}
              className="ml-[10px]"
            >
              SendGrid
            </span>
          </div>
          <p className="mb-[12px]">
            SendGrid - Send with an email based on user request in chat.
            Integrate and deliver in minutes with our RESTful Email An af ...
          </p>
          <div className="mb-[12px]">
            <span style={{ textDecorationLine: "underline" }}>See details</span>
          </div>
          <hr className="mb-[12px]" />
          <div
            className={classNames("flex justify-center items-center", {
              "text-[#4529FA]": exIndex == 1,
            })}
          >
            {exIndex != 1 ? "Connect to Bot" : "Connected"}
            {exIndex != 1 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <g clip-path="url(#clip0_1783_9456)">
                  <path
                    d="M6.58023 4.75398C6.30398 5.03023 6.30398 5.47648 6.58023 5.75273L9.32857 8.50106L6.58023 11.2494C6.30398 11.5256 6.30398 11.9719 6.58023 12.2481C6.85648 12.5244 7.30273 12.5244 7.57898 12.2481L10.8302 8.9969C11.1065 8.72065 11.1065 8.2744 10.8302 7.99815L7.57898 4.7469C7.30982 4.47773 6.85648 4.47773 6.58023 4.75398Z"
                    fill="#1C274C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1783_9456">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="ml-1"
              >
                <g clip-path="url(#clip0_1783_9468)">
                  <path
                    d="M8.49984 1.41699C4.58984 1.41699 1.4165 4.59033 1.4165 8.50033C1.4165 12.4103 4.58984 15.5837 8.49984 15.5837C12.4098 15.5837 15.5832 12.4103 15.5832 8.50033C15.5832 4.59033 12.4098 1.41699 8.49984 1.41699ZM6.58025 11.5391L4.03734 8.99616C3.76109 8.71991 3.76109 8.27366 4.03734 7.99741C4.31359 7.72116 4.75984 7.72116 5.03609 7.99741L7.08317 10.0374L11.9565 5.16408C12.2328 4.88783 12.679 4.88783 12.9553 5.16408C13.2315 5.44033 13.2315 5.88658 12.9553 6.16283L7.579 11.5391C7.30984 11.8153 6.8565 11.8153 6.58025 11.5391Z"
                    fill="#4529FA"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1783_9468">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
        </div>
        <div
          className={classNames({
            "existing-plugin-item": exIndex != 2,
            "existing-plugin-item-active": exIndex == 2,
          })}
          onClick={() => setExIndex(2)}
        >
          <div className="flex items-center mb-[15px]">
            <img src="/assets/images/calculation.svg" className="mr-[10px]" />
            <h2
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
              className={classNames({
                "text-[#4529FA]": exIndex == 2,
                "text-[#1C274C]": exIndex != 2,
              })}
            >
              Calculations
            </h2>
          </div>
          <div className="mb-[12px]">
            Offered by
            <span
              style={{ textDecorationLine: "underline" }}
              className="ml-[10px]"
            >
              MathJS
            </span>
          </div>
          <p className="mb-[12px]">
            Add calculations to your LLM-powered applications to get accurate
            and factual math-based answers.
          </p>
          <div className="mb-[12px]">
            <span style={{ textDecorationLine: "underline" }}>See details</span>
          </div>
          <hr className="mb-[12px]" />
          <div
            className={classNames("flex justify-center items-center", {
              "text-[#4529FA]": exIndex == 2,
            })}
          >
            {exIndex != 2 ? "Connect to Bot" : "Connected"}
            {exIndex != 2 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <g clip-path="url(#clip0_1783_9456)">
                  <path
                    d="M6.58023 4.75398C6.30398 5.03023 6.30398 5.47648 6.58023 5.75273L9.32857 8.50106L6.58023 11.2494C6.30398 11.5256 6.30398 11.9719 6.58023 12.2481C6.85648 12.5244 7.30273 12.5244 7.57898 12.2481L10.8302 8.9969C11.1065 8.72065 11.1065 8.2744 10.8302 7.99815L7.57898 4.7469C7.30982 4.47773 6.85648 4.47773 6.58023 4.75398Z"
                    fill="#1C274C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1783_9456">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="ml-1"
              >
                <g clip-path="url(#clip0_1783_9468)">
                  <path
                    d="M8.49984 1.41699C4.58984 1.41699 1.4165 4.59033 1.4165 8.50033C1.4165 12.4103 4.58984 15.5837 8.49984 15.5837C12.4098 15.5837 15.5832 12.4103 15.5832 8.50033C15.5832 4.59033 12.4098 1.41699 8.49984 1.41699ZM6.58025 11.5391L4.03734 8.99616C3.76109 8.71991 3.76109 8.27366 4.03734 7.99741C4.31359 7.72116 4.75984 7.72116 5.03609 7.99741L7.08317 10.0374L11.9565 5.16408C12.2328 4.88783 12.679 4.88783 12.9553 5.16408C13.2315 5.44033 13.2315 5.88658 12.9553 6.16283L7.579 11.5391C7.30984 11.8153 6.8565 11.8153 6.58025 11.5391Z"
                    fill="#4529FA"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1783_9468">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
        </div>
        <div
          className={classNames({
            "existing-plugin-item": exIndex != 3,
            "existing-plugin-item-active": exIndex == 3,
          })}
          onClick={() => setExIndex(3)}
        >
          <div className="flex items-center mb-[15px]">
            <img src="/assets/images/flights.svg" className="mr-[10px]" />
            <h2
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
              className={classNames({
                "text-[#4529FA]": exIndex == 3,
                "text-[#1C274C]": exIndex != 3,
              })}
            >
              Flights API
            </h2>
          </div>
          <div className="mb-[12px]">
            Offered by
            <span
              style={{ textDecorationLine: "underline" }}
              className="ml-[10px]"
            >
              PricesAPI
            </span>
          </div>
          <p className="mb-[12px]">Search flights and compare prices.</p>
          <div className="mb-[12px]">
            <span style={{ textDecorationLine: "underline" }}>See details</span>
          </div>
          <hr className="mb-[12px]" />
          <div
            className={classNames("flex justify-center items-center", {
              "text-[#4529FA]": exIndex == 3,
            })}
          >
            {exIndex != 3 ? "Connect to Bot" : "Connected"}
            {exIndex != 3 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <g clip-path="url(#clip0_1783_9456)">
                  <path
                    d="M6.58023 4.75398C6.30398 5.03023 6.30398 5.47648 6.58023 5.75273L9.32857 8.50106L6.58023 11.2494C6.30398 11.5256 6.30398 11.9719 6.58023 12.2481C6.85648 12.5244 7.30273 12.5244 7.57898 12.2481L10.8302 8.9969C11.1065 8.72065 11.1065 8.2744 10.8302 7.99815L7.57898 4.7469C7.30982 4.47773 6.85648 4.47773 6.58023 4.75398Z"
                    fill="#1C274C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1783_9456">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="ml-1"
              >
                <g clip-path="url(#clip0_1783_9468)">
                  <path
                    d="M8.49984 1.41699C4.58984 1.41699 1.4165 4.59033 1.4165 8.50033C1.4165 12.4103 4.58984 15.5837 8.49984 15.5837C12.4098 15.5837 15.5832 12.4103 15.5832 8.50033C15.5832 4.59033 12.4098 1.41699 8.49984 1.41699ZM6.58025 11.5391L4.03734 8.99616C3.76109 8.71991 3.76109 8.27366 4.03734 7.99741C4.31359 7.72116 4.75984 7.72116 5.03609 7.99741L7.08317 10.0374L11.9565 5.16408C12.2328 4.88783 12.679 4.88783 12.9553 5.16408C13.2315 5.44033 13.2315 5.88658 12.9553 6.16283L7.579 11.5391C7.30984 11.8153 6.8565 11.8153 6.58025 11.5391Z"
                    fill="#4529FA"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1783_9468">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
        </div>
        <div
          className={classNames({
            "existing-plugin-item": exIndex != 4,
            "existing-plugin-item-active": exIndex == 4,
          })}
          onClick={() => setExIndex(4)}
        >
          <div className="flex items-center mb-[15px]">
            <img src="/assets/images/delivery.svg" className="mr-[10px]" />
            <h2
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
              className={classNames({
                "text-[#4529FA]": exIndex == 4,
                "text-[#1C274C]": exIndex != 4,
              })}
            >
              Delivery Tracking
            </h2>
          </div>
          <div className="mb-[12px]">
            Offered by
            <span
              style={{ textDecorationLine: "underline" }}
              className="ml-[10px]"
            >
              FedEX
            </span>
          </div>
          <p className="mb-[12px]">
            Fedex Shipment Tracking API tool for delivery and everything else
          </p>
          <div className="mb-[12px]">
            <span style={{ textDecorationLine: "underline" }}>See details</span>
          </div>
          <hr className="mb-[12px]" />
          <div
            className={classNames("flex justify-center items-center", {
              "text-[#4529FA]": exIndex == 4,
            })}
          >
            {exIndex != 4 ? "Connect to Bot" : "Connected"}
            {exIndex != 4 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <g clip-path="url(#clip0_1783_9456)">
                  <path
                    d="M6.58023 4.75398C6.30398 5.03023 6.30398 5.47648 6.58023 5.75273L9.32857 8.50106L6.58023 11.2494C6.30398 11.5256 6.30398 11.9719 6.58023 12.2481C6.85648 12.5244 7.30273 12.5244 7.57898 12.2481L10.8302 8.9969C11.1065 8.72065 11.1065 8.2744 10.8302 7.99815L7.57898 4.7469C7.30982 4.47773 6.85648 4.47773 6.58023 4.75398Z"
                    fill="#1C274C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1783_9456">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="ml-1"
              >
                <g clip-path="url(#clip0_1783_9468)">
                  <path
                    d="M8.49984 1.41699C4.58984 1.41699 1.4165 4.59033 1.4165 8.50033C1.4165 12.4103 4.58984 15.5837 8.49984 15.5837C12.4098 15.5837 15.5832 12.4103 15.5832 8.50033C15.5832 4.59033 12.4098 1.41699 8.49984 1.41699ZM6.58025 11.5391L4.03734 8.99616C3.76109 8.71991 3.76109 8.27366 4.03734 7.99741C4.31359 7.72116 4.75984 7.72116 5.03609 7.99741L7.08317 10.0374L11.9565 5.16408C12.2328 4.88783 12.679 4.88783 12.9553 5.16408C13.2315 5.44033 13.2315 5.88658 12.9553 6.16283L7.579 11.5391C7.30984 11.8153 6.8565 11.8153 6.58025 11.5391Z"
                    fill="#4529FA"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1783_9468">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
        </div>
        <div
          className={classNames({
            "existing-plugin-item": exIndex != 5,
            "existing-plugin-item-active": exIndex == 5,
          })}
          onClick={() => setExIndex(5)}
        >
          <div className="flex items-center mb-[15px]">
            <img src="/assets/images/credit.svg" className="mr-[10px]" />
            <h2
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
              className={classNames({
                "text-[#4529FA]": exIndex == 5,
                "text-[#1C274C]": exIndex != 5,
              })}
            >
              Equifax Credit API with advanced ...
            </h2>
          </div>
          <div className="mb-[12px]">
            Offered by
            <span
              style={{ textDecorationLine: "underline" }}
              className="ml-[10px]"
            >
              Xignite
            </span>
          </div>
          <p className="mb-[12px]">
            It’s hard to imagine the succes ses of our clients and Stocktwits,
            without their ability to get big ...
          </p>
          <div className="mb-[12px]">
            <span style={{ textDecorationLine: "underline" }}>See details</span>
          </div>
          <hr className="mb-[12px]" />
          <div
            className={classNames("flex justify-center items-center", {
              "text-[#4529FA]": exIndex == 5,
            })}
          >
            {exIndex != 5 ? "Connect to Bot" : "Connected"}
            {exIndex != 5 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <g clip-path="url(#clip0_1783_9456)">
                  <path
                    d="M6.58023 4.75398C6.30398 5.03023 6.30398 5.47648 6.58023 5.75273L9.32857 8.50106L6.58023 11.2494C6.30398 11.5256 6.30398 11.9719 6.58023 12.2481C6.85648 12.5244 7.30273 12.5244 7.57898 12.2481L10.8302 8.9969C11.1065 8.72065 11.1065 8.2744 10.8302 7.99815L7.57898 4.7469C7.30982 4.47773 6.85648 4.47773 6.58023 4.75398Z"
                    fill="#1C274C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1783_9456">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="ml-1"
              >
                <g clip-path="url(#clip0_1783_9468)">
                  <path
                    d="M8.49984 1.41699C4.58984 1.41699 1.4165 4.59033 1.4165 8.50033C1.4165 12.4103 4.58984 15.5837 8.49984 15.5837C12.4098 15.5837 15.5832 12.4103 15.5832 8.50033C15.5832 4.59033 12.4098 1.41699 8.49984 1.41699ZM6.58025 11.5391L4.03734 8.99616C3.76109 8.71991 3.76109 8.27366 4.03734 7.99741C4.31359 7.72116 4.75984 7.72116 5.03609 7.99741L7.08317 10.0374L11.9565 5.16408C12.2328 4.88783 12.679 4.88783 12.9553 5.16408C13.2315 5.44033 13.2315 5.88658 12.9553 6.16283L7.579 11.5391C7.30984 11.8153 6.8565 11.8153 6.58025 11.5391Z"
                    fill="#4529FA"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1783_9468">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-start">
        <button className="prev-button" onClick={() => prevStep()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <g clip-path="url(#clip0_696_2975)">
              <path
                d="M14.2888 6.4304C13.915 6.05665 13.3113 6.05665 12.9375 6.4304L8.53875 10.8291C8.165 11.2029 8.165 11.8066 8.53875 12.1804L12.9375 16.5791C13.3113 16.9529 13.915 16.9529 14.2888 16.5791C14.6625 16.2054 14.6625 15.6016 14.2888 15.2279L10.5704 11.5L14.2888 7.78165C14.6529 7.4079 14.6529 6.79456 14.2888 6.4304Z"
                fill="#1C274C"
              />
            </g>
            <defs>
              <clipPath id="clip0_696_2975">
                <rect width="23" height="23" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Previous step
        </button>
        <button className="next-button" onClick={() => nextStep()}>
          Save and Continue
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
          >
            <g clip-path="url(#clip0_696_2972)">
              <path
                d="M8.9221 6.43039C8.54835 6.80414 8.54835 7.40789 8.9221 7.78164L12.6404 11.5L8.9221 15.2183C8.54835 15.5921 8.54835 16.1958 8.9221 16.5696C9.29585 16.9433 9.8996 16.9433 10.2733 16.5696L14.6721 12.1708C15.0458 11.7971 15.0458 11.1933 14.6721 10.8196L10.2733 6.4208C9.90918 6.05664 9.29585 6.05664 8.9221 6.43039Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_696_2972">
                <rect width="23" height="23" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
        <button className="skip-button" onClick={() => nextStep()}>
          Skip this step
        </button>
      </div>
    </div>
  );
};

export default BotPlugin;
