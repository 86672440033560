import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebaseConfig";

const ProtectedRoute = () => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authCheckCompleted, setAuthCheckCompleted] = useState(false);
  const user = localStorage.getItem("userId");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setAuthCheckCompleted(true);
    });

    return unsubscribe;
  }, [auth]);

  if (!authCheckCompleted) {
    return null;
  }

  if (location.pathname === "/login" && !isAuthenticated) {
    return <Outlet />;
  }

  // Navigation logic for protected routes
  if (
    !isAuthenticated &&
    location.pathname !== "/login" &&
    location.pathname !== "/register" &&
    location.pathname !== "/forgotPassword" &&
    location.pathname !== "/resetPassword"
  ) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  if (
    isAuthenticated &&
    user &&
    (location.pathname === "/login" ||
      location.pathname === "/register" ||
      location.pathname === "/forgotPassword" ||
      location.pathname === "/resetPassword")
  ) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
