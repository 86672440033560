import Navbar from "./Navbar";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import HomeSidebar from "./HomeSideBar";
import { loginUserWithId } from "../reducers/authReducer";
import { useParams } from "react-router";
import MyBot from "./MyBot";

const Main = ({ step }) => {
  const user = useSelector((state) => state.auth.currentUser);
  //   const [step, setStep] = useState(0);
  let { id } = useParams();
  const [type, setType] = useState(0);
  const [name, setName] = useState("");
  const [prompt, setPrompt] = useState("");
  const [base, setBase] = useState("");

  const publishBot = () => {
    const botInfo = {
      type,
      name,
      prompt,
      base,
    };
    console.log(botInfo);
  };
  const dispatch = useDispatch();
  // const loginWithId = (account) => {
  //   dispatch(loginUserWithId(account));
  // };

  // useEffect(() => {
  //   if (localStorage.getItem("userId")) {
  //     const id = localStorage.getItem("userId");
  //     const account = {
  //       id: id,
  //     };
  //     console.log(account);
  //     loginWithId(account);
  //   }
  // }, []);

  let content;
  switch (step) {
    case 1:
      content = <MyBot />;
      break;
    default:
      content = null;
  }

  return (
    <div className="flex w-full h-full">
      <HomeSidebar step={step} />
      <div className="w-full">
        <Navbar step={step} type={0} />
        <div style={{ width: "100%" }}>{content}</div>
      </div>
    </div>
  );
};

export default Main;
