import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar.js";
import SideBar from "./Sidebar.js";
import { useState, useRef, useEffect, useTransition } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBot } from "../../../reducers/botReducer";
import useWebSocket from "react-use-websocket";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import TypingText from "../../steps/utils/TypingText.js";
import { Link } from "react-router-dom";
import EmailDialog from "./EmailDialog.js";

const WebBotHome = () => {
  let { id } = useParams();
  const [msg, setMsg] = useState("");
  const [status, setStatus] = useState(0);
  const [isSending, setIsSending] = useState(0);
  const [openDlg, setOpenDlg] = useState(0);
  const [chatEnable, setChatEnable] = useState(0);
  const [selectedBot, setSelectedBot] = useState(0);
  const currentBot = useSelector((state) => state.bot.bot);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chatStarted = () => {
    let url = "/webbot/";
    if (selectedBot == 0) url += "65a803813ed0009f896e8dc8";
    if (selectedBot == 1) url += "6566c389cc7b2badce6c3d0d";
    if (selectedBot == 2) url += "6566c73cb1b175dccb8652bb";
    if (selectedBot == 3) url += "659f920e3ed0009f896e8db7";
    navigate(url, { state: { msg: msg } });
  };
  return (
    <div>
      <Navbar />
      <div className="flex" style={{ height: `calc(100vh - 100px)` }}>
        <div className="flex-1 flex flex-col mx-0 sm:mx-[100px]">
          <div className="flex-1 flex items-center justify-center flex-col">
            <img
              className=" hidden sm:block w-[200px] my-[50px]"
              src="/logo-black.svg"
            />
            <p className="recommendation-bot-header mb-[16px]">
              Pick LLM applications and start conversing
            </p>
            <div className="flex items-center justify-center flex-wrap">
              <div
                className={classNames(
                  "flex items-center justify-center bot-template w-[160px] sm:w-[240px] h-[160px] sm:h-[140px] mr-[12px]",
                  {
                    "bot-template-selected": selectedBot == 0,
                    "bot-template-unselected": selectedBot != 0,
                  }
                )}
                onClick={() => setSelectedBot(0)}
              >
                <div>
                  <div className="flex items-center justify-center mb-[10px]">
                    <img src="/assets/images/bindGpt.png" />
                    <p className="ml-[8px] recommendation-bot-header flex-1">
                      Bind GPT
                    </p>
                  </div>
                  <p className="recommendation-bot-desc">
                    Versatile general-purpose bot for diverse tasks and needs
                  </p>
                </div>
              </div>
              <div
                className={classNames(
                  "flex items-center justify-center bot-template w-[160px] sm:w-[240px] h-[160px] sm:h-[140px] mr-[12px]",
                  {
                    "bot-template-selected": selectedBot == 1,
                    "bot-template-unselected": selectedBot != 1,
                  }
                )}
                onClick={() => setSelectedBot(1)}
              >
                <div>
                  <div className="flex items-center justify-center mb-[10px]">
                    <img src="/assets/images/salesBot.png" />
                    <p className="ml-[8px] recommendation-bot-header flex-1">
                      Sales Bot
                    </p>
                  </div>
                  <p className="recommendation-bot-desc">
                    Discover new leads, Upload your leads and enrich with data.
                  </p>
                </div>
              </div>
              <div
                className={classNames(
                  "flex items-center justify-center bot-template w-[160px] sm:w-[240px] h-[160px] sm:h-[140px] mr-[12px]",
                  {
                    "bot-template-selected": selectedBot == 2,
                    "bot-template-unselected": selectedBot != 2,
                  }
                )}
                onClick={() => setSelectedBot(2)}
              >
                <div>
                  <div className="flex items-center justify-center mb-[10px]">
                    <img src="/assets/images/dataExtractor.png" />
                    <p className="ml-[8px] recommendation-bot-header flex-1">
                      Data Extractor
                    </p>
                  </div>
                  <p className="recommendation-bot-desc">
                    Extract people, places, emails and other entities from news,
                    court records into a JSON.
                  </p>
                </div>
              </div>
              <div
                className={classNames(
                  "flex items-center justify-center bot-template w-[160px] sm:w-[240px] h-[160px] sm:h-[140px] mr-[12px]",
                  {
                    "bot-template-selected": selectedBot == 3,
                    "bot-template-unselected": selectedBot != 3,
                  }
                )}
                onClick={() => setSelectedBot(3)}
              >
                <div>
                  <div className="flex items-center justify-center mb-[10px]">
                    <img src="/assets/images/productSupport.png" />
                    <p className="ml-[8px] recommendation-bot-header flex-1">
                      Product support ...
                    </p>
                  </div>
                  <p className="recommendation-bot-desc">
                    Connect your Intercom, Zendesk, FAQs and create your Product
                    Support bot.
                  </p>
                </div>
              </div>
            </div>
            <div className="home-webbot-textarea w-[95%] sm:w-[1000px]">
              <textarea
                rows={3}
                className="w-full p-3"
                placeholder="Enter your message here"
                onChange={(e) => setMsg(e.target.value)}
              ></textarea>
              <div className="flex mb-[16px] mr-[12px]">
                <div className="flex-1"></div>
                <div className="flex">
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    className="mr-2"
                  >
                    <g clip-path="url(#clip0_2532_6099)">
                      <path
                        d="M15.8125 6.46861V16.6078C15.8125 18.6107 14.3462 20.3932 12.3529 20.5849C10.0625 20.8053 8.14581 19.0132 8.14581 16.7707V4.92569C8.14581 3.67027 9.04665 2.52986 10.2925 2.40527C11.73 2.26152 12.9375 3.38277 12.9375 4.79152V14.854C12.9375 15.3811 12.5062 15.8124 11.9791 15.8124C11.4521 15.8124 11.0208 15.3811 11.0208 14.854V6.46861C11.0208 6.07569 10.695 5.74986 10.3021 5.74986C9.90915 5.74986 9.58331 6.07569 9.58331 6.46861V14.7199C9.58331 15.9753 10.4841 17.1157 11.73 17.2403C13.1675 17.384 14.375 16.2628 14.375 14.854V4.95444C14.375 2.95152 12.9087 1.16902 10.9154 0.977355C8.63456 0.756938 6.70831 2.54902 6.70831 4.79152V16.5503C6.70831 19.3007 8.72081 21.7636 11.4616 22.0224C14.6146 22.3099 17.25 19.8565 17.25 16.7707V6.46861C17.25 6.07569 16.9241 5.74986 16.5312 5.74986C16.1383 5.74986 15.8125 6.07569 15.8125 6.46861Z"
                        fill="#3A4363"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2532_6099">
                        <rect width="23" height="23" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    className="mr-4"
                  >
                    <path
                      d="M11.4999 13.416C13.0908 13.416 14.3749 12.1318 14.3749 10.541V4.79102C14.3749 3.20018 13.0908 1.91602 11.4999 1.91602C9.90911 1.91602 8.62495 3.20018 8.62495 4.79102V10.541C8.62495 12.1318 9.90911 13.416 11.4999 13.416ZM17.1637 10.541C16.6941 10.541 16.3012 10.886 16.2245 11.3556C15.8316 13.6077 13.867 15.3327 11.4999 15.3327C9.13286 15.3327 7.16828 13.6077 6.77536 11.3556C6.6987 10.886 6.30578 10.541 5.8362 10.541C5.25161 10.541 4.79161 11.0585 4.87786 11.6335C5.34745 14.5085 7.64745 16.7606 10.5416 17.1727V19.166C10.5416 19.6931 10.9729 20.1243 11.4999 20.1243C12.027 20.1243 12.4583 19.6931 12.4583 19.166V17.1727C15.3524 16.7606 17.6524 14.5085 18.122 11.6335C18.2179 11.0585 17.7483 10.541 17.1637 10.541Z"
                      fill="#3A4363"
                    />
                  </svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    className="cursor-pointer"
                    onClick={() => chatStarted()}
                  >
                    <g clip-path="url(#clip0_2532_6102)">
                      <path
                        d="M3.25829 19.5505L19.9812 12.3822C20.7575 12.0467 20.7575 10.9542 19.9812 10.6188L3.25829 3.45048C2.62579 3.17257 1.92621 3.64215 1.92621 4.32257L1.91663 8.74048C1.91663 9.21965 2.27121 9.63173 2.75038 9.68923L16.2916 11.5005L2.75038 13.3022C2.27121 13.3692 1.91663 13.7813 1.91663 14.2605L1.92621 18.6784C1.92621 19.3588 2.62579 19.8284 3.25829 19.5505Z"
                        fill="#4529FA"
                        fill-opacity="0.75"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2532_6102">
                        <rect width="23" height="23" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <div className="hidden sm:flex sm:w-[1000px] supported-models">
              Supported models:{" "}
              <img
                className="ml-[6px] mr-[2px]"
                src="/assets/images/gpt4.png"
              />{" "}
              GPT-4,
              <img
                className="ml-[6px] mr-[2px]"
                src="/assets/images/llama2.png"
              />{" "}
              Llama2,
              <img
                className="ml-[6px] mr-[2px]"
                src="/assets/images/mistral-ai.png"
              />{" "}
              Mistral-AI,
              <img
                className="ml-[6px] mr-[2px]"
                src="/assets/images/google-gemini.png"
              />{" "}
              Google Gemini
            </div>
          </div>
          <div className="flex px-2">
            <Link
              className="flex justify-center items-center description"
              to={
                "https://www.getbind.co/?utm_source=chat-app-bottom&utm_medium=chat-bot&utm_campaign=chat-bot"
              }
              target="_blank"
            >
              Created with Bind
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                className="ml-1"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.17708 0L9.82292 0C11.6231 0 12.2759 0.187436 12.934 0.539403C13.5921 0.891369 14.1086 1.40787 14.4606 2.06598C14.8126 2.7241 15 3.37689 15 5.17708V9.82292C15 11.6231 14.8126 12.2759 14.4606 12.934C14.1086 13.5921 13.5921 14.1086 12.934 14.4606C12.2759 14.8126 11.6231 15 9.82292 15H5.17708C3.37689 15 2.7241 14.8126 2.06598 14.4606C1.40787 14.1086 0.891369 13.5921 0.539403 12.934C0.187436 12.2759 0 11.6231 0 9.82292L0 5.17708C0 3.37689 0.187436 2.7241 0.539403 2.06598C0.891369 1.40787 1.40787 0.891369 2.06598 0.539403C2.7241 0.187436 3.37689 0 5.17708 0Z"
                  fill="#1C274C"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.96223 3.80092C8.02232 3.56051 8.26593 3.41434 8.50635 3.47444C8.74677 3.53455 8.89297 3.77816 8.83284 4.01858L7.03796 11.1979C6.97788 11.4383 6.73427 11.5845 6.49383 11.5243C6.25341 11.4643 6.10724 11.2207 6.16734 10.9802L7.96223 3.80092ZM5.12508 5.57315L3.19878 7.49941L5.12508 9.42566C5.30032 9.60088 5.30032 9.885 5.12508 10.0602C4.94984 10.2354 4.66573 10.2354 4.4905 10.0602L2.24691 7.81669C2.07167 7.64147 2.07167 7.35735 2.24691 7.18213L4.4905 4.93859C4.66573 4.76336 4.94984 4.76336 5.12508 4.93859C5.30032 5.11382 5.30032 5.39793 5.12508 5.57315ZM9.87512 9.42566L11.8014 7.49941L9.87512 5.57315C9.6999 5.39793 9.6999 5.11382 9.87512 4.93859C10.0503 4.76336 10.3345 4.76336 10.5097 4.93859L12.7533 7.18213C12.9285 7.35735 12.9285 7.64147 12.7533 7.81669L10.5097 10.0602C10.3345 10.2354 10.0503 10.2354 9.87512 10.0602C9.6999 9.885 9.6999 9.60088 9.87512 9.42566Z"
                  fill="white"
                />
              </svg>
            </Link>
            <div className="flex-1"></div>
            <div className="hidden sm:block disable-text">
              Bind can provide inaccurate information, including about people.
              Always double-check its answers. Your{" "}
              <span className="privacy">privacy</span> in Bind
            </div>
            <div className="hidden sm:block disable-text">
              Always check its answers. <span className="privacy">privacy</span>{" "}
              in Bind
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebBotHome;
