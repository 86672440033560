import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useSelector, useDispatch } from "react-redux";
import { loginUserWithId, registerUser } from "../../reducers/authReducer";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  GoogleAuthProvider,
  signInWithPopup,
  GithubAuthProvider,
  getAdditionalUserInfo,
} from "firebase/auth";
import { auth, db } from "../../firebaseConfig";
import { doc, setDoc } from "firebase/firestore";
import { history } from "../../history";

const RegisterForm = ({ setOpenDlg, setOpenRegisterDlg }) => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [companyname, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");

  const errors = useSelector((state) => state.auth.errors);
  //   console.log(errors);

  const dispatch = useDispatch();
  const createAccount = async () => {
    let errs = {};
    if (firstname === "") errs["firstname"] = "FirstName field is required!";
    if (lastname === "") errs["lastname"] = "LastName field is required!";
    if (companyname === "")
      errs["companyname"] = "Company Name field is required!";
    if (website === "") errs["website"] = "Website field is required!";
    if (email === "") errs["email"] = "Email field is required!";
    if (password === "") errs["password"] = "Password field is required!";

    if (Object.keys(errs).length === 0) {
      const newAccount = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        password: password,
        companyname: companyname,
        website: website,
      };

      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;

        await updateProfile(user, {
          displayName: firstname,
          photoURL: "",
        });

        await sendEmailVerification(user, {
          url: process.env.REACT_APP_BASE_URL,
        });

        await dispatch(
          registerUser({
            ...newAccount,
            userId: user.uid,
          })
        );

        // await setDoc(doc(db, "users", user.uid), {
        //   id: user.uid,
        //   firstName: firstname,
        //   email,
        //   photoURL: "",
        // });

        if (!setOpenRegisterDlg) {
          NotificationManager.success(
            "Registration successful. Check your email for verification.",
            "",
            2000
          );
          history.navigate("/login");
        } else {
          setOpenRegisterDlg && setOpenRegisterDlg(0);
          NotificationManager.success(
            "Welcome! You are successfully registered.",
            "",
            2000
          );
          // localStorage.setItem("user", email);
          localStorage.setItem("userId", user.uid);
        }
      } catch (error) {
        // Handle errors
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error:", errorMessage, error.errors);
        if (
          error.code === "auth/account-exists-with-different-credential" ||
          error.code === "auth/email-already-in-use"
        ) {
          NotificationManager.error(
            "",
            "Email already registered using different provider",
            2000
          );
        } else {
          console.error("Error:", errorMessage, error.errors);
          NotificationManager.error("", errorMessage, 2000);
        }
      }
    } else {
      for (let err = 0; err < Object.keys(errs).length; err++)
        NotificationManager.error("", Object.values(errs)[err], 2000);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential?.user;
      console.log(user,"userrr");
      const isFirstLogin = getAdditionalUserInfo(userCredential).isNewUser;

      if (isFirstLogin) {
        const name=user.displayName.split(" ");
        const firstName=name[0];
        const lastName=name[1];
        const newAccount = {
          firstname: firstName,
          lastname: lastName,
          email: user.email,
          userId: user.uid,
        };
        // localStorage.setItem("user", user.email);
        localStorage.setItem("userId", user.uid);

        dispatch(registerUser(newAccount));

        // await setDoc(doc(db, "users", user.uid), {
        //   id: user.uid,
        //   firstName: user.displayName,
        //   email: user.email,
        //   photoURL: user.photoURL,
        // });
      } else {
        dispatch(
          loginUserWithId({
            id: user.uid,
          })
        );
      }
      // localStorage.setItem("userId", user.uid);

      NotificationManager.success(
        "Successfully signed in with Google",
        "",
        2000
      );

      setOpenRegisterDlg && setOpenRegisterDlg(0);

      if (!setOpenRegisterDlg) history.navigate("/");
    } catch (error) {
      console.log("Error signing in with Google:", error.message);
      if (error.code === "auth/account-exists-with-different-credential") {
        NotificationManager.error(
          "",
          "Email already registered using different provider",
          2000
        );
      }
    }
  };

  const signInWithGitHub = async () => {
    try {
      const provider = new GithubAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;

      console.log("User signed in with GitHub:", user);
      const isFirstLogin = getAdditionalUserInfo(userCredential).isNewUser;

      if (isFirstLogin) {
        const name=user.displayName.split(" ");
        const firstName=name[0];
        const lastName=name[1];
        const newAccount = {
          firstname: firstName,
          lastname: lastName,
          email: user.email,
          userId: user.uid,
        };

        dispatch(registerUser(newAccount));
        localStorage.setItem("userId", user.uid);

        // await setDoc(doc(db, "users", user.uid), {
        //   id: user.uid,
        //   firstName: user.displayName,
        //   email: user.email,
        //   photoURL: user.photoURL,
        // });
      } else {
        dispatch(
          loginUserWithId({
            id: user.uid,
          })
        );
      }
      // localStorage.setItem("userId", user.uid);

      NotificationManager.success(
        "Successfully signed in with GitHub",
        "",
        2000
      );

      setOpenRegisterDlg && setOpenRegisterDlg(0);

      if (!setOpenRegisterDlg) history.navigate("/");
    } catch (error) {
      console.log("Error signing in with GitHub:", error.message);
      if (error.code === "auth/account-exists-with-different-credential") {
        NotificationManager.error(
          "",
          "Email already registered using different provider",
          2000
        );
      }
    }
  };
  return (
    <div className="md:w-1/2 text-left md:pt-0">
      <div className="z-10 flex flex-col justify-center items-center  bg-white  md:py-4 px-8 md:w-3/4 mx-auto rounded-lg">
        <div className="flex flex-col max-[400px]:pt-10 justify-center flex-1 w-full">
          <div className="px-6">
            <div className="py-4 space-y-1 lg:pb-6">
              <h2 className="text-[36px] font-semibold  text-gray-800">
                Create account
              </h2>
              <p className="flex gap-1 text-sm text-gray-700">
                Already have an account?{" "}
                {!setOpenRegisterDlg ? (
                  <Link to={"/login"} className="underline">
                    Sign in here
                  </Link>
                ) : (
                  <div
                    onClick={() => {
                      setOpenDlg?.(1);
                      setOpenRegisterDlg(0);
                    }}
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    Sign in here
                  </div>
                )}
              </p>
            </div>

            <div className="block space-y-4">
              <div className="space-y-2">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  className="w-full rounded-md text-sm"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="w-full rounded-md text-sm"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {/* <span className="block font-semibold tracking-wider  text-gray-600 uppercase text-xs mt-2">
            Minimal 8 characters
          </span> */}
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div className="space-y-2">
                  <input
                    type="text"
                    name="firstname"
                    id="firstname"
                    placeholder="First Name"
                    className="w-full rounded-md text-sm"
                    value={firstname}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="space-y-2">
                  <input
                    type="text"
                    name="lastname"
                    id="lastname"
                    placeholder="Last Name"
                    className="w-full rounded-md text-sm"
                    value={lastname}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="space-y-2">
                <input
                  type="text"
                  name="companyname"
                  id="companyname"
                  placeholder="Your company name"
                  className="w-full rounded-md text-sm"
                  value={companyname}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <input
                  type="text"
                  name="website"
                  id="website"
                  placeholder="Company website"
                  className="w-full rounded-md text-sm"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </div>
              <button
                className="flex items-center justify-center w-full px-4 py-4 text-sm font-semibold leading-4 text-center transition-colors duration-300 bg-[#4529FA] rounded-md hover:[#4529FA] text-blue-50 h-[54px]"
                onClick={() => createAccount()}
              >
                <span>Sign up</span>
              </button>
              <span className="block px-3 my-6 text-sm text-center text-gray-600 bg-white">
                <span className="block w-full bg-white border-b border-gray-200">
                  &nbsp;
                </span>
                <span className="relative z-20 px-20 py-4 bg-white -top-3">
                  or
                </span>
              </span>
              <div className="flex gap-3">
                <button
                  className="flex items-center justify-center w-full px-4 py-3 space-x-1 text-sm border border-gray-200 leading-4 text-center transition-colors duration-300 rounded-md"
                  onClick={() => signInWithGoogle()}
                >
                  <img src="/assets/images/google.svg" alt="google" />
                  <span className="pl-2">Sign in with Google</span>
                </button>
                <button
                  className="flex items-center justify-center w-full px-4 py-3 space-x-1 text-sm border border-gray-200 leading-4 text-center transition-colors duration-300 rounded-md"
                  onClick={() => signInWithGitHub()}
                >
                  <img src="/assets/images/github.svg" alt="github" />
                  <span className="pl-2">Sign in with Github</span>
                </button>
              </div>
              <div className="text-xs text-gray-700 w-2/3 mx-auto text-center pb-12">
                You acknowledge that you read, and agree to our{" "}
                <a href="" className="font-semibold underline">
                  Terms of Service{" "}
                </a>{" "}
                and our{" "}
                <a href="" className="font-semibold underline">
                  Privacy Policy.{" "}
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
