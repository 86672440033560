const BotThinking = () => {
  return (
    <div className="chat-msg flex items-center">
      <img src="/assets/images/bot.png" className="w-10 mr-2" />
      Bot is thinking...
    </div>
  );
};

export default BotThinking;
