import { Link } from "react-router-dom";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { current } from "@reduxjs/toolkit";
const SideBar = () => {
  const [step, setStep] = useState(0);
  const currentBot = useSelector((state) => state.bot.bot);
  return (
    <div
      className="w-[300px] hidden sm:flex flex-col h-full"
      style={{ borderRight: "1px solid #EBEBEB" }}
    >
      <div className="mt-[15px] mx-[24px] flex flex-col text-left">
        <p className="sidebar-description">This Application</p>
        <Link
          className={classNames(
            "mb-[10px] flex items-center web-chat-default-text",
            {
              "webbot-sidebar-item-current": step == 0,
              "webbot-sidebar-item-default": step != 0,
            }
          )}
          // onClick={() => setStep(0)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            className="mr-3"
          >
            <g clip-path="url(#clip0_2043_3150)">
              <path
                d="M17.9377 16.6227H3.06269C2.58144 16.6227 2.18769 17.0164 2.18769 17.4977C2.18769 17.9789 2.58144 18.3727 3.06269 18.3727H17.9377C18.4189 18.3727 18.8127 17.9789 18.8127 17.4977C18.8127 17.0164 18.4189 16.6227 17.9377 16.6227ZM19.3114 8.43268C19.1189 7.73268 18.4014 7.32143 17.7014 7.50518L13.0552 8.74768L7.40269 3.48018C7.16644 3.25268 6.82519 3.17393 6.51019 3.26143C5.91519 3.42768 5.63519 4.11018 5.94144 4.64393L8.95144 9.85893L4.60269 11.0227L3.22894 9.93768C3.01019 9.77143 2.73019 9.71018 2.45894 9.78018L2.17019 9.85893C1.89019 9.92893 1.75894 10.2527 1.90769 10.4977L3.55269 13.3414C3.75394 13.6827 4.15644 13.8489 4.53269 13.7527L18.3752 10.0427C19.0752 9.85018 19.4952 9.13268 19.3114 8.43268Z"
                fill="#5237FF"
              />
            </g>
            <defs>
              <clipPath id="clip0_2043_3150">
                <rect width="21" height="21" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {currentBot.name}
        </Link>
      </div>
      <div className="my-[15px] mx-[24px] flex flex-col text-left">
        <p className="sidebar-description">Featured Applications</p>
        <Link
          className={classNames(
            "mb-[10px] flex items-center web-chat-default-text",
            {
              "webbot-sidebar-item-current": step == 1,
              "sidebar-item-finish": step > 1,
              "webbot-sidebar-item-default": step < 1,
            }
          )}
          to={`${process.env.REACT_APP_BASE_URL}/webbot/6566ca04cc7b2badce6c3d0f`}
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            className="mr-3"
          >
            <g clip-path="url(#clip0_2043_3156)">
              <path
                d="M17.5 1.75H3.5C2.625 1.75 1.75 2.5375 1.75 3.5V6.13375C1.75 6.76375 2.12625 7.30625 2.625 7.6125V17.5C2.625 18.4625 3.5875 19.25 4.375 19.25H16.625C17.4125 19.25 18.375 18.4625 18.375 17.5V7.6125C18.8738 7.30625 19.25 6.76375 19.25 6.13375V3.5C19.25 2.5375 18.375 1.75 17.5 1.75ZM12.25 12.25H8.75C8.26875 12.25 7.875 11.8562 7.875 11.375C7.875 10.8938 8.26875 10.5 8.75 10.5H12.25C12.7312 10.5 13.125 10.8938 13.125 11.375C13.125 11.8562 12.7312 12.25 12.25 12.25ZM17.5 6.125H3.5V3.5H17.5V6.125Z"
                fill="#848A9E"
              />
            </g>
            <defs>
              <clipPath id="clip0_2043_3156">
                <rect width="21" height="21" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Yoda GPT
        </Link>
        <Link
          className={classNames(
            "mb-[10px] flex items-center web-chat-default-text",
            {
              "webbot-sidebar-item-current": step == 2,
              "sidebar-item-finish": step > 2,
              "webbot-sidebar-item-default": step < 2,
            }
          )}
          to={`${process.env.REACT_APP_BASE_URL}/webbot/6566c389cc7b2badce6c3d0d`}
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            className="mr-3"
          >
            <g clip-path="url(#clip0_2043_6449)">
              <path
                d="M14 2.625H4.375C3.4125 2.625 2.625 3.4125 2.625 4.375V16.625C2.625 17.5875 3.4125 18.375 4.375 18.375H16.625C17.5875 18.375 18.375 17.5875 18.375 16.625V7L14 2.625ZM7 6.125H9.625C10.1062 6.125 10.5 6.51875 10.5 7C10.5 7.48125 10.1062 7.875 9.625 7.875H7C6.51875 7.875 6.125 7.48125 6.125 7C6.125 6.51875 6.51875 6.125 7 6.125ZM14 14.875H7C6.51875 14.875 6.125 14.4812 6.125 14C6.125 13.5188 6.51875 13.125 7 13.125H14C14.4812 13.125 14.875 13.5188 14.875 14C14.875 14.4812 14.4812 14.875 14 14.875ZM14 11.375H7C6.51875 11.375 6.125 10.9812 6.125 10.5C6.125 10.0188 6.51875 9.625 7 9.625H14C14.4812 9.625 14.875 10.0188 14.875 10.5C14.875 10.9812 14.4812 11.375 14 11.375ZM13.125 7V4.375L16.625 7.875H14C13.5188 7.875 13.125 7.48125 13.125 7Z"
                fill="#1C274C"
              />
            </g>
            <defs>
              <clipPath id="clip0_2043_6449">
                <rect width="21" height="21" fill="white" />
              </clipPath>
            </defs>
          </svg>
          B2B Sales Leads Bot
        </Link>
        <Link
          className={classNames(
            "mb-[10px] flex items-center web-chat-default-text",
            {
              "webbot-sidebar-item-current": step == 3,
              "sidebar-item-finish": step > 3,
              "webbot-sidebar-item-default": step < 3,
            }
          )}
          // onClick={() => setStep(3)}
          to={`${process.env.REACT_APP_BASE_URL}/webbot/6566c5aff7b5b4fae6758940`}
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            className="mr-3"
          >
            <g clip-path="url(#clip0_2043_6454)">
              <path
                d="M6.125 17.5H9.625C9.625 18.4625 8.8375 19.25 7.875 19.25C6.9125 19.25 6.125 18.4625 6.125 17.5ZM4.375 15.75C4.375 16.2313 4.76875 16.625 5.25 16.625H10.5C10.9812 16.625 11.375 16.2313 11.375 15.75C11.375 15.2688 10.9812 14.875 10.5 14.875H5.25C4.76875 14.875 4.375 15.2688 4.375 15.75ZM14.4375 8.3125C14.4375 11.655 12.11 13.44 11.1388 14H4.61125C3.64 13.44 1.3125 11.655 1.3125 8.3125C1.3125 4.69 4.2525 1.75 7.875 1.75C11.4975 1.75 14.4375 4.69 14.4375 8.3125ZM18.6988 6.44875L17.5 7L18.6988 7.55125L19.25 8.75L19.8012 7.55125L21 7L19.8012 6.44875L19.25 5.25L18.6988 6.44875ZM16.625 5.25L17.4475 3.4475L19.25 2.625L17.4475 1.8025L16.625 0L15.8025 1.8025L14 2.625L15.8025 3.4475L16.625 5.25Z"
                fill="#848A9E"
              />
            </g>
            <defs>
              <clipPath id="clip0_2043_6454">
                <rect width="21" height="21" fill="white" />
              </clipPath>
            </defs>
          </svg>
          DomiKnows Pizza Order Bot
        </Link>
        <Link
          className={classNames(
            "mb-[10px] flex items-center web-chat-default-text",
            {
              "webbot-sidebar-item-current": step == 4,
              "sidebar-item-finish": step > 4,
              "webbot-sidebar-item-default": step < 4,
            }
          )}
          // onClick={() => setStep(4)}
          to={`${process.env.REACT_APP_BASE_URL}/webbot/6566c73cb1b175dccb8652bb`}
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            className="mr-3"
          >
            <g clip-path="url(#clip0_2043_6457)">
              <path
                d="M9.625 2.78316V18.2182C9.625 18.7782 9.10875 19.1982 8.56625 19.0757C4.655 18.2007 1.75 14.6919 1.75 10.5007C1.75 6.30941 4.655 2.80066 8.56625 1.92566C9.10875 1.80316 9.625 2.22316 9.625 2.78316ZM11.4013 2.78316V8.74191C11.4013 9.22316 11.795 9.61691 12.2763 9.61691H18.2175C18.7775 9.61691 19.1975 9.10066 19.075 8.54941C18.3313 5.25941 15.75 2.66941 12.4688 1.92566C11.9175 1.80316 11.4013 2.22316 11.4013 2.78316ZM11.4013 12.2594V18.2182C11.4013 18.7782 11.9175 19.1982 12.4688 19.0757C15.7588 18.3319 18.34 15.7332 19.0837 12.4432C19.2063 11.9007 18.7775 11.3757 18.2263 11.3757H12.285C11.795 11.3844 11.4013 11.7782 11.4013 12.2594Z"
                fill="#848A9E"
              />
            </g>
            <defs>
              <clipPath id="clip0_2043_6457">
                <rect width="21" height="21" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Data Extractor to JSON
        </Link>
      </div>
      <div className="flex-1"></div>
      <div className="mt-[15px] mx-[24px] flex flex-col text-left mb-[100px]">
        <p className="sidebar-description">how to</p>
        <Link
          className={classNames("mb-[10px] flex items-center sidebar-how-text")}
          // onClick={() => setStep(0)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            className="mr-3"
          >
            <g clip-path="url(#clip0_2043_3164)">
              <path
                d="M17.5 9.625H3.5C3.01875 9.625 2.625 10.0188 2.625 10.5C2.625 10.9812 3.01875 11.375 3.5 11.375H17.5C17.9812 11.375 18.375 10.9812 18.375 10.5C18.375 10.0188 17.9812 9.625 17.5 9.625ZM3.5 15.75H12.25C12.7312 15.75 13.125 15.3562 13.125 14.875C13.125 14.3938 12.7312 14 12.25 14H3.5C3.01875 14 2.625 14.3938 2.625 14.875C2.625 15.3562 3.01875 15.75 3.5 15.75ZM17.5 5.25H3.5C3.01875 5.25 2.625 5.64375 2.625 6.125V6.13375C2.625 6.615 3.01875 7.00875 3.5 7.00875H17.5C17.9812 7.00875 18.375 6.615 18.375 6.13375V6.125C18.375 5.64375 17.9812 5.25 17.5 5.25Z"
                fill="#848A9E"
              />
            </g>
            <defs>
              <clipPath id="clip0_2043_3164">
                <rect width="21" height="21" fill="white" />
              </clipPath>
            </defs>
          </svg>
          How to build our own ChatGPT
        </Link>
        <Link
          className={classNames("mb-[10px] flex items-center sidebar-how-text")}
          to={
            "https://medium.com/@getbind.co/how-to-build-complex-llm-applications-with-your-own-data-and-services-c9186e92d926"
          }
          target="_blank"
          // onClick={() => setStep(0)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            className="mr-3"
          >
            <g clip-path="url(#clip0_2043_3164)">
              <path
                d="M17.5 9.625H3.5C3.01875 9.625 2.625 10.0188 2.625 10.5C2.625 10.9812 3.01875 11.375 3.5 11.375H17.5C17.9812 11.375 18.375 10.9812 18.375 10.5C18.375 10.0188 17.9812 9.625 17.5 9.625ZM3.5 15.75H12.25C12.7312 15.75 13.125 15.3562 13.125 14.875C13.125 14.3938 12.7312 14 12.25 14H3.5C3.01875 14 2.625 14.3938 2.625 14.875C2.625 15.3562 3.01875 15.75 3.5 15.75ZM17.5 5.25H3.5C3.01875 5.25 2.625 5.64375 2.625 6.125V6.13375C2.625 6.615 3.01875 7.00875 3.5 7.00875H17.5C17.9812 7.00875 18.375 6.615 18.375 6.13375V6.125C18.375 5.64375 17.9812 5.25 17.5 5.25Z"
                fill="#848A9E"
              />
            </g>
            <defs>
              <clipPath id="clip0_2043_3164">
                <rect width="21" height="21" fill="white" />
              </clipPath>
            </defs>
          </svg>
          The basics of generative AI
        </Link>
        <Link
          className={classNames("mb-[10px] flex items-center sidebar-how-text")}
          to={
            "https://medium.com/@getbind.co/how-to-create-a-pizza-ordering-ai-bot-using-llms-b3763b1c8d5c"
          }
          target="_blank"
          // onClick={() => setStep(0)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            className="mr-3 w-[35px]"
          >
            <g clip-path="url(#clip0_2043_3164)">
              <path
                d="M17.5 9.625H3.5C3.01875 9.625 2.625 10.0188 2.625 10.5C2.625 10.9812 3.01875 11.375 3.5 11.375H17.5C17.9812 11.375 18.375 10.9812 18.375 10.5C18.375 10.0188 17.9812 9.625 17.5 9.625ZM3.5 15.75H12.25C12.7312 15.75 13.125 15.3562 13.125 14.875C13.125 14.3938 12.7312 14 12.25 14H3.5C3.01875 14 2.625 14.3938 2.625 14.875C2.625 15.3562 3.01875 15.75 3.5 15.75ZM17.5 5.25H3.5C3.01875 5.25 2.625 5.64375 2.625 6.125V6.13375C2.625 6.615 3.01875 7.00875 3.5 7.00875H17.5C17.9812 7.00875 18.375 6.615 18.375 6.13375V6.125C18.375 5.64375 17.9812 5.25 17.5 5.25Z"
                fill="#848A9E"
              />
            </g>
            <defs>
              <clipPath id="clip0_2043_3164">
                <rect width="21" height="21" fill="white" />
              </clipPath>
            </defs>
          </svg>
          A step-by-step guide to building a ChatGPT-like model
        </Link>
      </div>
      <Link
        className="flex justify-center items-center web-chat-default-text create-app-btn"
        to={
          "https://www.getbind.co/?utm_source=chat-app-bottom&utm_medium=chat-bot&utm_campaign=chat-bot"
        }
        target="_blank"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          className="mr-3"
        >
          <g clip-path="url(#clip0_2043_6496)">
            <path
              d="M9.50016 1.58203C5.13016 1.58203 1.5835 5.1287 1.5835 9.4987C1.5835 13.8687 5.13016 17.4154 9.50016 17.4154C13.8702 17.4154 17.4168 13.8687 17.4168 9.4987C17.4168 5.1287 13.8702 1.58203 9.50016 1.58203ZM12.6668 10.2904H10.2918V12.6654C10.2918 13.1008 9.93558 13.457 9.50016 13.457C9.06475 13.457 8.7085 13.1008 8.7085 12.6654V10.2904H6.3335C5.89808 10.2904 5.54183 9.93411 5.54183 9.4987C5.54183 9.06328 5.89808 8.70703 6.3335 8.70703H8.7085V6.33203C8.7085 5.89661 9.06475 5.54036 9.50016 5.54036C9.93558 5.54036 10.2918 5.89661 10.2918 6.33203V8.70703H12.6668C13.1022 8.70703 13.4585 9.06328 13.4585 9.4987C13.4585 9.93411 13.1022 10.2904 12.6668 10.2904Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_2043_6496">
              <rect width="19" height="19" fill="white" />
            </clipPath>
          </defs>
        </svg>
        Create your chatGPT app
      </Link>
      <Link
        className="description mt-2"
        to={
          "https://www.getbind.co/?utm_source=chat-app-bottom&utm_medium=chat-bot&utm_campaign=chat-bot"
        }
        target="_blank"
      >
        Powered by bind
      </Link>
    </div>
  );
};

export default SideBar;
