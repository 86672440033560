import { current } from "@reduxjs/toolkit";
import classNames from "classnames";

const BotKnowledgeBaseType = ({ title, desc, type, setType, currentType }) => {
  return (
    <div
      className={classNames("p-[20px] cursor-pointer w-[350px]", {
        "bot-knowledge-base-type-selected": type == currentType,
        "bot-knowledge-base-type-unselected": type != currentType,
      })}
      style={{ position: "relative" }}
      onClick={() => setType(type)}
    >
      <h1 className="text-left mb-[10px]">{title}</h1>
      <h2>{desc}</h2>
      {type == current && <div className="tooltip"></div>}
    </div>
  );
};

export default BotKnowledgeBaseType;
