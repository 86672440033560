import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { auth } from "../../firebaseConfig";
import { applyActionCode } from "firebase/auth";
import { NotificationManager } from "react-notifications";
import { history } from "../../history";

const VerifyEmail = () => {
  const location = useLocation();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const oobCode = searchParams.get("oobCode");

        if (!oobCode) {
          history.navigate("/login");
          return;
        }

        await applyActionCode(auth, oobCode);

        NotificationManager.success(
          "Your Email has been successfully verified",
          "",
          2000
        );

        history.navigate("/login");
      } catch (error) {
        console.log("error in verifying email", error);
        NotificationManager.error(
          "The Verification link is invalid or expired. Please request a new one.",
          "",
          2000
        );
        history.navigate("/login");
      }
    };

    verifyEmail();
  }, [location]);

  return <div>Please wait...</div>;
};

export default VerifyEmail;
