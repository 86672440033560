import { useState } from "react";
import { Link } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from "react-redux";
import {
  loginUser,
  loginUserWithId,
  registerUser,
} from "../../reducers/authReducer";
import {
  GithubAuthProvider,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { auth, db } from "../../firebaseConfig";
import { history } from "../../history";
import { doc, setDoc } from "firebase/firestore";

const LoginForm = ({ setOpenDlg, setOpenRegisterDlg }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const login = async () => {
    let errs = {};
    if (email === "") errs["email"] = "Email field is required!";
    if (password === "") errs["password"] = "Password field is required!";

    if (Object.keys(errs).length === 0) {
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        // localStorage.setItem("userId", user.uid);
        // await sendEmailVerification(user, {
        //   url: "http://localhost:3000/login",
        // });

        // if (!user.emailVerified) {
        //   await sendEmailVerification(user);

        //   NotificationManager.success(
        //     "Please check your email to verify your account.",
        //     "",
        //     3000
        //   );
        // }
        dispatch(
          loginUserWithId({
            id: user.uid,
          })
        );

        setOpenDlg?.(0);

        if (!setOpenDlg) history.navigate("/");
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error:", errorMessage);
        if (
          error.code == "auth/invalid-credential" ||
          error.code == "auth/invalid-email"
        ) {
          NotificationManager.error("", "Invalid Credential", 2000);
        } else {
          NotificationManager.error("", errorMessage, 2000);
        }
      }
    } else {
      console.log(errs);
      for (let err = 0; err < Object.keys(errs).length; err++)
        NotificationManager.error("", Object.values(errs)[err], 2000);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential?.user;
      const isFirstLogin = getAdditionalUserInfo(userCredential).isNewUser;

      if (isFirstLogin) {
        const name=user.displayName.split(" ");
        const firstName=name[0];
        const lastName=name[1];
        const newAccount = {
          firstname: firstName,
          lastname: lastName,
          email: user.email,
          userId: user.uid,
        };
        localStorage.setItem("userId", user.uid);
        dispatch(registerUser(newAccount));

        // await setDoc(doc(db, "users", user.uid), {
        //   id: user.uid,
        //   firstName: user.displayName,
        //   email: user.email,
        //   photoURL: user.photoURL,
        // });
      } else {
        dispatch(
          loginUserWithId({
            id: user.uid,
          })
        );
      }
      // localStorage.setItem("userId", user.uid);

      NotificationManager.success(
        "Successfully signed in with Google",
        "",
        2000
      );

      setOpenDlg && setOpenDlg(0);

      if (!setOpenDlg) history.navigate("/");
    } catch (error) {
      console.log("Error signing in with Google:", error.message);
      if (error.code === "auth/account-exists-with-different-credential") {
        NotificationManager.error(
          "",
          "Email already registered using different provider",
          2000
        );
      }
    }
  };

  const signInWithGitHub = async () => {
    try {
      const provider = new GithubAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;

      const isFirstLogin = getAdditionalUserInfo(userCredential).isNewUser;

      if (isFirstLogin) {
        const name=user.displayName.split(" ");
        const firstName=name[0];
        const lastName=name[1];
        const newAccount = {
          firstname: firstName,
          lastname: lastName,
          email: user.email,
          userId: user.uid,
        };

        dispatch(registerUser(newAccount));
        localStorage.setItem("userId", user.uid);

        // await setDoc(doc(db, "users", user.uid), {
        //   id: user.uid,
        //   firstName: user.displayName,
        //   email: user.email,
        //   photoURL: user.photoURL,
        // });
      } else {
        dispatch(
          loginUserWithId({
            id: user.uid,
          })
        );
      }
      // localStorage.setItem("userId", user.uid);

      NotificationManager.success(
        "Successfully signed in with GitHub",
        "",
        2000
      );

      setOpenDlg && setOpenDlg(0);

      if (!setOpenDlg) history.navigate("/");
    } catch (error) {
      console.log("Error signing in with GitHub:", error.message);
      if (error.code === "auth/account-exists-with-different-credential") {
        NotificationManager.error(
          "",
          "Email already registered using different provider",
          2000
        );
      }
    }
  };

  return (
    <div className="md:w-1/2 text-left">
      <div className="md:w-3/4 mx-auto z-10 flex flex-col justify-center h-screen md:h-fit px-4  bg-white md:pt-12 rounded-lg">
        <div className="flex flex-col justify-center flex-1 pb-12">
          <div className="px-8">
            <div className="space-y-1 lg:pb-6">
              <h2 className="text-[36px] font-semibold  text-gray-800">
                Sign in
              </h2>
              <p className="flex gap-1 text-sm text-gray-700">
                New user?{" "}
                {!setOpenDlg ? (
                  <Link to={"/register"} className="underline">
                    Sign up for free
                  </Link>
                ) : (
                  <div
                    onClick={() => {
                      setOpenDlg(0);
                      setOpenRegisterDlg(1);
                    }}
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    Sign up for free
                  </div>
                )}
              </p>
            </div>

            <div className="block space-y-4">
              <div className="space-y-2">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="w-full border-gray-300 rounded-md text-sm"
                  placeholder="Login or Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="w-full border-gray-300 rounded-md text-sm"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="flex items-center justify-between cursor-pointer">
                <label className="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    className="border-gray-300 rounded-md h-4"
                  />
                  <span className="text-sm pl-3 text-gray-700">
                    Remember me
                  </span>
                </label>
                <span
                  className="text-sm text-[#3A4363]"
                  onClick={() => {
                    !setOpenDlg && history.navigate("/forgotPassword");
                  }}
                >
                  Forgot your password?
                </span>
              </div>
              <button
                className="flex items-center justify-center w-full px-4 py-4 space-x-1 text-sm font-semibold leading-4 text-center transition-colors duration-300 bg-[#4529FA] rounded-md hover:bg-[#4529FA] text-blue-50"
                onClick={() => login()}
              >
                <span>Sign In</span>
              </button>
              <span className="block px-3 my-6 text-sm text-center text-gray-600 bg-white">
                <span className="block w-full bg-white border-b border-gray-200">
                  &nbsp;
                </span>
                <span className="relative z-20 px-20 py-4 bg-white -top-3">
                  or
                </span>
              </span>
              <div className="flex gap-3">
                <button
                  className="flex items-center justify-center w-full px-4 py-3 space-x-1 text-sm border border-gray-200 leading-4 text-center transition-colors duration-300 rounded-md"
                  onClick={() => signInWithGoogle()}
                >
                  <img src="/assets/images/google.svg" />
                  <span className="pl-2">Sign in with Google</span>
                </button>
                <button
                  className="flex items-center justify-center w-full px-4 py-3 space-x-1 text-sm border border-gray-200 leading-4 text-center transition-colors duration-300 rounded-md"
                  onClick={() => signInWithGitHub()}
                >
                  <img src="/assets/images/github.svg" />
                  <span className="pl-2">Sign in with Github</span>
                </button>
              </div>
              <div className="text-xs text-gray-700 w-2/3 mx-auto text-center pt-3">
                You acknowledge that you read, and agree to our{" "}
                <a href="" className="font-semibold underline">
                  Terms of Service{" "}
                </a>{" "}
                and our{" "}
                <a href="" className="font-semibold underline">
                  Privacy Policy.{" "}
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
